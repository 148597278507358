import {useEffect} from "react";
import Breadcrumb, {BreadcrumbItem, LocationBreadcrumbItem} from "../components/Breadcrumb";
import {AiOutlinePlusSquare} from "react-icons/ai";
import {BiHive} from "react-icons/bi";
import {FaHome} from "react-icons/fa";
import {useParams} from "react-router-dom";
import {useFetch} from "../hooks/useFetch";
import {API_URL} from "../config";
import {QueenBeeForm} from "../components/QueenBeeForm";

export default function NewQueenBee() {
    useEffect(() => {
        document.title = "Chytrý včelař | Nová matka"
    }, []);

    let params = useParams();

    const {data} = useFetch(
        `${API_URL}/hives/${params.hiveSlug}/`
    );

    return data ? (
        <>
            <Breadcrumb>
                <BreadcrumbItem title="Stanoviště" to="/muj-denik/"><FaHome/></BreadcrumbItem>

                {data ?
                    <>
                        <LocationBreadcrumbItem locationSlug={data.location_slug}/>
                        <BreadcrumbItem title={data.name} to={`/ul/${data.slug}`}><BiHive/></BreadcrumbItem>
                    </> : ""
                }

                <BreadcrumbItem title="Přidání matky" to="#"><AiOutlinePlusSquare/></BreadcrumbItem>
            </Breadcrumb>
            <div className="container is-fluid mt-4">
                <QueenBeeForm initialValues={{
                    hive: data.id,
                    is_active: true,
                    name: ""
                }}/>
            </div>
        </>) : "";
}

