import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {PasswordField, TextField} from "./TextField";
import {postFormDataWithoutAuthorization} from "../utils/postFormData";
import {API_URL} from "../config";
import {store} from "react-notifications-component";
import {Link, useHistory} from "react-router-dom";
import useAuth from "../hooks/useAuth";
import {useState} from "react";


export const RegistrationForm = ({initialValues = {}}) => {
    let history = useHistory();
    const {login} = useAuth();
    const [agree, setAgree] = useState(true)

    return <Formik
        initialValues={initialValues}
        validationSchema={
            Yup.object().shape({
                email: Yup.string().email('Neplatná adresa').required('Povinný údaj'),
                name: Yup.string()
                    .max(100, 'Jméno nesmí obsahovat více než 100 znaků')
                    .required('Povinný údaj'),
                password1: Yup.string()
                    .required('Heslo musí být vyplněno')
                    .min(5, 'Heslo je příliš krátké'),
                password2: Yup.string()
                    .oneOf([Yup.ref('password1'), null], "Hesla se neshodují")
                    .required('Heslo musí být vyplněno')
            })
        }
        onSubmit={(values, {setSubmitting, setFieldError, setStatus}) => {
            if (agree) {
                postFormDataWithoutAuthorization(`${API_URL}/auth/registration/`, values).then(data => {
                    setSubmitting(false);
                    store.addNotification({
                        title: "Vítejte mezi chytrými včelaři!",
                        message: <>Váš účet byl úspěšně založen.</>,
                        type: "info",
                        insert: "top",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        slidingExit: {
                            duration: 0,
                            timingFunction: 'ease-out',
                            delay: 0
                        },
                        dismiss: {
                            duration: 5000,
                            pauseOnHover: true
                        }
                    });
                    login({
                        email: values.email,
                        password: values.password1
                    }).then(({error}) => {
                        if (!error) {
                            history.replace({pathname: "/profil/"})
                        } else {
                            // We shouldn't get here
                        }
                    });
                }).catch(function (err) {
                    for (const key of Object.keys(err)) {
                        setFieldError(key, err[key]);
                    }
                    setSubmitting(false);
                });
            } else {
                setSubmitting(false);
                setStatus("noagree")
            }
        }
        }
    >
        {props => (
            <Form>
                <TextField disabled={props.isSubmitting} name="name" label="Jméno"/>
                <TextField disabled={props.isSubmitting} name="email" label="Email"/>
                <PasswordField disabled={props.isSubmitting} name="password1" label="Heslo"/>
                <PasswordField disabled={props.isSubmitting} name="password2"
                               label="Heslo znovu (pro jistotu)"/>
                <div className="field has-text-centered mt-5">
                    <button type="submit" className="button"
                            disabled={((!(props.isValid && props.dirty)))}>Založit účet
                    </button>
                </div>
                {props.status == "noagree" &&
                <p className="has-text-danger">Pro registraci je potřeba souhlasit.</p>
                }
                <Field className="mt-3" type="checkbox" checked={agree} onClick={() => {
                    setAgree(!agree);
                    props.setStatus("");
                }}/> Souhlasím s <Link to="/terms/" onClick={() => window.scrollTo(0, 0)}>podmínkami
                služby</Link> a <Link to="/terms/" onClick={() => window.scrollTo(0, 0)}>zpracováním osobních
                údajů</Link>.
            </Form>)}
    </Formik>
}