import useAuth from "../hooks/useAuth";
import {useInput} from "../hooks/useInput";
import {Link, useHistory, useLocation} from "react-router-dom";
import {FaEnvelope, FaLock} from "react-icons/fa";
import {useEffect, useState} from "react";
import LoginButton from "../components/LoginButton";

export default function Login() {
    const {login} = useAuth();
    let location = useLocation();
    let history = useHistory();

    const [emailProps, ] = useInput("");
    const [passwordProps, resetPassword] = useInput("");
    const [formError, setFormError] = useState()

    let {from} = location.state || {from: {pathname: "/muj-denik/"}};

    useEffect(() => {
        document.title = "Chytrý včelař | Přihlášení"
    }, []);

    const handleLogin = (event) => {
        event.preventDefault();
        login({
            email: emailProps.value,
            password: passwordProps.value
        }).then(({error}) => {
            resetPassword();
            if (!error) {
                history.replace(from);
            } else {
                setFormError(error);
            }
        });
    };

    return (
        <div>
            <div className="section pt-3">
                <div className="container mt-4">
                    <div className="section">
                        <div className="columns is-centered">
                            <div className="column is-6-tablet is-5-desktop is-4-widescreen">
                                <form method="post" className="box">
                                    <div className="field">
                                        <label htmlFor="" className="label">Email</label>
                                        <div className="control has-icons-left">
                                            <input
                                                {...emailProps}
                                                type="email"
                                                name="username"
                                                placeholder="emailová adresa"
                                                className="input"
                                                required/>
                                            <span className="icon is-small is-left">
                             <FaEnvelope />
                </span>
                                            {/*<p className="has-text-danger">{{form.username.errors.0}}</p>*/}
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label htmlFor="" className="label">Heslo</label>
                                        <div className="control has-icons-left">
                                            <input {...passwordProps}
                                                   type="password"
                                                   name="password"
                                                   placeholder="*******"
                                                   className="input"
                                                   required/>
                                            <span className="icon is-small is-left">
                  <FaLock />
                </span>
                                            {/*<p className="has-text-danger">{{form.password.errors.0}}</p>*/}
                                        </div>
                                    </div>
                                    <p className="has-text-danger">{ formError }</p>
                                    <div className="field has-text-centered mt-6">
                                        <LoginButton onClick={handleLogin}>Přihlásit</LoginButton>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <p className="has-text-centered mt-4 content is-small"><Link to='/zapomenute-heslo/'>Zapomenuté heslo</Link></p>
                        <p className="has-text-centered mt-4 content"><Link to='/registrace/'>Nemáte účet? Zaregistrujte se</Link></p>
                    </div>
                </div>
            </div>
        </div>
    );
};