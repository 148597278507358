import {storage} from "../config";

export function putFormData(uri, values) {

    if (!uri) return;
    const accessToken = storage.getItem("ud") && JSON.parse(storage.getItem("ud")).access_token;
    let formData = new FormData();
    for (let value in values) {
        if (value === "image" && typeof values[value] === "string") {
            continue;
        }

        if (values[value] instanceof Date) {
            formData.append(value, values[value].toISOString());
        } else {
            formData.append(value, values[value]);
        }
    }

    let success = false;
    return fetch(uri, {
        method: "PUT",
        headers: {
            'Authorization': `Bearer ${accessToken}`,
        },
        body: formData
    }).then(response => {
        success = (response.status === 200);
        return response.json();
    }).then((data) => {
        if (success) {
            return data;
        } else {
            throw data;
        }
    })

}