import {useFetch} from "../hooks/useFetch";
import {API_URL} from "../config";
import {useEffect, useMemo} from "react";
import {Link, useLocation} from "react-router-dom";


function useQuery() {
    const {search} = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

const PaymentStatusQ = ({payment}) => {

    const {data} = useFetch(
        `${API_URL}/pay/status/${payment}/`
    );

    return <>
        {data && data.state == "CREATED" && <PaymentInfo>Zatím se nepodařilo zrealizovat platbu.</PaymentInfo>}
        {data && data.state == "PAYMENT_METHOD_CHOSEN" && <PaymentInfo>Čeká se na platbu.</PaymentInfo>}
        {data && data.state == "AUTHORIZED" && <PaymentInfo>Platba byla autorizována.</PaymentInfo>}
        {data && data.state == "CANCELED" && <PaymentInfo>Platba byla zamítnuta.</PaymentInfo>}
        {data && data.state == "TIMEOUTED" && <PaymentInfo>Platba se nezdařila.</PaymentInfo>}
        {data && data.state == "REFUNDED" && <PaymentInfo>Platba byla vrácena.</PaymentInfo>}
        {data && data.state == "PARTIALLY_REFUNDED" && <PaymentInfo>Platba byla částečně vrácena.</PaymentInfo>}
        {data && data.state == "PAID" && <MakeUserPremium orderNumber={data.order_number}/>}
    </>
}

const PaymentInfo = ({children}) => {
    return <div className="card p-4"><p>{children} <Link to="/profil/">Zpět na profil</Link></p>
    </div>
}

const MakeUserPremium = ({orderNumber}) => {
    const {data} = useFetch(
        `${API_URL}/pay/make-premium/${orderNumber}/`
    );

    return data ?
        <div className="card p-4"><p>Gratulujeme, platba se povedla, nyní můžete používat Chytrého včelaře v režimu
            Premium! <Link to="/profil/">Zpět na profil</Link></p></div> :
        <PaymentInfo>Děkujeme, platba již byla uhrazena.</PaymentInfo>
}

export default function PaymentStatus() {

    let query = useQuery();

    return (
        <PaymentStatusQ payment={query.get("id")}/>
    );
}