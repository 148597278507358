import {useHistory} from "react-router-dom";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {API_URL} from "../config";
import {store} from "react-notifications-component";
import {putFormData} from "../utils/putFormData";
import {TextField} from "./TextField";

export const ProfileForm = ({edit = false, initialValues}) => {
    let history = useHistory();

    return <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
            name: Yup.string()
                .max(100, 'Jméno nesmí obsahovat více než 100 znaků')
                .required('Povinný údaj'),
            email: Yup.string().email('Neplatná adresa').required('Povinný údaj')
        })}
        onSubmit={(values, {setSubmitting, setFieldError}) => {
            const name = values.name
            let split = name.split(" ")
            values.first_name = split[0]
            values.last_name = split.slice(1).join(" ")
            values.name = undefined

            putFormData(`${API_URL}/profile/`, values).then(data => {
                setSubmitting(false);
                store.addNotification({
                    title: "Uživatel upraven",
                    message: <>Profil uživatele jménem {name} byl úspěšně upraven.</>,
                    type: "info",
                    insert: "top",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    slidingExit: {
                        duration: 0,
                        timingFunction: 'ease-out',
                        delay: 0
                    },
                    dismiss: {
                        duration: 5000,
                        pauseOnHover: true
                    }
                });
                history.replace({pathname: "/profil/"})
            }).catch(function (err) {
                for (const key of Object.keys(err)) {
                    setFieldError(key, err[key]);
                }
                setSubmitting(false);
            });
        }}
    >
        {props => (
            <Form>
                <div className="columns">
                    <div className="column">
                        <h1 className="title is-4">Úprava uživatele</h1>
                    </div>
                    <div className="column has-text-right">
                        {props.isSubmitting ?
                            <button type="submit" className="button"
                                    disabled>Ukládám...</button>
                            :
                            <button type="submit" className="button"
                                    disabled={((!(props.isValid && props.dirty)))}>Uložit</button>}
                    </div>
                </div>
                <hr className="mt-0"/>
                <div className="columns">
                    <div className="column">
                        <TextField disabled={props.isSubmitting} name="name" label="Jméno a příjmení"/>
                        <TextField disabled={props.isSubmitting} name="number" label="Číslo chovatele"/>
                    </div>
                    <div className="column">
                        <TextField disabled={props.isSubmitting} name="email" label="Přihlašovací email"/>
                        <TextField disabled={props.isSubmitting} name="organization" label="Organizace"/>
                    </div>
                </div>
            </Form>)}
    </Formik>
}