import {Link} from "react-router-dom";
import {useFetch} from "../hooks/useFetch";
import {API_URL} from "../config";
import {FaFlag} from "react-icons/fa";

export function BreadcrumbItem({children, title, to}) {
    return (<li>
        <Link to={to}>
            {children}
            <span className={children ? "ml-2" : ""}>{title}</span>
        </Link>
    </li>);
}

export function LocationBreadcrumbItem ({locationSlug}) {

    const {data, error} = useFetch(
        `${API_URL}/hive-locations/${locationSlug}/`
    );

    return data ? <BreadcrumbItem title={data.name} to={`/stanoviste/${data.slug}`}><FaFlag/></BreadcrumbItem> : ""
}

export default function Breadcrumb({children}) {
    return (
        <div className="container-fluid has-background-light p-2">
            <nav className="breadcrumb is-small has-succeeds-separator" aria-label="breadcrumbs">
                <ul>
                    {children}
                </ul>
            </nav>
        </div>
    );
}