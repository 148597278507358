import * as React from "react";
import {useState} from "react";
import {API_URL, storage} from "../config";

const authContext = React.createContext();

function useAuth() {
    const [, setAuthed] = useState()

    return {
        isLoggedIn: ((storage.getItem("ud") && true) || false),
        userData: storage.getItem("ud") && JSON.parse(storage.getItem("ud")).user,
        login(credentials) {
            return new Promise((resolve, reject) => {
                let success = false;
                return fetch(`${API_URL}/auth/login/`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(credentials)
                })
                    .then(response => {
                        success = (response.status === 200);
                        return response.json();
                    })
                    .then((data) => {
                        if (success) {
                            let {access_token} = data;
                            let {email, first_name, last_name, is_premium} = data.user;
                            let full_name = `${first_name}`;
                            if (last_name) {
                                full_name = `${first_name} ${last_name}`;
                            }
                            storage.setItem("ud", JSON.stringify({access_token, user: {email, full_name, is_premium}}));
                            setAuthed(true);
                            resolve({});
                        } else {
                            resolve({error: data.non_field_errors})
                        }
                    })
                    .catch((reason) => {
                        resolve({error: "Omlouváme se, server je dočasně mimo provoz."})
                    })
            });
        },
        logout() {
            return new Promise((res) => {
                storage.removeItem("ud");
                setAuthed(false);
                res();
            });
        }
    };
}

export function AuthProvider({children}) {
    const auth = useAuth();

    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    );
}

export default function AuthConsumer() {
    return React.useContext(authContext);
}
