import {useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import {FaPlus} from "react-icons/fa";

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

const thumbDelete = {
    right: '0.5rem',
    position: 'absolute',
    top: '0.5rem'
}


export const ImageField = ({name, initial, ...props}) => {
    const [fieldFiles, setFieldFiles] = useState(initial ? [{name: "initial", preview: initial}] : []);
    const {setFieldValue} = props;
    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            setFieldFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            setFieldValue(name, acceptedFiles[0]);
        }
    });

    const thumbs = fieldFiles.map(file => (
        <div key={1} style={{position: 'relative'}}>
            <button className="delete" style={thumbDelete} onClick={e => {
                e.stopPropagation();
                setFieldFiles([])
                setFieldValue(name, [])
            }}></button>
            <div
                style={thumb}
                key={file.name}>
                <div style={thumbInner}>
                    <img
                        alt=""
                        className="has-rounded-corners"
                        src={file.preview}
                        style={img}
                    />
                </div>
            </div>
        </div>
    ));

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        fieldFiles.forEach(file => URL.revokeObjectURL(file.preview));
    }, [fieldFiles]);

    return (
        <div className="field">
            <label className="label">Úvodní fotka</label>
            <div {...getRootProps({className: 'dropzone box'})}>
                <p className={`has-text-centered ${thumbs.length > 0 ? "is-hidden" : ""}`}><FaPlus className="mr-2"/>Přidat
                    fotku</p>
                <input {...getInputProps()} />
                <aside
                    style={thumbsContainer}>
                    {thumbs}
                </aside>
            </div>
        </div>
    );
}

export const ImagesField = ({name, extraStyle, onChange, onRemove, label, maxCount = 10, initial = [], ...props}) => {

    const [images, setImages] = useState([]);
    const [imageURLs, setImageURLs] = useState([]);
    const [initialImages, setInitialImages] = useState(initial);
    const [toRemove, setToRemove] = useState([]);


    useEffect(() => {
        setImageURLs(images.map(image => URL.createObjectURL(image)))
        onChange(images);
    }, [images])

    const onImageChange = (e) => {
        setImages(oldImages => [
            ...oldImages,
            ...e.target.files
        ].slice(0, maxCount - initialImages.length));
    }

    const onImageRemove = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const index = e.target.value;
        setImages(oldImages => oldImages.filter((value, i) => index != i));
    }

    const onInitialImageRemove = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const index = e.target.value;
        setToRemove(oldImages => [...oldImages, ...initialImages.filter((value, i) => index == i)])
        setInitialImages(oldImages => oldImages.filter((value, i) => index != i));
    }

    useEffect(() => {
        onRemove(toRemove);
    }, [toRemove])

    return (
        <div className="field">
            <label className="label">{label}</label>
            <div className="box" style={{position: "relative", ...extraStyle}}>
                {images.length + initialImages.length < maxCount ?
                    <p className="has-text-centered"><FaPlus className="mr-2"/>Přidat fotky (lze i přetažením)</p> :
                    <p className="has-text-centered">Maximální počet fotek: {maxCount}</p>}
                <input disabled={images.length + initialImages.length >= maxCount} className="upload-input" type="file" multiple
                       accept="image/*" onChange={onImageChange}/>
                <div>
                    {initialImages.map((image, index) =>
                        <figure key={image.id} className="image is-inline-flex is-96x96 mr-2 mt-4 mb-0">
                            <button value={index} className="delete" style={thumbDelete}
                                    onClick={onInitialImageRemove}></button>
                            <img className="has-rounded-corners"
                                 style={{objectFit: "cover", border: "2px solid whiteSmoke"}} alt=""
                                 src={image.image_url}/>
                        </figure>)}
                    {imageURLs.map((imageSrc, index) =>
                        <figure key={imageSrc} className="image is-inline-flex is-96x96 mr-2 mt-4 mb-0">
                            <button value={index} className="delete" style={thumbDelete}
                                    onClick={onImageRemove}></button>
                            <img className="has-rounded-corners"
                                 style={{objectFit: "cover", border: "2px solid whiteSmoke"}} alt=""
                                 src={imageSrc}/>
                        </figure>)}
                </div>
            </div>
        </div>
    );
}