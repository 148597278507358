export const ConfirmDialog = ({question, isActive, onClose, onConfirm}) => {

    const performConfirmAction = () => {
        onConfirm();
        onClose();
    }

    return <div className={`modal ${isActive ? "is-active" : ""}`}>
        <div onClick={onClose} className="modal-background"></div>
        <div className="modal-card">
            <header className="modal-card-head">
                <p className="modal-card-title" style={{fontSize: "1.2rem"}}>{question}</p>
                <button className="delete" onClick={onClose}></button>
            </header>
            <footer className="modal-card-foot" style={{justifyContent: "flex-end"}}>
                <button className="button is-danger" onClick={performConfirmAction}>Ano</button>
                <button className="button is-gray" onClick={onClose}>Ne, vrátit zpět</button>
            </footer>
        </div>
    </div>
}
