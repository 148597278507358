import {FaCalendar, FaFlag, FaMapMarkerAlt, FaTag} from "react-icons/fa";
import phLocation from "../img/location-ph.svg";
import {formatDate} from "../utils/utils";

export function HiveLocationCard({location}) {
    return <div className="panel">
        <p className="panel-heading is-size-6">
            <FaFlag className="mr-2"/>Informace o stanovišti
        </p>
        <div className="p-5">
            <figure className="image is-4by3 mb-4">
                {location.image_url
                    ?
                    <img className="has-rounded-corners cover" src={location.image_url} alt={location.name}/>
                    :
                    <img className="has-rounded-corners cover" src={phLocation} alt={location.name}/>
                }
            </figure>
            {location.address_line_1 ?
                <p className="mb-1 content is-small">
                    <FaMapMarkerAlt className="mr-3"/>
                    {location.address_line_1}
                </p> : ""}

            {location.registration_number ?
                <p className="mb-1 content is-small">
                    <FaTag className="mr-3"/>
                    {location.registration_number}
                </p> : ""}

            {location.established_date ?
                <p className="mb-1 content is-small">
                    <FaCalendar className="mr-3"/>
                    Založení: {formatDate(location.established)}
                </p> :
                <p className="mb-1 content is-small">
                    <FaCalendar className="mr-3"/>
                    Vytvoření: {formatDate(location.created)}
                </p>
            }
        </div>
    </div>
        ;
}
