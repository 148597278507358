import {BiHive} from "react-icons/bi";
import {formatDate} from "../utils/utils";
import {HiveFigure} from "./Hive";
import {HiveGallery} from "./HiveGallery";

export const HiveCard = ({hive}) => {
    return <div className="panel">
        <p className="panel-heading is-size-6">
            <BiHive className="mr-2"/>Informace o úlu
        </p>
        <div className="p-5">
            <div className="media">
                <div className="media-left has-text-centered is-3">
                    <HiveFigure hive={hive}/>
                </div>
                <div className="media-content">
                    {hive.type &&
                    <p className="mb-1 content is-small">
                        {hive.type} {hive.frame_type && <>({hive.frame_type})</> }
                    </p>}
                    <p className="mb-1 content is-small">
                        Nástavků: {hive.box_count}
                    </p>
                    {hive.colony_type &&
                    <p className="mb-1 content is-small">
                        Včelstvo: {hive.colony_type}
                    </p>}
                    {hive.established_date ?
                        <p className="mb-1 content is-small">
                            Založení: {formatDate(hive.established)}
                        </p> :
                        <p className="mb-1 content is-small">
                            Vytvoření: {formatDate(hive.created)}
                        </p>
                    }
                </div>
            </div>
            <HiveGallery hive={hive.slug}></HiveGallery>
        </div>
    </div>
        ;
}
