import {useEffect, useState} from "react";
import {HiveFigure} from "./Hive";
import {FaAngleDown, FaAngleUp, FaCheck} from "react-icons/fa";
import {TagFieldNumber, TagFieldOptions, TagsRow} from "./TagFieldOptions";
import {TimeField} from "./TimeField";
import {Field, Form, Formik} from "formik";
import {TextAreaField} from "./TextAreaField";
import {ImagesField} from "./ImageField";
import {putFormData} from "../utils/putFormData";
import {API_URL} from "../config";
import {postFormData} from "../utils/postFormData";
import {deleteFormData} from "../utils/deleteFormData";
import {store} from "react-notifications-component";
import {useHistory} from "react-router-dom";
import {ProgressBar} from "./ProgressBar";


export default function EntryForm({
                                      isInline = true,
                                      initialValues,
                                      initialTags = [],
                                      initialImages = [],
                                      locationSlug,
                                      hive,
                                      hiveId,
                                      active,
                                      onClick,
                                      onValuesChanged,
                                      onDirtyChanged,
                                      onReadyChanged,
                                      id,
                                      returnUrl = null
                                  }) {
    let history = useHistory();
    const [tags, setTags] = useState(initialTags);
    const [images, setImages] = useState([]);
    const [imagesToRemove, setImagesToRemove] = useState([]);
    const [active0, setActive0] = useState(false);
    const [active1, setActive1] = useState(false);
    const [active2, setActive2] = useState(false);
    const [active3, setActive3] = useState(false);
    const [active4, setActive4] = useState(false);
    const [created_time, setCreated_time] = useState();
    const [text, setText] = useState();
    const [values, setValues] = useState({});
    const [isDirty, setIsDirty] = useState(false);
    const [isReady, setIsReady] = useState(false);


    useEffect(() => {
        setValues({
            tags,
            images,
            created_time,
            text
        })
    }, [tags, images, created_time, text])

    useEffect(() => {
        onValuesChanged(values);
    }, [values])

    useEffect(() => {
        onDirtyChanged(isDirty || tags.length > 0 || images.length > 0);
    }, [isDirty, tags, images])

    useEffect(() => {
        onReadyChanged(isReady || tags.length > 0 || images.length > 0);
    }, [isReady, tags, images])


    const onIncludeTags = (toInclude) => {
        const tagsToInclude = toInclude.map(tag => [tag, null]);
        setTags(oldTags => [...oldTags, ...tagsToInclude]);
    };

    const onExcludeTags = (toExclude) => {
        setTags(oldTags => oldTags.filter(x => !toExclude.includes(x[0])));
    };

    const onSetNumberTag = (tag, value) => {
        if (value != null) {
            setTags(oldTags => [...oldTags.filter(x => tag != x[0]), [tag, parseFloat(value)]]);
        } else {
            setTags(oldTags => [...oldTags.filter(x => tag != x[0])]);
        }
    };

    const endEditing = (created = false) => {
        store.addNotification({
            title: "Prohlídka upravena",
            message: <>Prohlídka úlu byla úspěšně upravena.</>,
            type: "info",
            insert: "top",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            slidingExit: {
                duration: 0,
                timingFunction: 'ease-out',
                delay: 0
            },
            dismiss: {
                duration: 5000,
                pauseOnHover: true
            }
        });
        if (!returnUrl)
            history.replace({pathname: `/stanoviste/${locationSlug}/`})
        else {
            history.replace({pathname: `${returnUrl}`})
        }
    };

    const hasHive = () => {
        return (Object.keys(hive).length !== 0)
    }

    const hiveProperty = (property) => {
        return hive[property] || false
    }

    return (<Formik
        initialValues={initialValues}
        onSubmit={(values, {setSubmitting, setStatus, setFieldError}) => {
            if (id) {
                values.hive = hiveId;
                if (values.created_time) {
                    const [hours, minutes] = values.created_time.split(":");
                    values.created.setHours(hours);
                    values.created.setMinutes(minutes);
                }
                values.created_time = undefined

                // tags
                values.tags = JSON.stringify(tags)

                let totalImages = images.length
                let imagesToUpload = images.length
                setStatus(0)

                putFormData(`${API_URL}/entries/${id}/`, values).then(entryData => {
                    // images
                    if (imagesToUpload === 0) {
                        setStatus(100)
                        setSubmitting(false);
                        endEditing();
                    }

                    images.forEach((image, order) => {
                        const imageData = {
                            entry: id,
                            image: image,
                            order: order
                        }

                        postFormData(`${API_URL}/entries-images/`, imageData).then(data => {
                            imagesToUpload -= 1;
                            setStatus(Math.round(100 - (imagesToUpload * (100 / totalImages))))
                            if (imagesToUpload === 0) {
                                setSubmitting(false);
                                endEditing();
                            }
                        }).catch(function (err) {
                            imagesToUpload -= 1;
                            setStatus(Math.round(100 - (imagesToUpload * (100 / totalImages))))
                            if (imagesToUpload === 0) {
                                setSubmitting(false);
                                endEditing();
                            }
                        });
                    });

                    imagesToRemove.forEach(image => {
                        deleteFormData(`${API_URL}/entries-images/${image.id}`).then(data => {
                        }).catch(function (err) {
                        });
                    })

                }).catch(function (err) {
                    for (const key of Object.keys(err)) {
                        setFieldError(key, err[key]);
                    }
                    setSubmitting(false);
                });
            }
        }}
    >
        {props => (
            <Form>
                {props.isSubmitting && <ProgressBar value={props.status}/>}
                <Field name="created" type="hidden"/>
                {!isInline &&
                    <>
                        <div className="columns">
                            <div className="column">
                                <h1 className="title is-4">Úprava prohlídky</h1>
                            </div>
                            <div className="column has-text-right">
                                {props.isSubmitting ?
                                    <button type="submit" className="button"
                                            disabled>Ukládám ({props.status}%) ...</button>
                                    :
                                    <button type="submit" className="button"
                                            disabled={
                                                // TODO
                                                false
                                            }>Uložit</button>}
                            </div>
                        </div>
                        <hr className="mt-0"/>
                    </>}

                <div className={isInline ? "card mt-5 hive-card" : ""} style={{
                    borderLeftWidth: isInline ? "0.25em" : "0",
                    borderColor: hive.color ? hive.color : "#999"
                }}>
                    {isInline &&
                        <div onClick={onClick} className={isInline ? "card-content pt-2 pb-2" : ""} style={{
                            backgroundColor: active ? "whiteSmoke" : "unset", cursor: "pointer"
                        }}>
                            <div className="media">
                                <div className="media-left">
                                    <HiveFigure hive={hive}/>
                                </div>
                                <div className="media-content">
                                    <p className="title is-6">{hive.name}</p>
                                    <p className="subtitle is-6">
                                        Nástavků: {hive.box_count}
                                    </p>
                                </div>
                                <div>{props.values.text || tags.length > 0 || images.length > 0 ?
                                    <span style={{color: "green"}}><FaCheck
                                        className="mr-2 vatt"/>Prohlídka v {props.values.created_time}</span> : <>Prázdná
                                        prohlídka</>}</div>

                                {/*{ !active &&*/}
                                {/*<div style={{margin: "auto"}}>*/}
                                {/*    {(props.dirty || tags.length > 0 || images.length > 0) ?*/}
                                {/*        <button type="submit" className="button is-dark">Upravit prohlídku</button> :*/}
                                {/*        <button type="submit" className="button is-dark">Přidat prohlídku</button>}*/}
                                {/*</div>}*/}
                            </div>
                        </div>}
                    <div style={{display: active ? "block" : "none"}}
                         className={isInline ? "card-content pt-0 pb-2" : ""}>

                        <div className="mt-4">
                            <TimeField disabled={props.isSubmitting} onChange={(e) => {
                                props.handleChange(e);
                                setIsDirty(true);
                                setCreated_time(e.target.value)
                            }} name="created_time" label="Čas prohlídky"/>
                        </div>

                        <div className="mt-4">
                            <ImagesField extraStyle={{minHeight: "13rem"}} label="Fotky úlu"
                                         initial={initialImages}
                                         onChange={images => setImages(images)}
                                         onRemove={images => setImagesToRemove(images)}/>
                        </div>

                        <div className="card mt-5 mb-5">
                            <header style={{cursor: "pointer"}} onClick={(() => setActive0(v => !v))}
                                    className="card-header">
                                <p className="card-header-title">
                                    Vnější prohlídka
                                </p>
                                <button className="card-header-icon">
                                    {active0 ? <FaAngleUp/> : <FaAngleDown/>}
                                </button>
                            </header>
                            {active0 && <>
                                <TagsRow>
                                    <label className="label is-small">Česno</label>
                                    <TagFieldOptions multiple={true}
                                                     onInclude={onIncludeTags}
                                                     onExclude={onExcludeTags}
                                                     currentValue={tags}
                                                     tags={[7, 8, 9, 10, 11, 12, 13, 14, 15]}/>
                                </TagsRow>
                                <TagsRow>
                                    <label className="label is-small">Podmet</label>
                                    <TagFieldOptions multiple={true}
                                                     onInclude={onIncludeTags}
                                                     onExclude={onExcludeTags}
                                                     currentValue={tags}
                                                     tags={[16, 17]}/>
                                    <TagFieldNumber tag={18} onSet={onSetNumberTag} defaultValue="0" minValue={0}
                                                    currentValue={tags}
                                                    maxValue={400}/>
                                    <TagFieldOptions multiple={true}
                                                     onInclude={onIncludeTags}
                                                     onExclude={onExcludeTags}
                                                     currentValue={tags}
                                                     tags={[19]}/>
                                </TagsRow>
                                <TagsRow>
                                    <label className="label is-small">Podložka</label>
                                    <TagFieldNumber tag={20} onSet={onSetNumberTag} defaultValue="0" minValue={0}
                                                    currentValue={tags}
                                                    maxValue={2400}/>
                                    <TagFieldOptions multiple={false}
                                                     onInclude={onIncludeTags}
                                                     onExclude={onExcludeTags}
                                                     currentValue={tags}
                                                     tags={[21]}/>
                                    <TagFieldNumber tag={22} onSet={onSetNumberTag} defaultValue="0" minValue={0}
                                                    maxValue={2000} currentValue={tags}/>
                                    <TagFieldNumber tag={23} onSet={onSetNumberTag} defaultValue="0" minValue={0}
                                                    maxValue={11} currentValue={tags}/>
                                    <TagFieldOptions multiple={false}
                                                     onInclude={onIncludeTags}
                                                     onExclude={onExcludeTags}
                                                     currentValue={tags}
                                                     tags={[104]}/>
                                </TagsRow>
                            </>}
                        </div>

                        <div className="card mt-5 mb-5">
                            <header style={{cursor: "pointer"}} onClick={(() => setActive1(v => !v))}
                                    className="card-header">
                                <p className="card-header-title">
                                    Stav včelstva
                                </p>
                                <button className="card-header-icon">
                                    {active1 ? <FaAngleUp/> : <FaAngleDown/>}
                                </button>
                            </header>
                            {active1 && <>
                                <TagsRow>
                                    <TagFieldOptions multiple={false}
                                                     onInclude={onIncludeTags}
                                                     onExclude={onExcludeTags}
                                                     tags={[40, 41]} currentValue={tags}/>
                                    <TagFieldNumber tag={42} onSet={onSetNumberTag} defaultValue="0"
                                                    minValue={0} maxValue={33} currentValue={tags}/>
                                    <TagFieldOptions multiple={false}
                                                     onInclude={onIncludeTags}
                                                     onExclude={onExcludeTags}
                                                     currentValue={tags}
                                                     tags={[92]}/>
                                </TagsRow>
                                <TagsRow>
                                    <label className="label is-small">Stav plodu</label>
                                    <TagFieldNumber currentValue={tags} tag={43} onSet={onSetNumberTag} defaultValue="0"
                                                    minValue={0} maxValue={33}/>
                                    <TagFieldNumber currentValue={tags} tag={44} onSet={onSetNumberTag} defaultValue="0"
                                                    minValue={0} maxValue={33}/>
                                    <TagFieldOptions currentValue={tags} multiple={true}
                                                     onInclude={onIncludeTags}
                                                     onExclude={onExcludeTags}
                                                     tags={[45, 47]}/>
                                    <TagFieldNumber currentValue={tags} tag={67} onSet={onSetNumberTag} defaultValue="0"
                                                    minValue={0} maxValue={30}/>
                                    <TagFieldNumber currentValue={tags} tag={68} onSet={onSetNumberTag} defaultValue="0"
                                                    minValue={0} maxValue={30}/>
                                    <TagFieldNumber currentValue={tags} tag={69} onSet={onSetNumberTag} defaultValue="0"
                                                    minValue={0} maxValue={30}/>
                                </TagsRow>
                                <TagsRow>
                                    <label className="label is-small">Síla včelstva</label>
                                    <TagFieldOptions currentValue={tags} multiple={false}
                                                     onInclude={onIncludeTags}
                                                     onExclude={onExcludeTags}
                                                     tags={[87, 48, 88, 49, 89]}/>
                                </TagsRow>
                                <TagsRow>
                                    <label className="label is-small">Nálada</label>
                                    <TagFieldOptions currentValue={tags} multiple={false}
                                                     onInclude={onIncludeTags}
                                                     onExclude={onExcludeTags}
                                                     tags={[50, 51, 52]}/>
                                </TagsRow>
                                <TagsRow>
                                    <label className="label is-small">Nemoc</label>
                                    <TagFieldOptions currentValue={tags} multiple={true}
                                                     onInclude={onIncludeTags}
                                                     onExclude={onExcludeTags}
                                                     tags={[53, 54, 55, 56, 57, 58, 59]}/>
                                </TagsRow>
                                <TagsRow>
                                    <label className="label is-small">Stav zásob</label>
                                    <TagFieldNumber currentValue={tags} tag={90} onSet={onSetNumberTag} defaultValue="0"
                                                    minValue={0} maxValue={33}/>
                                </TagsRow>
                            </>}
                        </div>
                        <div className="card mt-5 mb-5">
                            <header style={{cursor: "pointer"}} onClick={(() => setActive2(v => !v))}
                                    className="card-header">
                                <p className="card-header-title">
                                    Zásahy
                                </p>
                                <button className="card-header-icon">
                                    {active2 ? <FaAngleUp/> : <FaAngleDown/>}
                                </button>
                            </header>
                            {active2 && <>
                                <TagsRow>
                                    {!hasHive() ?
                                        <TagFieldOptions currentValue={tags} onInclude={onIncludeTags}
                                                         onExclude={onExcludeTags}
                                                         multiple={false}
                                                         tags={[72, 73]}/> :
                                        hiveProperty("queen_excluder") ?
                                            <TagFieldOptions currentValue={tags} onInclude={onIncludeTags}
                                                             onExclude={onExcludeTags}
                                                             tags={[73]}/> :
                                            <TagFieldOptions currentValue={tags} onInclude={onIncludeTags}
                                                             onExclude={onExcludeTags}
                                                             tags={[72]}/>}
                                    {!hasHive() ?
                                        <TagFieldOptions currentValue={tags} onInclude={onIncludeTags}
                                                         onExclude={onExcludeTags}
                                                         multiple={false}
                                                         tags={[74, 75]}/> :
                                        hiveProperty("bee_filter") ?
                                            <TagFieldOptions currentValue={tags} onInclude={onIncludeTags}
                                                             onExclude={onExcludeTags}
                                                             tags={[75]}/> :
                                            <TagFieldOptions currentValue={tags} onInclude={onIncludeTags}
                                                             onExclude={onExcludeTags}
                                                             tags={[74]}/>}
                                    {!hasHive() ?
                                        <TagFieldOptions currentValue={tags} onInclude={onIncludeTags}
                                                         onExclude={onExcludeTags}
                                                         multiple={false}
                                                         tags={[76, 77]}/> :
                                        hiveProperty("inhibition") ?
                                            <TagFieldOptions currentValue={tags} onInclude={onIncludeTags}
                                                             onExclude={onExcludeTags}
                                                             tags={[77]}/> :
                                            <TagFieldOptions currentValue={tags} onInclude={onIncludeTags}
                                                             onExclude={onExcludeTags}
                                                             tags={[76]}/>}
                                    {!hasHive() ?
                                        <TagFieldOptions currentValue={tags} onInclude={onIncludeTags}
                                                         onExclude={onExcludeTags}
                                                         multiple={false}
                                                         tags={[96, 97]}/> :
                                        hiveProperty("queen_cage") ?
                                            <TagFieldOptions currentValue={tags} onInclude={onIncludeTags}
                                                             onExclude={onExcludeTags}
                                                             tags={[97]}/> :
                                            <TagFieldOptions currentValue={tags} onInclude={onIncludeTags}
                                                             onExclude={onExcludeTags}
                                                             tags={[96]}/>}
                                    {(!hasHive() || hiveProperty("gabon")) &&
                                        <TagFieldOptions currentValue={tags} onInclude={onIncludeTags}
                                                         onExclude={onExcludeTags}
                                                         tags={[98]}/>}
                                    {(!hasHive() || hiveProperty("evaporator")) &&
                                        <TagFieldOptions currentValue={tags} onInclude={onIncludeTags}
                                                         onExclude={onExcludeTags}
                                                         tags={[99]}/>}
                                </TagsRow>
                                <TagsRow>
                                    <label className="label is-small">Nástavky a rámky</label>
                                    <TagFieldNumber currentValue={tags} tag={78} onSet={onSetNumberTag} defaultValue="0"
                                                    minValue={0} maxValue={10}/>
                                    {/*TODO + vypocitat maxValue*/}
                                    {false &&
                                        <TagFieldNumber currentValue={tags} tag={79} onSet={onSetNumberTag}
                                                        defaultValue="0"
                                                        minValue={0} maxValue={10}/>}
                                    <TagFieldNumber currentValue={tags} tag={80} onSet={onSetNumberTag} defaultValue="0"
                                                    minValue={0} maxValue={30}/>
                                    <TagFieldNumber currentValue={tags} tag={81} onSet={onSetNumberTag} defaultValue="0"
                                                    minValue={0} maxValue={30}/>
                                    <TagFieldNumber currentValue={tags} tag={82} onSet={onSetNumberTag} defaultValue="0"
                                                    minValue={0} maxValue={30}/>
                                    <TagFieldNumber currentValue={tags} tag={83} onSet={onSetNumberTag} defaultValue="0"
                                                    minValue={0} maxValue={30}/>
                                    <TagFieldNumber currentValue={tags} tag={84} onSet={onSetNumberTag} defaultValue="0"
                                                    minValue={0} maxValue={30}/>
                                    <TagFieldNumber currentValue={tags} tag={85} onSet={onSetNumberTag} defaultValue="0"
                                                    minValue={0} maxValue={30}/>
                                    <TagFieldOptions currentValue={tags} onInclude={onIncludeTags}
                                                     onExclude={onExcludeTags}
                                                     tags={[94]}/>
                                    <TagFieldNumber currentValue={tags} tag={95} onSet={onSetNumberTag} defaultValue="0"
                                                    minValue={0} maxValue={400}/>
                                </TagsRow>
                            </>}
                        </div>
                        <div className="card mt-5 mb-5">
                            <header style={{cursor: "pointer"}} onClick={(() => setActive3(v => !v))}
                                    className="card-header">
                                <p className="card-header-title">
                                    Léčení
                                </p>
                                <button className="card-header-icon">
                                    {active3 ? <FaAngleUp/> : <FaAngleDown/>}
                                </button>
                            </header>
                            {active3 && <>
                                <TagsRow>
                                    <label className="label is-small">Organické</label>
                                    <TagFieldOptions currentValue={tags} multiple={true}
                                                     onInclude={onIncludeTags}
                                                     onExclude={onExcludeTags}
                                                     tags={[24, 27, 28, 29, 34, 35, 37]}/>
                                    <TagFieldNumber currentValue={tags} tag={39} onSet={onSetNumberTag} defaultValue="0"
                                                    minValue={0} maxValue={50}/>
                                    <TagFieldNumber currentValue={tags} tag={60} onSet={onSetNumberTag} defaultValue="0"
                                                    minValue={0} maxValue={200}/>
                                    <TagFieldNumber currentValue={tags} tag={61} onSet={onSetNumberTag} defaultValue="0"
                                                    minValue={0} maxValue={200}/>
                                    <TagFieldNumber currentValue={tags} tag={62} onSet={onSetNumberTag} defaultValue="0"
                                                    minValue={0} maxValue={100}/>
                                    <TagFieldNumber currentValue={tags} tag={86} onSet={onSetNumberTag} defaultValue="0"
                                                    minValue={0} maxValue={500}/>
                                    <TagFieldNumber currentValue={tags} tag={93} onSet={onSetNumberTag} defaultValue="0"
                                                    minValue={0} maxValue={10}/>
                                    <TagFieldNumber currentValue={tags} tag={105} onSet={onSetNumberTag} defaultValue="0"
                                                    minValue={0} maxValue={10}/>
                                </TagsRow>
                                <TagsRow>
                                    <label className="label is-small">Syntetické</label>
                                    <TagFieldOptions currentValue={tags} multiple={true}
                                                     onInclude={onIncludeTags}
                                                     onExclude={onExcludeTags}
                                                     tags={[25, 26, 30, 31, 32, 33, 36]}/>
                                    <TagFieldNumber currentValue={tags} tag={38} onSet={onSetNumberTag} defaultValue="0"
                                                    minValue={0} maxValue={10}/>
                                </TagsRow>
                            </>}
                        </div>
                        <div className="card mt-5 mb-5">
                            <header style={{cursor: "pointer"}} onClick={(() => setActive4(v => !v))}
                                    className="card-header">
                                <p className="card-header-title">
                                    Krmení
                                </p>
                                <button className="card-header-icon">
                                    {active4 ? <FaAngleUp/> : <FaAngleDown/>}
                                </button>
                            </header>
                            {active4 && <>
                                <TagsRow>
                                    <TagFieldOptions
                                        currentValue={tags}
                                        onInclude={onIncludeTags}
                                        onExclude={onExcludeTags}
                                        tags={[70]}/>
                                    <TagFieldNumber currentValue={tags} tag={63} onSet={onSetNumberTag} defaultValue="0"
                                                    minValue={0} maxValue={11}/>
                                    <TagFieldNumber currentValue={tags} tag={64} onSet={onSetNumberTag} defaultValue="0"
                                                    minValue={0} maxValue={11}/>
                                    <TagFieldNumber currentValue={tags} tag={65} step={0.1} onSet={onSetNumberTag}
                                                    defaultValue="0"
                                                    minValue={0} maxValue={5}/>
                                    <TagFieldNumber currentValue={tags} tag={100} onSet={onSetNumberTag}
                                                    defaultValue="0"
                                                    minValue={0} maxValue={15}/>
                                    <TagFieldNumber currentValue={tags} tag={101} onSet={onSetNumberTag}
                                                    defaultValue="0"
                                                    step={0.1}
                                                    minValue={0} maxValue={5}/>
                                    <TagFieldNumber currentValue={tags} tag={102} onSet={onSetNumberTag}
                                                    defaultValue="0"
                                                    minValue={0} maxValue={15}/>
                                    <TagFieldNumber currentValue={tags} tag={103} onSet={onSetNumberTag}
                                                    defaultValue="0"
                                                    step={0.1}
                                                    minValue={0} maxValue={5}/>
                                </TagsRow>
                            </>}
                        </div>
                        <div className="mb-4">
                            <TextAreaField name="text"
                                           onChange={(e) => {
                                               props.handleChange(e);
                                               setIsDirty(true);
                                               setIsReady(props.values.text || tags.length > 0 || images.length > 0)
                                               setText(e.target.value)
                                           }}
                                           disabled={props.isSubmitting} label="Poznámka k úlu"
                            />
                        </div>
                    </div>
                </div>
            </Form>)}
    </Formik>)
}

