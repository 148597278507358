import {useParams} from "react-router-dom";
import {useEffect} from "react";
import Breadcrumb, {BreadcrumbItem} from "../components/Breadcrumb";
import {FaEdit, FaFlag, FaHome} from "react-icons/fa";
import {useFetch} from "../hooks/useFetch";
import {API_URL} from "../config";
import {VisitForm} from "../components/VisitForm";


export default function EditNote() {
    let params = useParams();
    useEffect(() => {
        document.title = "Chytrý včelař | Upravit poznámku ke stanovišti"
    }, []);

    const {data} = useFetch(
        `${API_URL}/notes/${params.noteId}/`
    );

    return <>
        <Breadcrumb>
            <BreadcrumbItem title="Stanoviště" to="/muj-denik/"><FaHome/></BreadcrumbItem>

            {data &&
            <BreadcrumbItem title={data.location_name}
                            to={`/stanoviste/${data.location_slug}`}><FaFlag/></BreadcrumbItem>

            }

            <BreadcrumbItem title="Úprava poznámky" to="#"><FaEdit/></BreadcrumbItem>
        </Breadcrumb>
        <div className="container is-fluid mt-4">
            {data ?
                <>
                    <VisitForm
                        id={data.id}
                        locationId={data.location_id}
                        locationName={data.location_name}
                        locationSlug={data.location_slug}
                        initialValues={{
                            created: new Date(data.created),
                            created_time: new Date(data.created).toLocaleString('cs-CZ', {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false
                            }),
                            text: data.text || ""
                        }}
                        initialTags={data.tags}
                        initialImages={data.images}
                    />
                </>
                : <></>}
        </div>
    </>

}