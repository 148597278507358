export default function PrivacyPolicy() {
    return <div className="section pt-3">
        <div className="container">
            <div className="box mt-5 content">
                <p className="title is-5">Zásady zpracování osobních údajů
                </p>
                <p className="title is-6">1. Úvod</p>
                <p>1.1. Osobní údaje, které jsou získávány během návštěvy našich webových stránek a mobilní aplikace
                    zpracováváme v souladu se Zákonem č. 101/2000 Sb. o ochraně osobních údajů a též podle Nařízení o
                    ochraně osobních údajů (GDPR).</p>
                <p>1.2. Tento dokument se týká uživatelů mobilních aplikací a (nebo) webových stránek Chytrý včelař
                    (dále jen „Aplikace“).</p>

                <p className="title is-6">2. Kdo shromažďuje vaše osobní údaje?</p>
                <p>2.1. Vaše osobní údaje sbírá PunkHive s.r.o., IČ: 21152195, se sídlem Kaštanová 1055/14, Olomouc 779&nbsp;00, který provozuje mobilní aplikaci a web Chytrý včelař. Tento subjekt je oprávněn poskytovat a
                    prodávat Licenci (právo používat Aplikaci).</p>
                <p>2.2 Vaše osobní údaje zpracováváme jako správce, tj. určujeme, jak a za jakým účelem, po jak dlouhou
                    dobu budou osobní údaje zpracovávány.</p>
                <p>2.3. V průběhu zpracování dat nás můžete kontaktovat emailem punkhive@gmail.com</p>

                <p className="title is-6">3. Jaké údaje se shromažďujeme?</p>
                <p>3.1. Shromažďujeme následující osobní údaje, které jste poskytli v rámci Aplikace: jméno, email,
                    adresa, vaše aktivita v rámci Aplikace. Údaje jsou shromažďovány za účelem poskytování služby.</p>
                <p>3.2. K vytvoření vašeho účtu je nutné uvést minimálně následující údaje: jméno a email.</p>
                <p>3.3. Sbíráme také následující osobní údaje pro marketingové účely: jméno a email. Tyto údaje budeme
                    shromažďovat na základě vašeho souhlasu. Svůj souhlas můžete kdykoli odvolat.</p>

                <p className="title is-6">4. Jaký je právní základ pro zpracování údajů?</p>
                <p>4.1 Zpracujeme údaje uvedené v bodě 3.1. a bodě 6.1. za účelem poskytnout vám službu (právo na
                    přístup a používání Aplikace).</p>
                <p>4.2. Zpracujeme údaje uvedené v bodě 3.3. marketingové účely. Na základě vašeho souhlasu vám můžeme
                    čas od času zasílat marketingové emaily na propagaci naší služby.</p>

                <p className="title is-6">5. Budou data sdílena s třetími stranami?</p>
                <p>5.1. Vaše údaje nebudeme sdílet s třetími stranami pokud: nebudeme mít takovou právní povinnost,
                    budeme mít váš souhlas, je nutné sdílet informace za účelem vyšetřování, prevence nebo přijímání
                    opatření týkajících se nelegálních aktivit, podezření na podvod, situací zahrnujících potenciální
                    ohrožení fyzické bezpečnosti jakékoli osoby, porušení podmínek služby nebo jak to vyžaduje zákon.
                    Vaše údaje můžeme také sdílet s našimi poskytovateli služeb.
                </p>

                <p className="title is-6">6. Jak dlouho budou data uložena?</p>
                <p>6.1 Uložíme údaje uvedené v bodě 3.1. pokud vám budeme poskytovat služby v souladu s podmínkami,
                    zejména pokud máte na platformě účet. Abyste předešli pochybnostem, smíte smazat veškerá data
                    připojená k vašemu účtu, kromě jména a e-mailu (s ohledem na to, že bez těchto informací nemůžeme
                    váš uživatelský účet udržovat aktivní).
                </p>

                <p className="title is-6">7. Kde ukládáme data?</p>
                <p>7.1. Data zpracováváme výhradně v Evropské unii nebo v zemích, které zajišťují odpovídající úroveň
                    ochrany na základě rozhodnutí Evropské komise.
                </p>

                <p className="title is-6">8. Jaká máme bezpečností opatření?</p>
                <p>8.1. Z důvodu ochrany vašich dat jsme implementovali bezpečnostní opatření v souladu s platnými
                    zákony a osvědčenými postupy. Vaše data chráníme před případnými bezpečnostními incidenty, ale
                    nemůžeme zaručit, že k takovým incidentům nemůže dojít.
                </p>

                <p className="title is-6">9. Jaké máte práva v souvislosti s ochranou osobních údajů?</p>
                <p>9.1. V souvislosti s ochranou osobních údajů máte řadu práv. Pokud budete chtít některého z těchto
                    práv využít nebo chcete vznést stížnost, prosím, kontaktujte nás prostřednictvím emailu
                    punkhive@gmail.com.
                </p>
                <p>9.2. Podle nařízení GDPR máte právo na:</p>
                <ul>
                    <li>informace, které jsme splnili touto stránkou se Zásadami zpracování osobních údajů</li>
                    <li>přístup k vašim osobním údajům</li>
                    <li>požádání o opravu nesprávných, nepřesných nebo neúplných osobních údajů</li>
                    <li>požádání o vymazání osobních údajů, pokud již nejsou potřeba nebo pokud je jejich zpracování
                        nezákonné
                    </li>
                    <li>vznést námitku proti zpracování vašich osobních údajů pro marketingové účely</li>
                    <li>výmaz (být zapomenut) pokud už nechcete, abychom zpracovávali vaše osobní údaje, na vaši
                        žádost
                        smažeme všechny vaše osobní údaje, pokud nejsou vázány jiným zákonem (např. evidence
                        daňových
                        dokladů)
                    </li>
                </ul>
                <p>9.3. V určitých případech vás můžeme požádat o poskytnutí informací k potvrzení vaší identity. A
                    to
                    například: kliknutí na ověřovací odkaz, zadání uživatelského jména nebo hesla.</p>

                <p className="title is-6">10. Soubory cookie</p>
                <p>V případě návštěvy našich stránek, zaznamenáváme standardním způsobem IP adresu, přidělenou vaším
                    poskytovatelem, www stránku, z které nás navštívíte, jednotlivé navštívené stránky našeho webu a
                    datum a délku vaší návštěvy. Ukládání souborů cookies ve vašem prohlížeči je za účelem usnadnění
                    vaší orientace a pohybu na našich stránkách při opakovaných návštěvách. Identifikuje se pouze cookie
                    na vašem počítači.
                </p>
                <p>Používání souborů cookie můžete ve svém prohlížeči deaktivovat.
                </p>

                <p className="title is-6">11. Kam se můžete obrátit?</p>
                <p>11.1. Pokud si myslíte, že vaše údaje zpracováváme v rozporu s příslušnými právními předpisy,
                    kontaktujte nás na emailu punkhive@gmail.com</p>
                <p>11.2. Máte-li podezření, že s vašimi údaji nezacházíme v souladu se zákonem, máte právo se se svou
                    stížností kdykoli obrátit na Úřad pro ochranu osobních údajů. Budeme moc rádi, pokud přednostně
                    informujete nás, abychom mohli toto pochybení co nejdříve napravit k oboustranné spokojenosti.
                </p>

                <p className="mt-5">Tyto zásady zpracování osobních údajů jsou platné od 1.3.2022.</p>
            </div>
        </div>
    </div>
}
