import {ErrorMessage, useField, useFormikContext} from "formik";
import DatePicker, {registerLocale} from "react-datepicker";
import {cs} from 'date-fns/locale'

registerLocale("cs", cs)

export const DatePickerField = ({label, ...props}) => {
    const {setFieldValue} = useFormikContext();
    const [field] = useField(props);
    return (
        <div className="field">
            <label className="label">{label}</label>
            <DatePicker
                {...field}
                {...props}
                className="input"
                dateFormat="dd.MM.yyyy"
                locale="cs"
                showPopperArrow={false}
                selected={(field.value && new Date(field.value)) || null}
                onChange={val => {
                    setFieldValue(field.name, val || "");
                }}
                autoComplete="off"
            />
            <p className="has-text-danger"><ErrorMessage name={props.name}/></p>
        </div>
    );
};