function hexToRGB(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

function hexInvertBW(hex) {
    const rgb = hexToRGB(hex);
    const luminance = rgb ? (0.2126 * rgb["r"] + 0.7152 * rgb["g"] + 0.0722 * rgb["b"]) : 255;
    return (luminance < 140) ? "#ffffff" : "#000000";
}


export default function QueenBeeSvg({name, color}) {
    return <svg width="55" height="55" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <style>
            {".heavy { font: bold 10px sans-serif; }"}
        </style>
        <path
            d="M27.5595 9.04938C27.5595 11.8465 25.2972 14.1088 22.5001 14.1088C19.703 14.1088 17.4407 11.8465 17.4407 9.04938C17.4407 7.42461 19.1477 5.5736 20.5668 4.29846C21.6569 3.33183 23.3022 3.33183 24.3922 4.29846C25.8525 5.5736 27.5595 7.42461 27.5595 9.04938Z"
            fill="black"/>
        <path
            d="M33.0097 27.9296C32.6806 14.4173 12.3195 14.4173 11.9905 27.9296C11.9905 28.0736 11.9905 28.2381 11.9905 28.3821C11.9905 35.7244 16.2889 42.0384 22.5001 45C28.7112 42.0589 33.0097 35.7244 33.0097 28.3821C33.0097 28.2381 33.0097 28.0736 33.0097 27.9296Z"
            fill="#DF932F"/>
        <path
            d="M22.5 23.6723C25.6917 23.6723 28.2792 21.0849 28.2792 17.8931C28.2792 14.7013 25.6917 12.1138 22.5 12.1138C19.3082 12.1138 16.7207 14.7013 16.7207 17.8931C16.7207 21.0849 19.3082 23.6723 22.5 23.6723Z"
            fill="#482B1A"/>
        <path
            d="M29.7806 0C28.9374 0 28.2587 0.678702 28.2587 1.52194C28.2587 1.74817 28.3204 1.97441 28.4026 2.15951L24.5566 6.00548L25.2765 6.72532L29.1225 2.87934C29.3281 2.98218 29.5338 3.02331 29.76 3.02331C30.6033 3.02331 31.282 2.34461 31.282 1.50137C31.3025 0.678702 30.6238 0 29.7806 0Z"
            fill="black"/>
        <path
            d="M15.1988 0C16.0421 0 16.7208 0.678702 16.7208 1.52194C16.7208 1.74817 16.6591 1.97441 16.5768 2.15951L20.4228 6.00548L19.703 6.72532L15.857 2.89991C15.6513 3.00274 15.4456 3.04388 15.2194 3.04388C14.3762 3.04388 13.6975 2.36517 13.6975 1.52194C13.6769 0.678702 14.3762 0 15.1988 0Z"
            fill="black"/>
        <path
            d="M32.948 29.9657C32.9891 29.4515 33.0097 28.9168 33.0097 28.4026C33.0097 28.2587 33.0097 28.0941 33.0097 27.9502C32.9891 26.7367 32.7834 25.6467 32.4749 24.6595C29.5339 26.3665 26.1404 27.3537 22.5001 27.3537C18.8803 27.3537 15.4663 26.3665 12.5252 24.6595C12.1961 25.6467 12.011 26.7367 11.9905 27.9502C11.9905 28.0941 11.9905 28.2587 11.9905 28.4026C11.9905 28.9374 12.011 29.4515 12.0522 29.9657C15.2195 31.4465 18.7569 32.2692 22.5001 32.2692C26.2432 32.2692 29.7601 31.4259 32.948 29.9657Z"
            fill="#482B1A"/>
        <path
            d="M28.6701 40.2491C30.1509 38.5009 31.3232 36.4648 32.0636 34.223C29.0814 35.2925 25.8524 35.8889 22.5001 35.8889C19.1477 35.8889 15.9187 35.3131 12.9365 34.223C13.6769 36.4442 14.8492 38.4803 16.33 40.2491C18.325 40.6193 20.4022 40.8249 22.5001 40.8249C24.5979 40.8249 26.6751 40.6193 28.6701 40.2491Z"
            fill="#482B1A"/>
        <path
            d="M5.65593 15.8364C1.62485 17.0087 -0.699191 21.2249 0.452546 25.256C1.60428 29.287 5.84103 31.6111 9.87211 30.4593C12.8748 29.5955 18.5718 23.5695 21.3072 20.5256C22.0476 19.703 21.6568 18.4072 20.6079 18.1193C16.7003 17.0293 8.65867 14.9726 5.65593 15.8364Z"
            fill="#65B8EF"/>
        <path
            d="M39.3441 15.8364C43.3752 17.0087 45.6992 21.2249 44.5475 25.256C43.3958 29.287 39.159 31.6111 35.1279 30.4593C32.1252 29.5955 26.4282 23.5695 23.6928 20.5256C22.9524 19.703 23.3432 18.4072 24.3921 18.1193C28.2998 17.0293 36.3414 14.9726 39.3441 15.8364Z"
            fill="#65B8EF"/>
        <path
            d="M13.2861 1.52196C9.46072 -1.15171 4.19564 -0.205645 1.52196 3.61977C-1.15171 7.44518 -0.205645 12.7103 3.61977 15.3839C6.43741 17.3378 15.4251 18.0576 20.0114 18.3044C21.3072 18.3661 22.2121 17.0704 21.6979 15.8775C19.888 11.6614 16.0832 3.4758 13.2861 1.52196Z"
            fill="#80C6FF"/>
        <path
            d="M31.7138 1.52196C35.5393 -1.15171 40.8043 -0.205645 43.478 3.61977C46.1517 7.44518 45.2056 12.7103 41.3802 15.3839C38.5626 17.3378 29.5749 18.0576 24.9885 18.3044C23.6928 18.3661 22.7879 17.0704 23.302 15.8775C25.1119 11.6614 28.9168 3.4758 31.7138 1.52196Z"
            fill="#80C6FF"/>
        <path visibility={name ? "visible" : "hidden"}
            d="M22.5001 26.0992C27.0322 26.0992 30.7062 22.4252 30.7062 17.8931C30.7062 13.361 27.0322 9.68695 22.5001 9.68695C17.968 9.68695 14.2939 13.361 14.2939 17.8931C14.2939 22.4252 17.968 26.0992 22.5001 26.0992Z"
            fill="#ffffff"/>
        <path
            d="M22.5001 26.0992C27.0322 26.0992 30.7062 22.4252 30.7062 17.8931C30.7062 13.361 27.0322 9.68695 22.5001 9.68695C17.968 9.68695 14.2939 13.361 14.2939 17.8931C14.2939 22.4252 17.968 26.0992 22.5001 26.0992Z"
            fill={color}/>
        <text x="50%" y="48%" fill={hexInvertBW(color)} textAnchor="middle" className="heavy">{name}</text>
        <path
            d="M26.5928 0.802063L25.0914 4.91541C25.0914 4.91541 22.6851 5.63524 19.9291 4.91541L18.2427 0.802063L20.9369 2.18003L22.5206 0.802063L24.0014 1.99493L26.5928 0.802063Z"
            fill="#E9BE00"/>
    </svg>
}