import {useProfile} from "../hooks/useProfile";
import {PremiumOnlyCard} from "./PremiumOnlyCard";
import {useFetch} from "../hooks/useFetch";
import {API_URL} from "../config";
import Loader from "./Loader";
import {useEffect, useState} from "react";
import {ProgressBar} from "./ProgressBar";
import {postFormData} from "../utils/postFormData";
import {store} from "react-notifications-component";
import {CgFile} from "react-icons/cg";
import {formatDate, formatDateForApi, formatTime} from "../utils/utils";
import DatePicker from "react-datepicker";

const ExportForm = ({onComplete}) => {
    const [location, setLocation] = useState("0")
    const [includeImages, setIncludeImages] = useState(true)
    const [dateFrom, setDateFrom] = useState('')
    const [dateTo, setDateTo] = useState('')


    const {data} = useFetch(
        `${API_URL}/hive-locations/?nonempty=1`
    );

    const performExport = () => {
        const exportData = {
            location_id: location,
            include_images: includeImages,
            date_from: dateFrom,
            date_to: dateTo,
        }
        postFormData(`${API_URL}/exports/perform-export/`, exportData).then(data => {
            console.log(data);
            store.addNotification({
                title: "Export",
                message: <>Požadavek na export byl úspěšně vytvořen.</>,
                type: "info",
                insert: "top",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                slidingExit: {
                    duration: 0,
                    timingFunction: 'ease-out',
                    delay: 0
                },
                dismiss: {
                    duration: 5000,
                    pauseOnHover: true
                }
            });
            onComplete()
        }).catch(function (err) {
            //
        });
    }

    return data ? <div>
        <div>
            <label className="label">Stanoviště</label>
            <div className="field">
                <div className="select mb-3">
                    <select onChange={(e) => {
                        setLocation(e.target.value)
                    }} value={location}>
                        <option value={0}>--</option>
                        {data.map(location =>
                            <option key={location.id} value={location.id}>{location.name}</option>
                        )}
                    </select>
                </div>
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <label className="label">Od</label>
                <DatePicker
                    placeholderText="pradávna"
                    className="input"
                    dateFormat="dd.MM.yyyy"
                    locale="cs"
                    showPopperArrow={false}
                    selected={dateFrom || ''}
                    onChange={val => {
                        setDateFrom(val);
                    }}
                    autoComplete="off"
                />
            </div>
            <div className="column">
                <label className="label">Do</label>
                <DatePicker
                    placeholderText="dneška"
                    className="input"
                    dateFormat="dd.MM.yyyy"
                    locale="cs"
                    showPopperArrow={false}
                    selected={dateTo || ''}
                    onChange={val => {
                        setDateTo(val);
                    }}
                    autoComplete="off"
                />
            </div>
        </div>
        <div>
            <label className="checkbox pb-5">
                <input type="checkbox" checked={includeImages} onChange={() => {
                    setIncludeImages(!includeImages)
                }}/> včetně obrázků
            </label>
        </div>
        <div>
            <button type="submit" onClick={performExport} disabled={location === "0"} className="button">Exportovat
            </button>
        </div>
    </div> : <></>
}

export const ExportsContent = () => {
    const {isPremium, isProfileDataReady} = useProfile()
    const [completed, setCompleted] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const {data} = useFetch(
        `${API_URL}/exports/?k=${completed}&s=${submitted}`
    );

    if (isProfileDataReady && !isPremium) {
        return <PremiumOnlyCard/>
    }

    const getCompletedExports = () => {
        return data.filter((item) => item.status === 3)
    }

    const isSomeRunning = () => {
        return data.some((item) => item.status === 1 || item.status === 2)
    }

    const onComplete = () => {
        setCompleted(true);
    }

    const onFormSubmit = () => {
        setCompleted(false);
        setSubmitted(true);
    }

    if (isPremium && data) {
        return <>
            <h2 className="title is-4">Export stanoviště do PDF</h2>
            {(isSomeRunning() && !completed) ? <RunningExport onComplete={onComplete}/> :
                <ExportForm onComplete={onFormSubmit}/>}
            <hr/>
            <h3 className="title is-5">Proběhlé exporty</h3>
            {getCompletedExports().length > 0 ?
                <table className="table is-fullwidth is-striped">
                    <thead className="has-text-weight-bold">
                    <tr>
                        <td>Datum</td>
                        <td>Čas</td>
                        <td>Stanoviště</td>
                        <td>S&nbsp;obrázky</td>
                        <td>Odkaz</td>
                    </tr>
                    </thead>
                    <tbody>
                    {getCompletedExports().map(exportItem =>
                        <tr key={exportItem.id}>
                            <td>{(exportItem.from_date && exportItem.to_date) ?
                                <>{formatDate(exportItem.from_date)} - {formatDate(exportItem.to_date)}</> : <>--</>}</td>
                            <td>{formatTime(exportItem.created)}</td>
                            <td>{exportItem.location_name}</td>
                            <td>{exportItem.include_images ? "Ano" : "Ne"}</td>
                            <td><a href={exportItem.url} target="_blank"
                                   download={`${exportItem.location_name}-${formatDateForApi(exportItem.created)}`}><CgFile/> stáhnout</a>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
                : <i>Zatím neproběhly žádné exporty.</i>}
        </>
    }
    return <Loader/>
}


const RunningExport = ({onComplete}) => {

    const [time, setTime] = useState(Date.now());

    const {data, loading} = useFetch(
        `${API_URL}/exports/?time=${time}`
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(Date.now())
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    const status = (value) => {
        const statuses = {
            1: "čeká ve frontě",
            2: "probíhá",
            3: "je hotov",
            4: "se nezdařil"
        }
        return statuses[value]
    }

    const getRunningExport = () => {
        if (data) {
            const running = data.filter((item) => item.status === 1 || item.status === 2)
            if (running.length > 0) {
                return running[0]
            } else {
                onComplete()
            }
        }
    }

    return (data && getRunningExport()) ?
        <>Export {status(getRunningExport().status)}
            <div className="columns">
                <div className="column">
                    <ProgressBar value={getRunningExport().status === 1 ? null : getRunningExport().progress}/></div>
                <div className="column is-1">{getRunningExport().progress} %</div>
            </div>
        </>
        :
        (loading ? <Loader/> : <></>)

}
