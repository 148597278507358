import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useFetch} from "../hooks/useFetch";
import {API_URL} from "../config";
import Breadcrumb, {BreadcrumbItem} from "../components/Breadcrumb";
import {AiOutlinePlusSquare} from "react-icons/ai";
import {FaFlag, FaHome} from "react-icons/fa";
import {VisitForm} from "../components/VisitForm";

export default function NewVisit() {
    useEffect(() => {
        document.title = "Chytrý včelař | Nový záznam"
    }, []);

    let params = useParams();

    const {data} = useFetch(
        `${API_URL}/hive-locations/${params.hiveLocationSlug}/`
    );

    const currentDate = new Date();

    return data ? (
        <>
            <Breadcrumb>
                <BreadcrumbItem title="Stanoviště" to="/muj-denik/"><FaHome/></BreadcrumbItem>
                <BreadcrumbItem title={data.name} to={`/stanoviste/${data.slug}`}><FaFlag/></BreadcrumbItem>
                <BreadcrumbItem title="Nový záznam" to="#"><AiOutlinePlusSquare/></BreadcrumbItem>
            </Breadcrumb>
            <div className="container is-fluid mt-4">
                <VisitForm initialValues={{
                    text: "",
                    created: currentDate,
                    created_time: currentDate.toLocaleString('cs-CZ', {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false
                    })
                }}
                           locationId={data.id}
                           locationSlug={data.slug}
                           locationName={data.name}/>
            </div>
        </>) : "";
}

