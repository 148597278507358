import {useFetch} from "../hooks/useFetch";
import {API_URL} from "../config";
import Gallery from "./Gallery";
import Loader from "./Loader";

export const HiveGallery = ({hive}) => {
    const {data, loading, error} = useFetch(
        `${API_URL}/hives/${hive}/images/`
    );

    return <>
        {data && data.count > 0 ?
            <>
                <hr/>
                <p>Galerie prohlídek ({data.count})</p>
                <div><Gallery thumbSize="32x32" data={data.results}/></div>
            </>
            : loading ? <Loader/> : ""}
    </>
}