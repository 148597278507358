export default function PrivacyPolicyEn() {
    return <div className="section pt-3">
        <div className="container">
            <div className="box mt-5 content">
                <p className="title is-5">Personal Data Processing Principles</p>
                <p className="title is-6">1. Introduction</p>
                <p>1.1. We process personal data collected during your visit to our website and mobile application in
                    accordance with the Act No. 101/2000 Coll., on the Protection of Personal Data, and the General Data
                    Protection Regulation (GDPR).</p>
                <p>1.2. This document pertains to users of the mobile applications and/or websites of Chytrý včelař
                    ("Application").</p>
                <p className="title is-6">2. Who collects your personal data?</p>
                <p>2.1. Your personal data is collected by PunkHive s.r.o, Company ID No.: 21152195, with its
                    registered office at Kaštanová 1055/14, Olomouc 779&nbsp;00, who operates the Chytrý včelař mobile
                    application and website. This entity is authorized to provide and sell a License (the right to use
                    the Application).</p>
                <p>2.2. We process your personal data as a controller, that is, we determine how and for what purpose,
                    and for how long personal data will be processed.</p>
                <p>2.3. During the data processing, you can contact us by email at punkhive@gmail.com.</p>
                <p className="title is-6">3. What data do we collect?</p>
                <p>3.1. We collect the following personal data that you provide within the Application: name, email,
                    address, your activity within the Application. The data is collected for the purpose of providing
                    the service.</p>
                <p>3.2. The following minimum data is required to create your account: name and email.</p>
                <p>3.3. We also collect the following personal data for marketing purposes: name and email. We will
                    collect this data based on your consent. You can withdraw your consent at any time.</p>
                <p className="title is-6">4. What is the legal basis for data processing?</p>
                <p>4.1. We will process the data specified in point 3.1. and point 6.1. for the purpose of providing you
                    with the service (the right to access and use the Application).</p>
                <p>4.2. We will process the data specified in point 3.3. for marketing purposes. Based on your consent,
                    we may occasionally send you marketing emails to promote our service.</p>
                <p className="title is-6">5. Will data be shared with third parties?</p>
                <p>5.1. We will not share your data with third parties unless: we have a legal obligation to do so, we
                    have your consent, it is necessary to share information for investigation, prevention, or taking
                    action related to illegal activities, suspicion of fraud, situations involving potential physical
                    harm to any person, violation of the terms of service, or as required by law. We may also share your
                    data with our service providers.</p>

                <p className="title is-6">6. How long will the data be stored?</p>
                <p>6.1. We will store the data listed in point 3.1. as long as we provide you with services in accordance with the terms, especially if you have an account on the platform. To avoid any doubts, you have the right to delete all data associated with your account, except for your name and email (as we need these to keep your user account active).</p>
                <p className="title is-6">7. Where do we store data?</p>
                <p>7.1. We process data exclusively within the European Union or in countries that provide an adequate level of protection based on the decision of the European Commission.</p>
                <p className="title is-6">8. What security measures do we have?</p>
                <p>8.1. To protect your data, we have implemented security measures in accordance with applicable laws and best practices. We protect your data from potential security incidents, but we cannot guarantee that such incidents will not occur.</p>
                <p className="title is-6">9. What rights do you have in relation to the protection of personal data?</p>
                <p>9.1. You have several rights in relation to the protection of personal data. If you wish to exercise any of these rights or make a complaint, please contact us via email punkhive@gmail.com.</p>
                <p>9.2. Under the GDPR regulation, you have the right to:</p>
                <ul>
                    <li>Information that we have fulfilled with these Principles of Personal Data Processing</li>
                    <li>Access to your personal data</li>
                    <li>Request for correction of incorrect, inaccurate, or incomplete personal data</li>
                    <li>Request for erasure of personal data if they are no longer needed or if their processing is
                        unlawful
                    </li>
                    <li>Object to the processing of your personal data for marketing purposes</li>
                    <li>Erasure (right to be forgotten) - if you no longer want us to process your personal data, we
                        will delete all your personal data upon your request, if they are not bound by other laws (e.g.
                        tax record keeping)
                    </li>
                </ul>
                <p>9.3. In certain cases, we may ask you to provide information to confirm your identity. This may
                    include clicking on a verification link, entering a username or password.</p>
                <p className="title is-6">10. Cookies</p>
                <p>When you visit our website, we record your IP address assigned by your provider, the web page from
                    which you visit us, the individual pages of our website that you visit, and the date and length of
                    your visit in a standard manner. Saving cookies in your browser is to facilitate your orientation
                    and movement on our website during repeated visits. Only the cookie on your computer is
                    identified.</p>
                <p>You can deactivate the use of cookies in your browser.</p>
                <p className="title is-6">11. Where can you turn?</p>
                <p>11.1. If you believe that we process your data in violation of applicable laws, please contact us at
                    punkhive@gmail.com.</p>
                <p>11.2. If you suspect that we are not handling your data in accordance with the law, you have the
                    right to submit your complaint to the Office for Personal Data Protection at any time. We would be
                    happy if you informed us first so that we can correct this mistake as soon as possible to mutual
                    satisfaction.</p>
                <p className="mt-5">These Principles of Personal Data Processing are valid from March 1, 2022.</p>
            </div>
        </div>
    </div>
}
