import {useFetch} from "../hooks/useFetch";
import {API_URL} from "../config";
import {FaChevronRight} from "react-icons/fa";
import {BiHive} from "react-icons/bi";
import {HiPlus} from "react-icons/hi";
import {Link} from "react-router-dom";
import Loader from "./Loader";
import {useProfile} from "../hooks/useProfile";

export const HiveFigure = ({hive, tiny=false}) => {
    return <figure
        className={`image ${tiny ? "is-16x16 is-inline-block vatt" : "is-48x48"} has-rounded-corners has-shadow ${hive.color ? "" : "has-stripes"}`}>
        {hive.thumbnail_url && !tiny ?
            <img className="image is-48x48 has-rounded-corners has-shadow"
                 src={hive.thumbnail_url} alt={hive.name}/>
            :
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 220 220">
                <defs>
                    <style>
                        {".cls-1 { fill: #a8a8a8; }"}
                        {".cls-2 { fill: #d1d1d1; }"}
                        {".cls-4 { fill: #c4c4c4; }"}
                        {".cls-5 { fill: #6b6b6b; }"}
                        {`.color-${hive.id} { fill: ${hive.color ? hive.color : "#999"} }`}

                    </style>
                </defs>
                <g id="Vrstva_2" data-name="Vrstva 2">
                    <g id="Vrstva_1-2" data-name="Vrstva 1">
                        <polygon className="cls-1"
                                 points="186.96 145.1 33.04 145.1 0 204.51 0 220 220 220 220 204.51 186.96 145.1"/>
                        <path className="cls-2"
                              d="M110,0h0A82.87,82.87,0,0,0,27.13,82.87V177c0,12.48,37.1,22.59,82.87,22.59s82.87-10.11,82.87-22.59V82.87A82.87,82.87,0,0,0,110,0Z"/>
                        <rect className="cls-1" y="204.51" width="220"
                              height="15.49"/>
                        <rect className={`color-${hive.id}`} y="204.51"
                              width="220"
                              height="15.49"/>
                        <path className="cls-4"
                              d="M110,149.05c-45.77,0-82.87-10.12-82.87-22.6h0v25.3c0,12.47,37.1,22.59,82.87,22.59s82.87-10.12,82.87-22.59v-25.3C192.87,138.93,155.77,149.05,110,149.05Z"/>
                        <path className="cls-4"
                              d="M110,98.47c-45.77,0-82.87-10.12-82.87-22.6h0v25.29c0,12.48,37.1,22.6,82.87,22.6s82.87-10.12,82.87-22.6V75.87C192.87,88.35,155.77,98.47,110,98.47Z"/>
                        <path className="cls-4"
                              d="M40.58,37.63A82.57,82.57,0,0,0,31.1,57.5c10.72,9.09,42,15.68,78.9,15.68s68.18-6.59,78.9-15.68a82.57,82.57,0,0,0-9.48-19.87C164.63,43.8,139.07,47.89,110,47.89S55.37,43.8,40.58,37.63Z"/>
                        <path className="cls-4"
                              d="M110,22.6c19.35,0,37.13-1.81,51.23-4.84A82.48,82.48,0,0,0,110,0h0A82.48,82.48,0,0,0,58.77,17.76C72.87,20.79,90.66,22.6,110,22.6Z"/>
                        <path className="cls-5"
                              d="M110,199.63c7.67,0,15.08-.29,22.13-.82v-2.28A22.12,22.12,0,0,0,110,174.41h0a22.12,22.12,0,0,0-22.13,22.12v2.28C94.92,199.34,102.33,199.63,110,199.63Z"/>
                    </g>
                </g>
            </svg>
        }
    </figure>
}

function Hive({hive}) {
    return (<Link to={`/ul/${hive.slug}`}>
        <div className="card mt-5 hive-card" style={{borderColor: hive.color ? hive.color : "#999"}}>
            <div className="card-content">
                <div className="media">
                    <div className="media-left">
                        <HiveFigure hive={hive}/>
                    </div>
                    <div className="media-content">
                        <p className="title is-6">{hive.name}</p>
                        <p className="subtitle is-6">
                            Nástavků: {hive.box_count}
                        </p>
                    </div>
                    <div style={{marginTop: "auto"}}>
                        <FaChevronRight/>
                    </div>
                </div>
            </div>
        </div>
    </Link>)
}

export function HivesList({locationSlug}) {
    const {data, loading, error} = useFetch(
        `${API_URL}/hives/?location=${locationSlug}`
    );
    const {isPremium} = useProfile()

    return <div className="panel">
        <p className="panel-heading is-size-6">
            <BiHive className="mr-2"/>Úly na stanovišti <>{data ? `(${data.length})` : ""}</>
        </p>
        <div className="pl-5 pr-5 pb-5">
            {data ?
                data.map(hive => <Hive key={hive.id} hive={hive}/>)
                : loading ? <Loader className="mt-4"/> : ""}
        </div>
        {data && isPremium ?
        <div className="panel-block pb-5 pr-5 is-justify-content-flex-end">
            <Link to={`/stanoviste/${locationSlug}/novy-ul/`} className="button"><HiPlus className="mr-3"/>Přidat
                úl</Link>
        </div> : "" }
    </div>
}
