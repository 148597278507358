import {useHistory} from "react-router-dom";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {DatePickerField} from "./DatePickerField";
import {ImagesField} from "./ImageField";
import {TextAreaField} from "./TextAreaField";

import {TimeField} from "./TimeField";
import {useEffect, useState} from "react";
import {TagFieldNumber, TagFieldOptions, TagsRow} from "./TagFieldOptions";
import {postFormData} from "../utils/postFormData";
import {API_URL} from "../config";
import {useFetch} from "../hooks/useFetch";
import {BiHive} from "react-icons/bi";
import Loader from "./Loader";
import {chunk} from "../utils/utils";
import EntryForm from "./EntryForm";
import {putFormData} from "../utils/putFormData";
import {deleteFormData} from "../utils/deleteFormData";
import {store} from "react-notifications-component";
import {ProgressBar} from "./ProgressBar";

export const VisitForm = ({
                              initialValues,
                              locationId,
                              locationName,
                              locationSlug,
                              id,
                              initialTags = [],
                              initialImages = []
                          }) => {
    let history = useHistory();

    const [tags, setTags] = useState(initialTags);
    const [noteImages, setNoteImages] = useState([]);
    const [noteImagesToRemove, setNoteImagesToRemove] = useState([]);
    const [entryHives, setEntryHives] = useState({});
    const [readyHives, setReadyHives] = useState([]);

    const onEntryHivesValuesChanged = (values) => {
        setEntryHives(values);
    }

    const onReadyEntryHivesChanged = (hives) => {
        setReadyHives(hives);
    }

    const onIncludeTags = (toInclude) => {
        const tagsToInclude = toInclude.map(tag => [tag, null]);
        setTags(oldTags => [...oldTags, ...tagsToInclude]);
    };

    const onExcludeTags = (toExclude) => {
        setTags(oldTags => oldTags.filter(x => !toExclude.includes(x[0])));
    };

    const onSetNumberTag = (tag, value) => {
        if (value != null) {
            setTags(oldTags => [...oldTags.filter(x => tag !== x[0]), [tag, parseFloat(value)]]);
        } else {
            setTags(oldTags => [...oldTags.filter(x => tag !== x[0])]);
        }
    };

    const endEditing = (created = false) => {
        store.addNotification({
            title: created ? "Záznam vytvořen" : "Záznam upraven",
            message: created ? <>Záznam ze stanoviště <b>{locationName}</b> byl úspěšně vytvořen.</> : <>Poznámka ke
                stanovišti byla úspěšně upravena.</>,
            type: "info",
            insert: "top",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            slidingExit: {
                duration: 0,
                timingFunction: 'ease-out',
                delay: 0
            },
            dismiss: {
                duration: 5000,
                pauseOnHover: true
            }
        });
        history.replace({pathname: `/stanoviste/${locationSlug}/`})
    };

    const isNoteEmpty = (values) => {
        return !values.text && tags.length === 0 && (noteImages.length - noteImagesToRemove.length + initialImages.length) === 0
    }

    const saveHives = (visitDate, locationName, imagesToUpload, totalImages, setStatus, setSubmitting) => {
        // hives
        Object.keys(entryHives).forEach(hiveId => {
            const entry = entryHives[hiveId];
            if (entry.text || entry.tags.length > 0 || entry.images.length > 0) {

                if (entry.created_time) {
                    const [hours, minutes] = entry.created_time.split(":");
                    visitDate.setHours(hours);
                    visitDate.setMinutes(minutes);
                }

                let entryData = {
                    hive: hiveId,
                    text: entry.text || "",
                    tags: JSON.stringify(entry.tags),
                    created: visitDate
                }
                postFormData(`${API_URL}/entries/`, entryData).then(data => {
                    let entryId = data.id
                    entry.images.forEach((image, order) => {
                        const imageData = {
                            entry: entryId,
                            image: image,
                            order: order
                        }
                        postFormData(`${API_URL}/entries-images/`, imageData).then(data => {
                            imagesToUpload -= 1;
                            setStatus(Math.round(100 - (imagesToUpload * (100 / totalImages))))
                            if (imagesToUpload === 0) {
                                setSubmitting(false);
                                endEditing(true);
                            }
                        }).catch(function (err) {
                            imagesToUpload -= 1;
                            setStatus(Math.round(100 - (imagesToUpload * (100 / totalImages))))
                            if (imagesToUpload === 0) {
                                setSubmitting(false);
                                endEditing(true);
                            }
                        });
                    });

                }).catch(function (err) {
                });
            }
        });
        if (imagesToUpload === 0) {
            setStatus(100)
            setSubmitting(false);
            endEditing(true);
        }
    }

    return <>
        <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
                // TODO: validace
                // name: Yup.string()
                //     .max(50, 'Název úlu nesmí obsahovat více než 50 znaků')
                //     .required('Povinný údaj')
            })}
            onSubmit={(values, {setSubmitting, setStatus, setFieldError}) => {
                // date
                if (values.created_time) {
                    const [hours, minutes] = values.created_time.split(":");
                    values.created.setHours(hours);
                    values.created.setMinutes(minutes);
                }
                values.created_time = undefined;
                // create copy
                let visitDate = new Date(values.created.getTime());

                // tags
                values.tags = JSON.stringify(tags)

                if (id) {
                    let totalImages = noteImages.length
                    let imagesToUpload = noteImages.length
                    setStatus(0)
                    putFormData(`${API_URL}/notes/${id}/`, values).then(noteData => {
                        // images
                        if (imagesToUpload === 0) {
                            setStatus(100)
                            setSubmitting(false);
                            endEditing();
                        }
                        noteImages.forEach(image => {
                            const imageData = {
                                location_visit_note: id,
                                image: image
                            }
                            postFormData(`${API_URL}/hive-locations/${locationId}/notes/${id}/images/`, imageData).then(data => {
                                imagesToUpload -= 1;
                                setStatus(Math.round(100 - (imagesToUpload * (100 / totalImages))))
                                if (imagesToUpload === 0) {
                                    setSubmitting(false);
                                    endEditing();
                                }
                            }).catch(function (err) {
                                imagesToUpload -= 1;
                                setStatus(Math.round(100 - (imagesToUpload * (100 / totalImages))))
                                if (imagesToUpload === 0) {
                                    setSubmitting(false);
                                    endEditing();
                                }
                            });
                        });
                        noteImagesToRemove.forEach(image => {
                            deleteFormData(`${API_URL}/hive-locations/${locationId}/notes/${id}/images/${image.id}/`).then(data => {
                            }).catch(function (err) {
                            });
                        })

                    }).catch(function (err) {
                        for (const key of Object.keys(err)) {
                            setFieldError(key, err[key]);
                        }
                        setSubmitting(false);
                    });

                } else {
                    let hiveImages = Object.keys(entryHives).reduce(
                        (prev, key) => prev + entryHives[key].images.length,
                        0);
                    let totalImages = noteImages.length + hiveImages
                    let imagesToUpload = noteImages.length + hiveImages
                    setStatus(0)

                    if (isNoteEmpty(values)) {
                        saveHives(visitDate, "", imagesToUpload, totalImages, setStatus, setSubmitting)
                    } else {
                        postFormData(`${API_URL}/hive-locations/${locationId}/notes/`, values).then(noteData => {

                            const noteId = noteData.id;

                            // images
                            noteImages.forEach(image => {
                                const imageData = {
                                    location_visit_note: noteId,
                                    image: image
                                }
                                postFormData(`${API_URL}/hive-locations/${locationId}/notes/${noteId}/images/`, imageData).then(data => {
                                    imagesToUpload -= 1;
                                    setStatus(Math.round(100 - (imagesToUpload * (100 / totalImages))))
                                    if (imagesToUpload === 0) {
                                        setSubmitting(false);
                                        endEditing(true);
                                    }
                                }).catch(function (err) {
                                    imagesToUpload -= 1;
                                    setStatus(Math.round(100 - (imagesToUpload * (100 / totalImages))))
                                    if (imagesToUpload === 0) {
                                        setSubmitting(false);
                                        endEditing(true);
                                    }
                                });
                            });

                            saveHives(visitDate, noteData.location_name, hiveImages, totalImages, setStatus, setSubmitting)
                        }).catch(function (err) {
                            for (const key of Object.keys(err)) {
                                setFieldError(key, err[key]);
                            }
                            setSubmitting(false);
                        });
                    }
                }
            }}
        >
            {props => (
                <Form>
                    {props.isSubmitting && <ProgressBar value={props.status}/>}
                    <div className="columns">
                        <div className="column">
                            <h1 className="title is-4">{id ? "Úprava záznamu" : "Nový záznam"}</h1>
                        </div>
                        <div className="column has-text-right">
                            {props.isSubmitting ?
                                <button type="submit" className="button"
                                        disabled>Ukládám ({props.status}%) ...
                                </button>
                                :
                                <button type="submit" className="button"
                                        disabled={!(props.isValid && !isNoteEmpty(props.values) || readyHives.length > 0)}>Uložit</button>}
                        </div>
                    </div>
                    <hr className="mt-0"/>
                    <div className="columns">
                        <div className="column">
                            <div className="columns">
                                <div className="column">
                                    <DatePickerField disabled={props.isSubmitting} name="created"
                                                     label="Den návštěvy"/>
                                </div>
                                <div className="column">
                                    <TimeField disabled={props.isSubmitting} name="created_time" label="Čas"/>
                                </div>
                            </div>

                            <label className="label">Počasí</label>
                            <div className="card">
                                <TagsRow>
                                    <TagFieldNumber tag={6} step={0.1} onSet={onSetNumberTag} defaultValue="10"
                                                    currentValue={tags}
                                                    minValue={-20} maxValue={50}/>
                                </TagsRow>
                                <TagsRow>
                                    <TagFieldOptions multiple={false} onInclude={onIncludeTags}
                                                     onExclude={onExcludeTags}
                                                     currentValue={tags}
                                                     tags={[1, 2, 3]}/>
                                </TagsRow>
                                <TagsRow>
                                    <TagFieldOptions multiple={false} onInclude={onIncludeTags}
                                                     onExclude={onExcludeTags}
                                                     currentValue={tags}
                                                     tags={[5, 4]}/>
                                </TagsRow>
                            </div>

                            <label className="label mt-5">Žihadla</label>
                            <div className="card">
                                <TagsRow>
                                    <TagFieldNumber tag={66} onSet={onSetNumberTag} defaultValue="0" minValue={0}
                                                    currentValue={tags}
                                                    maxValue={400}/>
                                </TagsRow>
                            </div>
                        </div>
                        <div className="column">
                            <ImagesField extraStyle={{minHeight: "13rem"}} label="Fotky stanoviště"
                                         initial={initialImages} name="image"
                                         onChange={images => setNoteImages(images)}
                                         onRemove={images => setNoteImagesToRemove(images)}
                            />
                            <TextAreaField name="text"
                                           disabled={props.isSubmitting} label="Poznámka ke stanovišti"
                            />
                        </div>
                    </div>
                </Form>)}
        </Formik>

        {id ? <></> :
            <EntryHives locationId={locationId}
                        onValuesChanged={onEntryHivesValuesChanged}
                        onReadyHivesChanged={onReadyEntryHivesChanged}
            />
        }
    </>
}

export function EntryHives({locationId, onValuesChanged, onReadyHivesChanged}) {
    const {data, loading, error} = useFetch(
        `${API_URL}/hives/?location=${locationId}`
    );

    const currentDate = new Date();

    const [activeKey, setActiveKey] = useState()
    const [values, setValues] = useState({})
    const [dirtyHives, setDirtyHives] = useState([])
    const [readyHives, setReadyHives] = useState([])

    const toggleActive = (key, activeKey) => {
        return () => {
            if (activeKey === key) {
                setActiveKey(null);
            } else {
                setActiveKey(key);
            }
        }
    }

    const changeHiveValues = (hiveId) => {
        return (values) => {
            setValues(oldValues => ({...oldValues, ...{[hiveId]: values}}))
        }
    }

    const setDirty = (hiveId) => {
        return (isDirty) => {
            const toInclude = isDirty ? [hiveId] : [];
            setDirtyHives(oldValues => [...oldValues.filter(e => e !== hiveId), ...toInclude]);
        }
    }

    const setReady = (hiveId) => {
        return (isReady) => {
            const toInclude = isReady ? [hiveId] : [];
            setReadyHives(oldValues => [...oldValues.filter(e => e !== hiveId), ...toInclude]);
        }
    }

    useEffect(() => {
        onValuesChanged(values);
    }, [values])

    useEffect(() => {
        onReadyHivesChanged(readyHives)
    }, [readyHives])

    return (data && data.length > 0) ?
        <>
            <hr/>
            <label className="label"> <BiHive className="mr-2"/>Úly na stanovišti</label>
            {chunk(data, 1).map(hives =>
                <div className="columns" key={hives[0].id}>
                    {hives.map((hive, i) =>
                        <div className="column is-12" key={hive.id}>
                            <EntryForm
                                initialValues={{
                                    created_time: currentDate.toLocaleString('cs-CZ', {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: false
                                    })
                                }}
                                onDirtyChanged={setDirty(hive.id)}
                                onReadyChanged={setReady(hive.id)}
                                onValuesChanged={changeHiveValues(hive.id)}
                                onClick={toggleActive(hive.id, activeKey)}
                                active={hive.id === activeKey}
                                key={hive.id} hive={hive}/>
                        </div>
                    )}
                </div>
            )}</> : loading ? <Loader className="mt-4"/> : ""
}
