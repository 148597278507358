import {useEffect} from "react";
import {RegistrationForm} from "../components/RegistrationForm";

export default function Registration() {

    useEffect(() => {
        document.title = "Chytrý včelař | Registrace"
    }, []);

    return <div className="container is-fluid mt-4">
        <div className="section">
            <div className="columns is-centered">
                <div className="column is-6-tablet is-5-desktop is-4-widescreen">
                    <div className="box">
                        <RegistrationForm />
                    </div>
                </div>
            </div>
        </div>
    </div>
}
