import {useParams} from "react-router-dom";
import {useEffect} from "react";
import Breadcrumb, {BreadcrumbItem} from "../components/Breadcrumb";
import {FaEdit, FaFlag, FaHome} from "react-icons/fa";
import {useFetch} from "../hooks/useFetch";
import {API_URL} from "../config";
import EntryForm from "../components/EntryForm";
import {formatDate, formatTime} from "../utils/utils";
import {BiHive} from "react-icons/bi";

export default function EditEntry({fromHive = false}) {
    let params = useParams();
    useEffect(() => {
        document.title = "Chytrý včelař | Upravit prohlídku úlu"
    }, []);

    const {data} = useFetch(
        `${API_URL}/entries/${params.entryId}/`
    );

    return <>
        <Breadcrumb>
            <BreadcrumbItem title="Stanoviště" to="/muj-denik/"><FaHome/></BreadcrumbItem>

            {data &&
            <>
                <BreadcrumbItem title={data.location_name}
                                to={`/stanoviste/${data.location_slug}`}><FaFlag/></BreadcrumbItem>

                {fromHive ?
                    <>
                        <BreadcrumbItem title={data.hive_name} to={`/ul/${params.hiveSlug}`}><BiHive/></BreadcrumbItem>
                        <BreadcrumbItem title={`Úprava prohlídky`} to="#"><FaEdit/></BreadcrumbItem>
                    </>
                    :
                    <BreadcrumbItem title={`Úprava prohlídky úlu ${data.hive_name}`} to="#"><FaEdit/></BreadcrumbItem>
                }
            </>
            }
        </Breadcrumb>
        <div className="container is-fluid mt-4">
            {data ?
                <>
                    <EntryForm
                        isInline={false}
                        initialValues={{
                            created: new Date(data.created),
                            created_time: formatTime(data.created),
                            text: data.text || ""
                        }}
                        initialImages={data.images}
                        initialTags={data.tags}
                        onDirtyChanged={(() => {
                        })}
                        /*{setDirty(hive.id)}*/
                        onReadyChanged={(() => {
                        })}
                        /*{setReady(hive.id)}*/
                        onValuesChanged={(() => {
                        })}
                        /*{changeHiveValues(hive.id)}*/
                        onClick={(() => {
                        })}
                        /*{toggleActive(hive.id, activeKey)}*/
                        active={true}
                        locationSlug={data.location_slug}
                        hive={{}}
                        hiveId={data.hive}
                        id={data.id}
                        returnUrl={fromHive ? `/ul/${params.hiveSlug}/` : null}/>
                </> : <></>}
        </div>
    </>
}
