import {Link} from "react-router-dom";

export default function Terms() {
    return <div className="section pt-3">
        <div className="container">
            <div className="box mt-5 content">
                <p className="title is-5">Všeobecné obchodní podmínky</p>


                <p className="title is-6">1. Definice</p>
                <p>V těchto obchodních podmínkách:</p>
                <p>1.1. "Provozovatel" je PunkHive s.r.o., IČ: 21152195, se sídlem Kaštanová 1055/14, Olomouc
                    779&nbsp;00.</p>
                <p>1.2. "Aplikace" znamená mobilní a webovou aplikaci s názvem Chytrý včelař, jejíž hlavní funkcí je
                    vytváření a správa úlových zápisníku, která je dostupná z internetové adresy www.chytry-vcelar.cz.
                </p>
                <p>1.3. "VOP" znamená tyto Všeobecné obchodní podmínky.</p>
                <p>1.4. "Smlouva" znamená smlouvu uzavřenou mezi Uživatelem a provozovatelem, jejímž předmětem je
                    poskytnutí Licence k užívání Aplikace Uživateli za podmínek uvedených v těchto VOP.</p>
                <p>1.5. "Licence" znamená nevýhradní licenci k užívání Aplikace za podmínek uvedených v těchto VOP.</p>
                <p>1.6. "Uživatel" znamená jakoukoliv právnickou nebo fyzickou osobu odlišnou od Provozovatele, která
                    užívá Aplikaci.</p>
                <p>1.7. "Uživatelský účet" znamená neveřejnou část Aplikace, která je uživateli přístupná po zadání
                    Přihlašovacích údajů.</p>
                <p>1.8. "Přihlašovací údaje" znamenají unikátní kombinaci přihlašovacího jména Uživatele a hesla
                    zvoleného Uživatelem, které Uživatel uloží do databáze Aplikace při zřizování Uživatelského účtu v
                    Aplikaci.</p>


                <p className="title is-6">2. Proces uzavření smlouvy o užívání Aplikace</p>
                <p>2.1. Zobrazení tlačítka s názvem "Koupit Premium" v uživatelském prostředí Aplikace je návrhem na
                    uzavření Smlouvy ze strany Provozovatele.</p>
                <p>2.2. Kliknutí Uživatele na tlačítko "Koupit Premium" je bezvýhradným přijetím návrhu na uzavření
                    Smlouvy ze strany Uživatele a tímto úkonem je uzavřena Smlouva.</p>
                <p>2.3. Kliknutím na pole (tzv. checkbox), které obsahuje popis ve znění "Souhlasím s podmínkami služby
                    a zpracováním osobních údajů" a následným kliknutím na tlačítko ve smyslu 2.2 Uživatel vyjadřuje
                    souhlas s těmito VOP.</p>
                <p>2.4. Uzavřením Smlouvy vstupují v účinnost následující ustanovení těchto VOP.</p>


                <p className="title is-6">3. Užívání Aplikace</p>
                <p>3.1. Provozovatel uděluje uživateli Licenci v rozsahu a za podmínek uvedených v uživatelském
                    prostředí Aplikace, zejména uvedených u daného typu Uživatelského účtu.</p>
                <p>3.2. Uživatel se za udělení Licence zavazuje Provozovateli zaplatit odměnu ve výši uvedené v
                    uživatelském prostředí Aplikace. Není-li u konkrétního způsobu použití (zejména u daného typu
                    Uživatelského účtu) odměna uvedena, platí, že je Licence poskytnuta bezúplatně.</p>
                <p>3.3. V případě, je licence udělena za odměnu, má Provozovatel právo poskytnout plnění Smlouvy (např.
                    zpřístupnit danou část nebo funkcionalitu Aplikace) až v momentě, kdy dojde k zaplacení odměny za
                    poskytnutí Licence a nezaplatí-li Uživatel za Licenci odměnu v plné výši v Provozovatelem stanovené
                    lhůtě, jinak ihned, ruší se Smlouva bez dalšího od počátku.</p>
                <p>3.4. Provozovatel je po uplynutí doby Licence oprávněn plně a/nebo částečně znepřístupnit Uživatelský
                    účet Uživateli.</p>
                <p>3.5. Uživatel má právo zřídit více než jeden Uživatelský účet.</p>
                <p>3.6. Uživatel nemá právo udělit třetí osobě podlicenci k užívání Aplikace.</p>
                <p>3.7. Veškerá finanční plnění realizovaná prostřednictví Aplikace nebo platební brány propojené s
                    Aplikací, se považují za zaplacené připsáním celé příslušné částky na příslušný bankovní účet
                    Provozovatele.</p>
                <p>3.8. Provozovatel má právo Aplikaci nebo její část kdykoliv odstavit, změnit a/nebo jej znepřístupnit
                    z příslušné internetové adresy, a to z jakéhokoliv důvodu.</p>
                <p>3.9. Uživatel má právo na užívání Aplikace za podmínek Smlouvy v aktuální verzi dostupné z příslušné
                    internetové adresy.</p>
                <p>3.10. Uživatel se zavazuje při užívání Aplikace jednat tak, aby jemu ani Provozovateli užíváním
                    Aplikace nevznikla žádná škoda.</p>


                <p className="title is-6">4. Databáze</p>
                <p>4.1. Uživatel nemá právo prostřednictvím Aplikace strojově (zejm. pomocí tzv. softwarových robotů)
                    vytěžovat databázi spojenou s Aplikací.</p>
                <p>4.2. Uživatel a Provozovatel tímto sjednávají, že jakékoliv údaje vložné Uživatelem do databáze
                    Aplikace jsou části databáze shromážděné Provozovatelem a stávají se součástí databáze
                    Provozovatele, aniž by Uživateli jeho jednáním popsaným v tomto odstavci vzniklo jakékoliv právo k
                    databázi Aplikace.</p>
                <p>4.3. Smluvní strany jsou si vědomy, shodně prohlašují a činí nesporným, že Aplikace splňuje podmínky
                    ust. § 562 odst. 2 Občanského zákoníku, tedy, že záznamy údajů v Aplikaci a její databázi, jakožto
                    elektronickém systému, jsou spolehlivé a jsou prováděny systematicky a posloupně a jsou chráněny
                    proti změnám.</p>


                <p className="title is-6">5. Osobní údaje a cookies</p>
                <p>5.1. S daty uživatele nakládá provozovatel v souladu se zásadami <Link to="/privacy-policy/"
                                                                                          onClick={() => window.scrollTo(0, 0)}>ochrany
                    osobních údajů</Link>.</p>


                <p className="title is-6">6. Odpovědnost za škodu</p>
                <p>6.1. Ustanovení tohoto článku VOP se nevztahují na spotřebitele.</p>
                <p>6.2. Uživatel se tímto výslovně vzdává práva na náhradu škody od Provozovatele neúmyslně nebo nikoli
                    z hrubé nedbalosti způsobené Provozovatelem porušením jakékoliv povinnosti Provozovatele uvedené ve
                    Smlouvě v souvislosti s plněním Smlouvy nebo v těchto VOP.</p>


                <p className="title is-6">7. Změna VOP</p>
                <p>7.1. Uživatel bere na vědomí, že Provozovatel uzavírá Smlouvu v běžném obchodním styku s větším
                    počtem osob a Smlouvy jsou podle svého charakteru zavazující dlouhodobě k opětovným plněním stejného
                    druhu s odkazem na tyto VOP. Smluvní strany činí nesporným, že z povahy závazků Provozovatele podle
                    těchto VOP vyplývá a již před uzavřením Smlouvy vyplývala rozumná potřeba pozdější změny těchto
                    VOP.</p>
                <p>7.2. Smluvní strany tímto ujednávají, že Provozovatel může tyto VOP v přiměřeném rozsahu změnit a
                    zároveň ujednávají, že změna VOP bude Uživateli, jako druhé straně, oznámena prostřednictvím
                    emailové zprávy odeslané na emailovou adresu zadanou Uživatelem do Aplikace. Uživatel má v takovém
                    případě právo změny VOP odmítnout a předmětný závazek mezi ním a Provozovatelem z tohoto důvodu
                    vypovědět ve výpovědní době jeden (1) kalendářní měsíc, která začne běžet den následující po
                    odeslání oznámení, o které Smluvní strany tímto shodně prohlašují, že je dostatečná k obstarání
                    obdobných plnění od jiného subjektu.</p>
                <p>7.3. V případě, že dojde k uzavření nové Smlouvy k již existujícímu Uživatelskému účtu (tj. k
                    obnovení nebo prodloužení Uživatelského účtu), řídí se taková Smlouva VOP ve znění ke dni uzavření
                    takové Smlouvy.</p>


                <p className="title is-6">8. Povinné informace pro spotřebitele</p>
                <p>8.1. Tento článek VOP podmínek je účinný pouze vůči Uživateli, který je spotřebitelem. Ustanovení
                    uvedená v tomto článku VOP se nevztahují zejména na podnikatele – živnostníky nebo obchodní
                    společnosti.</p>
                <p>8.2. Provozovatel tímto informuje Uživatele, že:</p>
                <p>8.3. Adresa pro doručování písemností Provozovateli je shodná s výše uvedenou adresou sídla
                    Provozovatele;</p>
                {/*<p>8.4. Telefonický kontakt Provozovatele je +420 737 441 064;</p>*/}
                <p>8.4. Adresa Provozovatele pro doručování elektronické pošty je punkhive@gmail.com;</p>
                <p>8.5. Odměna Provozovatele za Licenci je stanovena včetně všech daní a poplatků a je její výše a/nebo
                    způsob výpočtu je stanoven v Aplikaci;</p>
                <p>8.6. Uživatel je povinen zaplatit Provozovateli odměnu za Licenci, není-li Licence poskytování
                    bezúplatně, jedním ze způsobů, který je u příslušné možnosti uveden v uživatelském prostředí
                    Aplikace;</p>
                <p>8.7. Uživateli nevznikají náklady na dodání;</p>
                <p>8.8. K užívání Aplikace je třeba přístup k síti Internet a internetový prohlížeč Internet Explorer
                    9+,
                    Google Chrome, Mozilla Firefox, Safari, Opera.</p>
                <p>8.9. Uživatel má právo od Smlouvy odstoupit bez uvedení důvodu a bez jakékoliv sankce do 14 dnů od
                    převzetí plnění. Převzetím plnění se rozumí poskytnutí Licence.</p>
                <p>8.10. Ve vztahu k Provozovateli nevznikají Uživateli žádné náklady za použití komunikačních
                    prostředků
                    na dálku.</p>
                <p>8.11. Údaje o uzavření Smlouvy vč. těchto obchodních podmínek jsou uloženy v databázi Aplikace a
                    Uživatel k nim má přístup prostřednictvím Uživatelského účtu.</p>
                <p>8.12. Uživatel má možnost zjistit chyby kontrolou v Uživatelském účtu;</p>
                <p>8.13. Opravování chyb vzniklých při zadávání dat lze provádět rovněž prostřednictvím Aplikace a tam
                    kde
                    to Aplikace neumožňuje, tak prostřednictvím technické podpory, jejíž kontakt je uveden v
                    Aplikaci;</p>
                <p>8.14. Provozovatel dodržuje všechny platné zákony České republiky; žádné další kodexy nejsou pro
                    Provozovatele závazné;</p>
                <p>8.15. Provozovatel nevyužívá žádné prostředky mimosoudního řešení sporů;</p>
                <p>8.16. Předmětem Smlouvy není dodání zboží, na Aplikace se tak nevztahují ustanovení zákona o
                    reklamaci;
                    Odpovědnost za zjevné nebo skryté vady Aplikace, které měla Aplikace v době jeho zpřístupnění
                    Uživateli, lze uplatnit u Provozovatele za podmínek stanovených zákonem, Smlouvou a těmito VOP;</p>
                <p>8.17. Uživatel je povinen dodržovat tyto VOP, které jsou též součástí smlouvy a platné a účinné
                    právní
                    předpisy České republiky.</p>
                <p>8.18. Uživatel se může obrátit se stížností na orgán dohledu nebo státního dozoru. Česká obchodní
                    inspekce vyřizuje mimosoudní stížnosti spotřebitelů způsobem a za podmínek stanovených příslušnými
                    právními předpisy.</p>


                <p className="title is-6">9. Rozhodné právo</p>
                <p>9.1. Tyto VOP, jakož i Smlouva, se řídí právním řádem České republiky, zejména zák. č. 89/2012 Sb.,
                    občanský zákoník, ve znění pozdějších předpisů.</p>
                <p>9.2. Jakékoliv spory vzniklé na základě Smlouvy a/nebo těchto VOP budou rozhodovat věcně a místně
                    příslušné soudy České republiky.</p>


                <p className="title is-6">10. Účinnost</p>
                <p>10.1. Tyto VOP nabývají platnosti a účinnosti dne 1. 3. 2022.</p>

            </div>
        </div>
    </div>
}
