import useAuth from "../hooks/useAuth";
import {useHistory} from "react-router-dom";
import React from "react";

export default function LogoutButton({children}) {
    const {logout} = useAuth();

    let history = useHistory();

    const handleLogout = () => {
        logout().then(() => {
            history.replace({pathname: "/"})
        });
    };

    return (
        <button onClick={handleLogout} className="button is-outlined without-border">{children}</button>
    );
}

export  function NavBarLogoutButton({children, onClick, innerRef}) {
    const {logout} = useAuth();

    let history = useHistory();

    const handleLogout = (onClick) => {
        return () => {
            logout().then(() => {
                history.replace({pathname: "/"})
            });
            onClick()
        }
    };

    return (
        <button onClick={handleLogout(onClick)} className="button is-a navbar-item" ref={innerRef}>
            {children}
        </button>
    );
}