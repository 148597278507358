import {useEffect, useState} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import {useFetch} from "../hooks/useFetch";
import {API_URL} from "../config";
import Error from "../components/Error";
import Breadcrumb, {BreadcrumbItem, LocationBreadcrumbItem} from "../components/Breadcrumb";
import {AiOutlineEdit} from "react-icons/ai";
import {BiHive} from "react-icons/bi";
import {FaBook, FaChevronLeft, FaChevronRight, FaHome} from "react-icons/fa";
import {GiBee} from "react-icons/gi";
import {HiPlus} from "react-icons/hi";
import {ConfirmDialog} from "../components/ConfirmDialog";
import {deleteData} from "../utils/deleteData";
import {store} from "react-notifications-component";
import {HiveCard} from "../components/HiveCard";
import {formatDate} from "../utils/utils";
import QueenBeeSvg from "../components/QueenBee";
import {Feeding} from "../components/Feeding";
import {QueenBeeHistory} from "../components/QueenBeeHistory";
import Loader from "../components/Loader";
import {useProfile} from "../hooks/useProfile";
import {Entry} from "../components/Visits";
import {usePosition} from "../index";

const NoQueenBee = ({hiveSlug}) => {
    const {isPremium} = useProfile();

    return <div className="panel">
        <p className="panel-heading is-size-6">
            <GiBee className="mr-2"/>Matka
        </p>
        {isPremium ?
            <div className="p-5">
                <Link to={`/ul/${hiveSlug}/nova-matka/`} className="button"><HiPlus className="mr-3"/>Přidat
                    matku</Link>
            </div> :
            <div className="p-5">
                <i>Bez matky</i>
            </div>
        }
    </div>
}

const QueenBee = ({slug}) => {

    const {data, loading, error} = useFetch(
        `${API_URL}/queen-bees/${slug}/`
    );

    const [showQueenBeeHistory, setShowQueenBeeHistory] = useState(false)

    return <div className="panel">
        <p className="panel-heading is-size-6">
            <GiBee className="mr-2"/>Matka
            {data ?
                <Link to={`/matka/${slug}/upravit/`}
                      className="button is-small-ico is-dark is-pulled-right"><AiOutlineEdit/></Link> : ""}
        </p>
        {data ?
            <div className="p-5">
                <div className="media">
                    <div className="media-left has-text-centered is-3">
                        <QueenBeeSvg name={data.name && data.name.substring(0, 3)} color={data.color}/>
                    </div>
                    <div className="media-content">
                        {data.birth_date ?
                            <p className="mb-1  is-small title is-6">
                                Vylíhnutí: {formatDate(data.birth_date)}
                            </p> : <></>}
                        {data.added_to_hive_date ?
                            <p className="mb-1 content is-small">
                                Přidání: {formatDate(data.added_to_hive_date)}
                            </p> : <></>}
                        {data.origin ?
                            <p className="mb-1 content is-small">
                                Původ: {data.origin}
                            </p> : <></>}
                        {data.kind ?
                            <p className="mb-1 content is-small">
                                Linie: {data.kind}
                            </p> : <></>}
                    </div>
                </div>
                <div className="pb-5">
                    <button className="button is-normal is-pulled-right"
                            onClick={(e) => setShowQueenBeeHistory(!showQueenBeeHistory)}>
                        {showQueenBeeHistory ? "Schovat historii" : "Historie"}
                    </button>
                </div>
                {showQueenBeeHistory ?
                    <QueenBeeHistory className="animate__animated animate__fadeIn"
                                     hive={data.hive}></QueenBeeHistory> : ""}
            </div> : loading ? <div className="pb-5"><Loader className="mt-5"/></div> : ""}
    </div>;
}

export default function Hive() {

    let params = useParams();

    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);

    const {data, loading, error} = useFetch(
        `${API_URL}/hives/${params.hiveSlug}/`
    );

    const {setPageForPosition} = usePosition()

    useEffect(() => {
        document.title = "Chytrý včelař | Detail úlu"
        setPageForPosition("Hive");
    }, []);

    let history = useHistory();
    const {isPremium} = useProfile()

    const deleteLocation = () => {
        const name = data.name;
        const locationSlug = data.location_slug;
        deleteData(`${API_URL}/hives/${data.slug}/`).then(data => {
            store.addNotification({
                title: "Úl smazán",
                message: <>Úl <b>{name}</b> byl smazán.</>,
                type: "danger",
                insert: "top",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                slidingExit: {
                    duration: 0,
                    timingFunction: 'ease-out',
                    delay: 0
                },
                dismiss: {
                    duration: 5000,
                    pauseOnHover: true
                }
            });
            history.replace({pathname: `/stanoviste/${locationSlug}`})
        }).catch(function (err) {
            // TODO: Error
        });
    }

    if (loading) {
        return <Loader/>
    }

    if (error) {
        return <Error/>
    }

    return (
        <>
            <Breadcrumb>
                <BreadcrumbItem title="Stanoviště" to="/muj-denik/"><FaHome/></BreadcrumbItem>
                {data ?
                    <>
                        <LocationBreadcrumbItem locationSlug={data.location_slug}/>
                        <BreadcrumbItem title={data.name} to="#"><BiHive/></BreadcrumbItem>
                    </> : ""
                }
            </Breadcrumb>

            {data ?
                <>
                    <div className="container is-fluid mt-4">
                        <div className="columns">
                            <div className="column">
                                <h1 className="title is-4">{data.name}</h1>
                            </div>
                            <div className="column has-text-right">
                                {isPremium &&
                                <>
                                    <Link to={`/ul/${data.slug}/upravit/`}
                                          className="button is-dark mr-2">
                                        Upravit
                                    </Link>
                                    <button onClick={() => setShowDeleteConfirmationDialog(true)}
                                            className="button is-danger">Odstranit
                                    </button>
                                    <ConfirmDialog question={<>Opravdu si přejete vymazat úl <b>{data.name}</b>?</>}
                                                   isActive={showDeleteConfirmationDialog}
                                                   onClose={() => setShowDeleteConfirmationDialog(false)}
                                                   onConfirm={deleteLocation}
                                    />
                                </>}
                            </div>
                        </div>
                        <hr className="mt-0"/>
                        <div className="columns">
                            <div className="column is-4">
                                <HiveCard hive={data}/>
                                {(data.active_queen_bee) ?
                                    <QueenBee slug={data.active_queen_bee_slug}/>
                                    : <NoQueenBee hiveSlug={data.slug}/>
                                }
                                <Feeding hiveId={data.id}/>
                            </div>
                            <div className="column is-8">
                                <Entries hiveId={data.id}/>
                            </div>
                        </div>
                    </div>
                </> : ""}
        </>);
}

const Entries = ({hiveId}) => {
    const {setPosition, position, pageForPosition} = usePosition();
    const [apiUrl, setApiUrl] = useState(`${API_URL}/entries/?hive=${hiveId}&page=${position[pageForPosition][0]}&page_size=20`)
    const [page, setPage] = useState(position[pageForPosition][0])

    const {data, loading} = useFetch(
        apiUrl
    );

    useEffect(() => {
        if (data) {
            window.scrollTo(0, position[pageForPosition][1]);
        }
    })

    const [change, setChange] = useState(0);
    const onChange = () => {
        setChange(old => old + 1)
        setApiUrl(`${API_URL}/entries/?hive=${hiveId}&page_size=20&page=${page}&ch=${change}`)
    }
    const {isPremium} = useProfile();

    return <>
        <div className="panel">
            <div className="panel-heading is-size-6">
                <FaBook className="mr-2"/>
                {data ?
                    <>Prohlídky {data ? `(${data.count})` : ""}</>
                    : <>Prohlídky se načítají...</>}
            </div>
        </div>

        {loading ? <Loader/> :

            (data && data.count > 0) ?
                <>
                    {data.results.map(entry => <div key={entry.id} className="panel">
                        <Entry key={entry.id} entry={entry} includeDate={true} useDetailedEditLink={true} page={page} onDelete={onChange}/>
                    </div>)}
                    {
                        (data.next || data.previous) ?
                            <div className="panel">
                                <div className="panel-block">
                                    <nav className="pagination is-right" style={{width: "100%"}}>
                                        <a href={data.previous && "#/"}
                                           onClick={(e) => {
                                               e.preventDefault()
                                               setPage(oldPage => oldPage - 1)
                                               setApiUrl(data.previous);
                                               window.scrollTo(0, 0);
                                               setPosition({...position, [pageForPosition]: [page, 0]});
                                           }}
                                           className="pagination-previous" disabled={!data.previous}><FaChevronLeft
                                            className="mr-3"/>Novější
                                        </a>
                                        <span style={{order: 1}}>
                                          {`strana ${page} / ${Math.floor(data.count / 20) + 1}`}
                                        </span>
                                        <a href={data.next && "#/"}
                                           onClick={(e) => {
                                               e.preventDefault();
                                               setPage(oldPage => oldPage + 1)
                                               setApiUrl(data.next)
                                               window.scrollTo(0, 0);
                                               setPosition({...position, [pageForPosition]: [page, 0]});
                                           }}
                                           className="pagination-next" disabled={!data.next}>Starší<FaChevronRight
                                            className="ml-3"/></a>
                                    </nav>
                                </div>
                            </div>
                            : ""
                    }
                </>
                :
                (data && data.count <= 0) ?
                    <div className="panel-block">Úl je zatím bez prohlídek.</div>
                    : ""
        }
    </>
}
