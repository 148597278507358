import {useFetch} from "../hooks/useFetch";
import {API_URL} from "../config";
import {
    Bar,
    BarChart,
    LineChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    Line
} from 'recharts';
import {
    FaAndroid,
    FaApple,
    FaBacteria,
    FaBan,
    FaBed,
    FaBeer,
    FaBus,
    FaCar,
    FaCaravan,
    FaCat,
    FaCheck,
    FaCookie,
    FaCrown,
    FaDog,
    FaFile,
    FaGasPump,
    FaGlassMartini,
    FaHands,
    FaKiwiBird,
    FaLaptop,
    FaMask,
    FaMoneyBill,
    FaPills,
    FaPlane,
    FaTheaterMasks,
    FaTree,
    FaUser,
    FaUserTie
} from "react-icons/fa";
import {ProgressBar} from "../components/ProgressBar";
import {useState} from "react";

const UserStatsPanel = ({registered, anonymous = 0, children}) => {
    return <article className="panel is-dark"><p className="panel-heading">
        {children}
    </p>
        <p className="panel-block">
            <FaUser className="mr-2"/>
            Registrovaní: {registered}
        </p>
        {anonymous !== 0 &&
        <p className="panel-block">
            <FaMask className="mr-2"/>
            Anonymní: {anonymous}
        </p>}
    </article>
}

const RandomIco = () => {
    const elms = [<FaTree className="mr-2"/>,
        <FaBacteria className="mr-2"/>,
        <FaBus className="mr-2"/>,
        <FaGlassMartini className="mr-2"/>,
        <FaCookie className="mr-2"/>,
        <FaCar className="mr-2"/>,
        <FaCaravan className="mr-2"/>,
        <FaKiwiBird className="mr-2"/>,
        <FaPills className="mr-2"/>,
        <FaPlane className="mr-2"/>,
        <FaBed className="mr-2"/>,
        <FaLaptop className="mr-2"/>,
        <FaHands className="mr-2"/>,
        <FaGasPump className="mr-2"/>,
    ]
    return elms[Math.floor(Math.random() * elms.length)]
}

const PerformGoPayCheck = ({paid}) => {
    const {data} = useFetch(`${API_URL}/payments/`);

    return data ? (data.payments === paid ? <FaCheck className="ml-2"/> : <FaBan className="ml-2"/>) : <button disabled className="button is-small-ico is-dark is-pulled-right ml-2"><FaCat/></button>
}

const GoPayCheck = ({paid}) => {
    const [isActive, setActive] = useState(false)

    return (isActive) ? <PerformGoPayCheck paid={paid}/> :
        <button onClick={() => setActive(true)} className="button is-small-ico is-dark is-pulled-right ml-2"><FaDog/></button>
}

export default function UserStats() {

    const {data} = useFetch(`${API_URL}/user-stats/`);
    const goal = 600

    return data ?
        <div className="container is-fluid mt-4">
            <h1 className="title is-4">Statistiky</h1>
            <hr className="mt-0"/>
            <div className="columns">
                <div className="column">
                    <UserStatsPanel registered={data.ios_registered} anonymous={data.ios_anonymous}>
                        <FaApple className="mr-2"/> iOS
                    </UserStatsPanel>
                </div>
                <div className="column">
                    <UserStatsPanel registered={data.android_registered} anonymous={data.android_anonymous}>
                        <FaAndroid className="mr-2"/> Android
                    </UserStatsPanel>
                </div>
            </div>
            <div className="columns">
                <div className="column">
                    <article className="panel is-dark"><p className="panel-heading">
                        <FaCrown className="mr-2"/> Platící
                    </p>
                        <p className="panel-block">
                            <FaUser className="mr-2"/>
                            {data.paid} <GoPayCheck paid={data.paid}></GoPayCheck>
                        </p>
                        <p className="panel-block">
                            <FaMoneyBill className="mr-2"/>
                            {(data.paid - 199) * 299 + 39601} Kč hrubého
                        </p>
                        <p className="panel-block">
                            <FaBeer className="mr-2"/>
                            Cíl&nbsp;{goal}: <ProgressBar color="dark" className="ml-3 mr-4"
                                                          value={Math.round(data.paid / goal * 100)}/>{Math.round(data.paid / goal * 100)}%
                        </p>
                    </article>
                </div>
                <div className="column">
                    <article className="panel is-dark"><p className="panel-heading">
                        <RandomIco/> Ostatní
                    </p>
                        <p className="panel-block">
                            <FaUserTie className="mr-2"/>
                            Uživatelé exportů: {data.pdf_users}
                        </p>
                        <p className="panel-block">
                            <FaFile className="mr-2"/>
                            Exporty: {data.pdf_count}
                        </p>
                        <p className="panel-block">
                            <FaTheaterMasks className="mr-2"/>
                            Přepnutí uživatelé: {data.switched}
                        </p>
                    </article>
                </div>
            </div>
            <div className="columns mt-5">
                <div className="column">
                    <Graph60 data={data.last_60} slice={14}/>
                </div>
            </div>
            <div className="columns mt-5">
                <div className="column">
                    <GraphEntries60 data={data.entries60}/>
                </div>
            </div>
        </div> : "";
}

const Graph60 = ({data, slice = 14}) => {
    return <ResponsiveContainer width='100%' aspect={2.5 / 1}><BarChart
        data={data.slice(0, slice).map(item => ({...item, datum: item.datum.slice(0, -4)}))}
    >
        <CartesianGrid strokeDasharray="3 3"/>
        <XAxis dataKey="datum"/>
        <YAxis width={23} />
        <Tooltip/>
        <Legend/>
        <Bar dataKey="registrace" fill="#777777"/>
        <Bar dataKey="platby" fill="#FBB518"/>
    </BarChart></ResponsiveContainer>
}

const GraphEntries60 = ({data, slice = 60}) => {
    return <ResponsiveContainer width='100%' aspect={2.5 / 1}><LineChart
        data={data.slice(0, slice).map(item => ({...item, datum: item.datum.slice(0, -4)}))}
    >
        <CartesianGrid strokeDasharray="3 3"/>
        <XAxis dataKey="datum"/>
        <YAxis width={60} domain={[10000, 18000]} />
        <Tooltip/>
        <Legend/>
        <Line type="monotone" dataKey="prohlídky" stroke="#FBB518"/>
    </LineChart></ResponsiveContainer>
}
