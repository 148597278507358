import DatePicker, {registerLocale} from "react-datepicker";
import {cs} from 'date-fns/locale'
import {useFetch} from "../hooks/useFetch";
import {API_URL} from "../config";
import {useContext, useEffect, useState} from "react";
import {TagsContext} from "../App";
import {FaAngleDown, FaAngleUp, FaFilter} from "react-icons/fa";
import {Link} from "react-router-dom";
import {IoMdClose} from "react-icons/io";

registerLocale("cs", cs)

const TagOption = ({tag, active, ...props}) => {
    return <span style={
        {
            cursor: "pointer",
            fontSize: "0.7rem",
            height: "1.5rem",
            userSelect: "none",
            borderBottomRightRadius: "4px",
            borderTopRightRadius: "4px"
        }
    } {...props}
                 className={`tag mb-2 is-normal mr-2 ${active && "is-info"}`}>
    {tag}
        </span>
}

const TagsOfGroup = ({group, active, tags, setTags}) => {
    const [isActive, setActive] = useState(active);

    const tagsMap = useContext(TagsContext);

    const tagsInCategories = Object.values(tagsMap.tags).filter(tag => tag.group.id === group).reduce((result, tag) => {
        let categoryTitle = tag.category && tag.category.title || ""
        if (categoryTitle in result) {
            result[categoryTitle].push(tag)
        } else {
            result[categoryTitle] = [tag];
        }
        return result
    }, {})

    const values = () => {
        let allowedTags = Object.values(tagsMap.tags).filter(tag => tag.group.id === group).map(tag => tag.id)
        return tags.filter(tag => allowedTags.includes(tag))
    }

    const handleTagClick = (tagId) => {
        if (tags.includes(tagId)) {
            setTags(oldValues => oldValues.filter(x => x != tagId));
        } else {
            setTags(oldValues => [...oldValues, tagId]);
        }
    }

    return <>
        <header style={{cursor: "pointer"}} className="card-header" onClick={(() => setActive(v => !v))}>
            <p className="card-header-title">
                {tagsMap.groups[group].title} {values().length > 0 && `(${values().length})`}
            </p>
            <button className="card-header-icon">
                {isActive ? <FaAngleUp/> : <FaAngleDown/>}
            </button>
        </header>
        {isActive &&
        <div className="panel-block">
            <div className="container">
                {Object.keys(tagsInCategories).map(category => <div key={category}>
                    <p className="label is-small">{category}</p>
                    <p>
                        {tagsInCategories[category].map(tag =>
                            <TagOption
                                key={tag.id}
                                onClick={() => handleTagClick(tag.id)}
                                active={tags.includes(tag.id)}
                                tag={tag.title}/>
                        )}
                    </p>
                </div>)}</div>
        </div>
        }
    </>
}

export const Filters = ({locationId, isPremium = false, onChanged}) => {
    const {data} = useFetch(
        `${API_URL}/hives/?location=${locationId}`
    );

    const [isActive, setActive] = useState(false);
    const [tags, setTags] = useState([]);
    const [hive, setHive] = useState("__all__");
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const [filtersCount, setFiltersCount] = useState(0)
    const [firstTime, setFirstTime] = useState(true)

    useEffect(() => {
        // TODO: trochu divne, ale volalo se i pri "initu", proto firstTime
        if (!firstTime) {
            onChanged({
                tags: tags,
                hive: hive,
                from: from,
                to: to
            })
            setFiltersCount((tags.length) +
                (hive === "__all__" ? 0 : 1) +
                (from ? 1 : 0) +
                (to ? 1 : 0)
            )
        }
        setFirstTime(false)
    }, [tags, hive, from, to])

    const onIncludeTags = (toInclude) => {
        setTags(oldTags => [...oldTags, ...toInclude]);
    };

    const onExcludeTags = (toExclude) => {
        setTags(oldTags => oldTags.filter(x => !toExclude.includes(x)));
    };

    const eraseFilters = (e) => {
        e.stopPropagation()
        setFrom(null)
        setTags([])
        setTo(null)
        setHive("__all__")
    }

    return data ? <>
        <div style={{cursor: "pointer"}} className="panel-block pr-5" onClick={(() => setActive(v => !v && isPremium))}>
            <div className="container columns">
                <div className="column" style={{color: isPremium ? "black" : "lightGray"}}>
                    {isActive ? <><FaFilter className="mr-3"/>Skrýt filtry</> : <><FaFilter className="mr-3"/>Zobrazit
                        filtry {isPremium ? "" :
                            <span style={{color: "black"}}>(Tato funkce je dostupná pouze v režimu <Link
                                to="/profil/">Premium</Link>)</span>}</>}
                </div>
                {isPremium &&
                <div className="column has-text-right">{filtersCount > 0 &&
                <button onClick={eraseFilters} className="p-0 button is-a-red">
                    <IoMdClose class="mr-1"/> Zrušit filtry ({filtersCount})</button>}</div>
                }
            </div>
        </div>
        <div className="panel" style={{display: isActive ? "block" : "none"}}>
            <div className="panel-block">
                <div className="container">
                    <div className="columns">
                        <div className="column">
                            <label className="label">Od</label>
                            <DatePicker
                                placeholderText="pradávna"
                                className="input"
                                dateFormat="dd.MM.yyyy"
                                locale="cs"
                                showPopperArrow={false}
                                selected={from}
                                onChange={val => setFrom(val)}
                                autoComplete="off"
                            />
                        </div>
                        <div className="column">
                            <label className="label">Do</label>
                            <DatePicker
                                placeholderText="dneška"
                                className="input"
                                dateFormat="dd.MM.yyyy"
                                locale="cs"
                                showPopperArrow={false}
                                selected={to}
                                onChange={val => setTo(val)}
                                autoComplete="off"
                            />
                        </div>
                        <div className="column">
                            <label className="label">Úl</label>
                            <div className="select">
                                <select onChange={(e) => {
                                    setHive(e.target.value)
                                }}
                                        value={hive}
                                >
                                    <option value={"__all__"}>Všechny úly</option>
                                    {data.map(hive =>
                                        <option key={hive.id} value={hive.id}>{hive.name}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TagsOfGroup group={2} tags={tags} setTags={setTags}/>
            <TagsOfGroup group={4} tags={tags} setTags={setTags}/>
            <TagsOfGroup group={7} tags={tags} setTags={setTags}/>
            <TagsOfGroup group={3} tags={tags} setTags={setTags}/>
            <TagsOfGroup group={5} tags={tags} setTags={setTags}/>
        </div>
    </> : <></>
}
