import {useParams} from "react-router-dom";
import {useEffect} from "react";
import Breadcrumb, {BreadcrumbItem, LocationBreadcrumbItem} from "../components/Breadcrumb";
import {FaEdit, FaHome} from "react-icons/fa";
import {BiHive} from "react-icons/bi";
import {useFetch} from "../hooks/useFetch";
import {API_URL} from "../config";
import {QueenBeeForm} from "../components/QueenBeeForm";


export default function EditQueenBee() {

    let params = useParams();

    const {data} = useFetch(
        `${API_URL}/queen-bees/${params.queenBeeSlug}/`
    );

    useEffect(() => {
        document.title = "Chytrý včelař | Upravit matku"
    }, []);

    return <>
        <Breadcrumb>
            <BreadcrumbItem title="Stanoviště" to="/muj-denik/"><FaHome/></BreadcrumbItem>

            {data ?
                <>
                    <LocationBreadcrumbItem locationSlug={data.location_slug}/>
                    <BreadcrumbItem title={data.hive_name} to={`/ul/${data.hive_slug}`}><BiHive/></BreadcrumbItem>
                </> : ""
            }

            <BreadcrumbItem title="Úprava matky" to="#"><FaEdit/></BreadcrumbItem>
        </Breadcrumb>
        <div className="container is-fluid mt-4">
            {data ?
                <QueenBeeForm
                    slug={data.slug} initialValues={{
                    hive: data.hive,
                    is_active: true,
                    name: data.name,
                    color: data.color || "",
                    origin: data.origin || "",
                    birth_date:(data.birth_date && new Date(data.birth_date)) || "",
                    added_to_hive_date: (data.added_to_hive_date && new Date(data.added_to_hive_date)) || "",
                    kind: data.kind || ""
                }}/>
                : ""}
        </div>
    </>
}