/// Format date to local string
export function formatDate(dateString) {
    let date = new Date(Date.parse(dateString));
    return date.toLocaleDateString();
}

/// Format date to local string including time
export function formatDateAndTime(dateString) {
    let date = new Date(Date.parse(dateString));
    return `${date.toLocaleDateString()} | ${date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`;
}

/// Format date to local string as time only
export function formatTime(dateString) {
    let date = new Date(Date.parse(dateString));
    return `${date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`;
}

/// Format date to "%Y-%m-%d"
export function formatDateForApi(dateString) {
    let date = new Date(Date.parse(dateString));
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`
}

/// Chunk array to n-tuples
export function chunk(arr, size) {
    return arr.reduce(function (result, value, index, array) {
        if (index % size === 0)
            result.push(array.slice(index, index + size));
        return result;
    }, []);
}
