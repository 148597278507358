import {useState} from "react";
import Lightbox from "react-image-lightbox";

export default function Gallery({data, thumbSize="64x64"}) {

    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    return <div>
        {data.map((image, index) =>
            <figure key={index} className={`image is-inline-flex is-${thumbSize} mr-2 mt-4 mb-0`}>
                <a href="/#"
                   onClick={e => {
                       e.preventDefault();
                       setIsOpen(true);
                       setPhotoIndex(index)
                   }}>
                    <img className="has-rounded-corners"
                         alt=""
                         src={image.thumbnail_url}/>
                </a>
            </figure>
        )}

        {isOpen && (
            <Lightbox
                mainSrc={data[photoIndex].image_url}
                mainSrcThumbnail={data[photoIndex].thumbnail_url}
                nextSrc={(data[(photoIndex + 1) % data.length]).image_url}
                prevSrc={(data[(photoIndex + data.length - 1) % data.length]).image_url}
                onCloseRequest={() => setIsOpen(false)}
                onMovePrevRequest={() =>
                    setPhotoIndex((photoIndex + data.length - 1) % data.length)
                }
                imageTitle={`Obrázek ${(photoIndex + 1)} / ${data.length}`}
                enableZoom={false}
                closeLabel="Zavřít galerii"
                onMoveNextRequest={() =>
                    setPhotoIndex((photoIndex + 1) % data.length)
                }
                //loader={} TODO: dodá Ivo
            />
        )}
    </div>
}