// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
import useAuth from "../hooks/useAuth";
import {Redirect, Route} from "react-router-dom";

export default function PrivateRoute({children, ...rest}) {
    const {isLoggedIn} = useAuth();
    return (
        <Route
            {...rest}
            render={({location}) =>
                isLoggedIn ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {from: location}
                        }}
                    />
                )
            }
        />
    );
}