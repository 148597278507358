import React, {createContext} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import ReactNotification from 'react-notifications-component'
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Home from "./pages/Home";
import Premium from "./pages/Premium";
import UserStats from "./pages/UserStats";
import PaymentStatus from "./pages/PaymentStatus";
import Vcelkulacka from "./pages/Vcelkulacka";
import Terms from "./pages/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Login from "./pages/Login";
import Registration from "./pages/Registration";
import ForgottenPassword from "./pages/ForgottenPassword";
import ForgottenPasswordRequest from "./pages/ForgottenPasswordRequest";
import PrivateRoute from "./utils/PrivateRoute";
import NavBar from "./components/NavBar";
import HiveLocation from "./pages/HiveLocation";
import Dashboard from "./pages/Dashboard";
import Profile from "./pages/Profile";
import EditProfile from "./pages/EditProfile";
import NewLocation from "./pages/NewLocation";
import Hive from "./pages/Hive";
import NewHive from "./pages/NewHive";
import EditLocation from "./pages/EditLocation";
import EditHive from "./pages/EditHive";
import NewQueenBee from "./pages/NewQueenBee"
import EditQueenBee from "./pages/EditQueenBee"
import NewVisit from "./pages/NewVisit"
import EditNote from "./pages/EditNote";
import EditEntry from "./pages/EditEntry";
import Honey from "./pages/Honey";
import NewHoney from "./pages/NewHoney";
import EditHoney from "./pages/EditHoney";
import Exports from "./pages/Exports.js"


import 'react-image-lightbox/style.css';
import "react-datepicker/dist/react-datepicker.css";
import TermsEn from "./pages/TermsEn";
import PrivacyPolicyEn from "./pages/PrivacyPolicyEn";
import ForgottenPasswordEn from "./pages/ForgottenPasswordEn";

export const TagsContext = createContext();

export default function App() {
    return (
        <Router>
            <ReactNotification/>
            <div className="has-navbar-fixed-top">
                <NavBar color="primary" brandColor="white"></NavBar>

                <Switch>
                    <Route path="/login">
                        <Login/>
                    </Route>
                    <Route path="/registrace/">
                        <Registration/>
                    </Route>
                    <Route path="/zapomenute-heslo/:uid/:token/">
                        <ForgottenPassword/>
                    </Route>
                    <Route path="/forgotten-password/:uid/:token/">
                        <ForgottenPasswordEn/>
                    </Route>
                    <Route path="/zapomenute-heslo/">
                        <ForgottenPasswordRequest/>
                    </Route>
                    <Route path="/terms">
                        <Terms/>
                    </Route>
                    <Route path="/privacy-policy">
                        <PrivacyPolicy/>
                    </Route>
                    <Route path="/en/terms">
                        <TermsEn/>
                    </Route>
                    <Route path="/en/privacy-policy">
                        <PrivacyPolicyEn/>
                    </Route>
                    <PrivateRoute path="/profil/upravit/">
                        <EditProfile/>
                    </PrivateRoute>
                    <PrivateRoute path="/profil/">
                        <Profile/>
                    </PrivateRoute>
                    <PrivateRoute path="/muj-denik/">
                        <Dashboard/>
                    </PrivateRoute>
                    <PrivateRoute path="/nove-stanoviste/">
                        <NewLocation/>
                    </PrivateRoute>
                    <PrivateRoute path="/stanoviste/:hiveLocationSlug/upravit/">
                        <EditLocation/>
                    </PrivateRoute>
                    <PrivateRoute path="/stanoviste/:hiveLocationSlug/novy-ul/">
                        <NewHive/>
                    </PrivateRoute>
                    <PrivateRoute path="/ul/:hiveSlug/prohlidka/:entryId/upravit/">
                        <EditEntry fromHive={true}/>
                    </PrivateRoute>
                    <PrivateRoute path="/ul/:hiveSlug/upravit/">
                        <EditHive/>
                    </PrivateRoute>
                    <PrivateRoute path="/ul/:hiveSlug/nova-matka/">
                        <NewQueenBee/>
                    </PrivateRoute>
                    <PrivateRoute path="/matka/:queenBeeSlug/upravit">
                        <EditQueenBee/>
                    </PrivateRoute>
                    <PrivateRoute path="/stanoviste/:hiveLocationSlug/novy-zaznam/">
                        <NewVisit/>
                    </PrivateRoute>
                    <PrivateRoute path="/stanoviste/:hiveLocationSlug">
                        <HiveLocation/>
                    </PrivateRoute>
                    <PrivateRoute path="/ul/:hiveSlug">
                        <Hive/>
                    </PrivateRoute>
                    <PrivateRoute path="/poznamka/:noteId/upravit/">
                        <EditNote/>
                    </PrivateRoute>
                    <PrivateRoute path="/prohlidka/:entryId/upravit/">
                        <EditEntry/>
                    </PrivateRoute>
                    <PrivateRoute path="/medobrani/">
                        <Honey/>
                    </PrivateRoute>
                    <PrivateRoute path="/nove-medobrani/">
                        <NewHoney/>
                    </PrivateRoute>
                    <PrivateRoute path="/med/:honeyId">
                        <EditHoney/>
                    </PrivateRoute>
                    <PrivateRoute path="/exporty/">
                        <Exports/>
                    </PrivateRoute>
                    <Route path="/premium/">
                        <Premium/>
                    </Route>
                    <Route path="/vcelkulacka/">
                        <Vcelkulacka/>
                    </Route>
                    <PrivateRoute path="/platba/">
                        <PaymentStatus/>
                    </PrivateRoute>
                    <PrivateRoute path="/stats/">
                        <UserStats/>
                    </PrivateRoute>
                    <Route path="/500">
                        <Error500/>
                    </Route>
                    <Route path="/" exact={true}>
                        <Home/>
                    </Route>
                    <Route component={Error404}/>
                </Switch>

            </div>
        </Router>
    );
}

