import {useFetch} from "../hooks/useFetch";
import {API_URL} from "../config";
import {useEffect} from "react";
import Breadcrumb, {BreadcrumbItem} from "../components/Breadcrumb";
import {FaEdit, FaUser} from "react-icons/fa";
import {ProfileForm} from "../components/ProfileForm";

export default function EditProfile() {

    const {data} = useFetch(
        `${API_URL}/profile/`
    );  

    useEffect(() => {
        document.title = "Chytrý včelař | Upravit profil"
    }, []);


    return <>
        <Breadcrumb>
            <BreadcrumbItem title="Profil" to="/profil/"><FaUser/></BreadcrumbItem>
            <BreadcrumbItem title="Úprava profilu" to="#"><FaEdit/></BreadcrumbItem>
        </Breadcrumb>
        <div className="container is-fluid mt-4">
            {data ?
                <ProfileForm
                    edit={true}
                    initialValues={{
                        name: `${data.user.first_name} ${data.user.last_name}`,
                        email: data.user.email,
                        number: data.number || "",
                        organization: data.organization || ""
                    }}/>
                : ""}
        </div>
    </>
}