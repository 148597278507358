import {useHistory} from "react-router-dom";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {postFormData} from "../utils/postFormData";
import {API_URL} from "../config";
import {store} from "react-notifications-component";
import {putFormData} from "../utils/putFormData";
import {TextField} from "./TextField";
import {DatePickerField} from "./DatePickerField";
import {ImageField} from "./ImageField";
import {Select} from "./Select";
import {IntegerField} from "./IntegerField";
import {ColorField} from "./ColorField";

const HIVE_TYPES = [
    "Nástavkový",
    "Warré",
    "Ležan",
    "Medná kráva",
    "Pozorovací",
    "Košnice",
    "Klát",
    "Brť",
    "Jiný"
]

let HIVE_FRAME_TYPES = [
    "39 x 24",
    "Langstroth 1/2",
    "Langstroth 2/3",
    "Langstroth 3/4",
    "Langstroth Originál",
    "37 x 30",
    "42 x 17",
    "42 x 27.5",
    "39 x 17",
    "Dadant",
    "Jiné"
]

let HIVE_COLONY_TYPES =  [
    "Oddělek",
    "Smetenec",
    "Roj",
    "Vyzimované"
]

export const HiveForm = ({initialValues, slug}) => {
    let history = useHistory();

    return <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
            name: Yup.string()
                .max(50, 'Název úlu nesmí obsahovat více než 50 znaků')
                .required('Povinný údaj')
        })}
        onSubmit={(values, {setSubmitting, setFieldError}) => {
            if (slug) {
                putFormData(`${API_URL}/hives/${slug}/`, values).then(data => {
                    setSubmitting(false);
                    store.addNotification({
                        title: "Úl upraven",
                        message: <>Úl <b>{values['name']}</b> byl úspěšně upraven.</>,
                        type: "info",
                        insert: "top",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        slidingExit: {
                            duration: 0,
                            timingFunction: 'ease-out',
                            delay: 0
                        },
                        dismiss: {
                            duration: 5000,
                            pauseOnHover: true
                        }
                    });
                    // TODO: zatím nevím, kam přesměrovat
                    history.replace({pathname: `/ul/${slug}/`})
                }).catch(function (err) {
                    for (const key of Object.keys(err)) {
                        setFieldError(key, err[key]);
                    }
                    setSubmitting(false);
                });
            } else {
                postFormData(`${API_URL}/hives/`, values).then(data => {
                    setSubmitting(false);
                    store.addNotification({
                        title: "Úl vytvořen",
                        message: <>Úl <b>{values['name']}</b> byl úspěšně vytvořen.</>,
                        type: "info",
                        insert: "top",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        slidingExit: {
                            duration: 0,
                            timingFunction: 'ease-out',
                            delay: 0
                        },
                        dismiss: {
                            duration: 5000,
                            pauseOnHover: true
                        }
                    });
                    history.replace({pathname: `/ul/${data.slug}`})
                }).catch(function (err) {
                    for (const key of Object.keys(err)) {
                        setFieldError(key, err[key]);
                    }
                    setSubmitting(false);
                });
            }
        }}
    >
        {props => (
            <Form>
                <div className="columns">
                    <div className="column">
                        <h1 className="title is-4">{slug ? "Úprava" : "Přidání"} úlu</h1>
                    </div>
                    <div className="column has-text-right">
                        {props.isSubmitting ?
                            <button type="submit" className="button"
                                    disabled>Ukládám...</button>
                            :
                            <button type="submit" className="button"
                                    disabled={((!(props.isValid && props.dirty)))}>Uložit</button>}
                    </div>
                </div>
                <hr className="mt-0"/>
                <div className="columns">
                    <div className="column">
                        <TextField disabled={props.isSubmitting} name="name" label="Název úlu"/>
                        <Select nullable={true} disabled={props.isSubmitting} name="type" label="Typ">
                            {HIVE_TYPES.map(value => <option key={value} value={value}>{value}</option>)}
                        </Select>
                        <Select nullable={true} disabled={props.isSubmitting} name="frame_type" label="Míra rámku">
                            {HIVE_FRAME_TYPES.map(value => <option key={value} value={value}>{value}</option>)}
                        </Select>
                        <IntegerField min="0" disabled={props.isSubmitting} name="box_count" label="Počet nástavků"/>
                        <ColorField disabled={props.isSubmitting} name="color" label="Barva" />
                        <DatePickerField disabled={props.isSubmitting} name="established" label="Datum založení"/>
                        <Select nullable={true} disabled={props.isSubmitting} name="colony_type" label="Včelstvo">
                            {HIVE_COLONY_TYPES.map(value => <option key={value} value={value}>{value}</option>)}
                        </Select>
                    </div>
                    <div className="column">
                        <ImageField initial={props.initialValues.image} name="image"
                                    setFieldValue={props.setFieldValue}/>
                    </div>
                </div>
            </Form>)}
    </Formik>
}