import {useEffect, useState} from "react";
import {storage} from "../config";
import useAuth from "./useAuth";

export function useFetch(uri, authorized = true) {
    const [data, setData] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    const {logout, isLoggedIn} = useAuth();
    useEffect(() => {
        if (!uri) return;
        if (isLoggedIn || !authorized) {
            const accessToken = storage.getItem("ud") && JSON.parse(storage.getItem("ud")).access_token;
            let success = false;
            const authorizationHeaders = authorized ? {
                Authorization: `Bearer ${accessToken}`
            } : {}
            setLoading(true);
            fetch(uri, {
                method: "GET",
                headers: authorizationHeaders
            })
                .then(response => {
                    if (response.status === 403) {
                        // maybe refresh token would be better
                        logout();
                    }
                    success = (response.status === 200);
                    return response.json();
                })
                .then((data) => {
                    if (success) {
                        setData(data);
                    } else {
                        setError({});
                    }
                }).then(() => setLoading(false)).catch(setError);
        }
    }, [uri, logout]);
    return {
        loading,
        data,
        error
    };
}