import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {PasswordField, TextField} from "./TextField";
import {postFormDataWithoutAuthorization} from "../utils/postFormData";
import {API_URL} from "../config";
import {store} from "react-notifications-component";
import {useHistory} from "react-router-dom";


export const ForgottenPasswordRequestForm = () => {
    let history = useHistory();

    return <Formik
        initialValues={{}}
        validationSchema={
            Yup.object({
                email: Yup.string().email('Neplatná adresa').required('Povinný údaj')
            })
        }
        onSubmit={(values, {setSubmitting, setFieldError}) => {
            postFormDataWithoutAuthorization(`${API_URL}/auth/password/reset/`, values).then(data => {
                setSubmitting(false);
                store.addNotification({
                    title: "Žádost o nové heslo vytvořena",
                    message: <>Na váš email byly zaslány pokyny pro obnovu hesla.</>,
                    type: "info",
                    insert: "top",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    slidingExit: {
                        duration: 0,
                        timingFunction: 'ease-out',
                        delay: 0
                    },
                    dismiss: {
                        duration: 5000,
                        pauseOnHover: true
                    }
                });
                history.replace({pathname: "/"})
            }).catch(function (err) {
                for (const key of Object.keys(err)) {
                    setFieldError(key, err[key]);
                }
                setSubmitting(false);
            });
        }}
    >
        {props => (
            <Form>
                <TextField disabled={props.isSubmitting} name="email" label="Přihlašovací email"/>
                <div className="field has-text-centered mt-5">
                    <button type="submit" className="button"
                            disabled={((!(props.isValid && props.dirty)))}>Zažádat o nové heslo
                    </button>
                </div>
            </Form>)}
    </Formik>
}