import {useFetch} from "../hooks/useFetch";
import {API_URL} from "../config";
import {formatDateForApi} from "../utils/utils";
import {IoFastFood} from "react-icons/io5";
import {useState} from "react";
import Loader from "./Loader";
import Error from "./Error";

export const FeedingRange = {
    LAST_WEEK: "last_w",
    LAST_THREE_WEEKS: "last_3_mo",
    LAST_TWO_MONTHS: "last_2_mo",
    LAST_HALF_YEAR: "last_half_yo",
    LAST_YEAR: "last_yo"
};
Object.freeze(FeedingRange);

const FeedingRow = ({id, label, data, unit = "l"}) => {
    return <>{data["result"][id] > 0 ? <tr>
        <td>{label}</td>
        <td><b>{data["result"][id]} {unit}</b></td>
    </tr> : <></>}</>

}

export const Feeding = ({hiveId}) => {
    const [range, setRange] = useState(FeedingRange.LAST_YEAR);

    //const firstDayOfYear = new Date(new Date().getFullYear(), 0, 1);
    const now = new Date();
    let from = new Date(now);
    const to = now

    switch (range) {
        case FeedingRange.LAST_WEEK:
            from.setDate(from.getDate() - 7);
            break;
        case FeedingRange.LAST_THREE_WEEKS:
            from.setDate(from.getDate() - 21);
            break;
        case FeedingRange.LAST_TWO_MONTHS:
            from.setMonth(from.getMonth() - 2);
            break;
        case FeedingRange.LAST_HALF_YEAR:
            from.setMonth(from.getMonth() - 6);
            break;
        default:
            from.setDate(from.getDate() - 365);
    }

    const {data, loading, error} = useFetch(
        `${API_URL}/hives/${hiveId}/feeding/?` + new URLSearchParams({
            from: formatDateForApi(from),
            to: formatDateForApi(to)
        })
    );

    if (error) {
        return <Error/>
    }

    return <div className="panel">
        <div className="panel-heading is-size-6">
            <div className="columns">
                <div className="column is-5">
                    <p><IoFastFood className="mr-2"/>Krmení</p>
                </div>
                <div className="column pt-2 pb-0">
                    <div className="select is-small">
                        <select className="" value={range} onChange={(e) => setRange(e.target.value)}>
                            <option value={FeedingRange.LAST_YEAR}>Poslední rok</option>
                            <option value={FeedingRange.LAST_HALF_YEAR}>Poslední půlrok</option>
                            <option value={FeedingRange.LAST_TWO_MONTHS}>Poslední dva měsíce</option>
                            <option value={FeedingRange.LAST_THREE_WEEKS}>Poslední tři týdny</option>
                            <option value={FeedingRange.LAST_WEEK}>Poslední týden</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        {loading ?
            <div className="p-5">
                <Loader className="mt-1"/>
            </div>
            : data ?
                <div className="p-5">
                    {(Object.keys(data["result"]).reduce(function (previous, key) {
                        return previous + data["result"][key];
                    }, 0) > 0) ?
                        <table className="table">
                            <tbody>
                            <FeedingRow id={63} label="Cukerný roztok 3:2" data={data}/>
                            <FeedingRow id={64} label="Cukerný roztok 1:1" data={data}/>
                            <FeedingRow id={65} label="Medocukrové těsto" unit="kg" data={data}/>
                            </tbody>
                        </table> : <i>Bez krmení</i>}
                </div> : ""}
    </div>;
}