import {useParams} from "react-router-dom";
import {useEffect} from "react";
import Breadcrumb, {BreadcrumbItem, LocationBreadcrumbItem} from "../components/Breadcrumb";
import {FaEdit, FaFlag, FaHome} from "react-icons/fa";
import {BiHive} from "react-icons/bi";
import {useFetch} from "../hooks/useFetch";
import {API_URL} from "../config";
import {QueenBeeForm} from "../components/QueenBeeForm";
import {GiHoneyJar} from "react-icons/gi";
import {HoneyForm} from "../components/HoneyForm";


export default function EditHoney() {

    let params = useParams();

    const {data} = useFetch(
        `${API_URL}/honey-harvesting/${params.honeyId}/`
    );

    useEffect(() => {
        document.title = "Chytrý včelař | Upravit medobraní"
    }, []);

    return <>
        <Breadcrumb>
            <BreadcrumbItem title="Medobraní" to="/medobrani/"><FaHome/></BreadcrumbItem>
            {data ?
                <BreadcrumbItem title="Úprava" to="#"><GiHoneyJar/></BreadcrumbItem> : ""
            }
        </Breadcrumb>
        <div className="container is-fluid mt-4">
            {data ?
                <HoneyForm
                    id={data.id}
                    showHumidity={data.humidity || false}
                    initialHives={data.hives}
                    initialValues={{
                        location: data.location,
                        weight: data.weight,
                        created: new Date(data.created) || new Date(),
                        kind: data.kind,
                        humidity: data.humidity || 16,
                        note: data.note || ""
                    }}/>
                : ""}
        </div>
    </>
}