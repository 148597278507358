import {useParams} from "react-router-dom";
import {useEffect} from "react";
import Breadcrumb, {BreadcrumbItem, LocationBreadcrumbItem} from "../components/Breadcrumb";
import {FaEdit, FaHome} from "react-icons/fa";
import {BiHive} from "react-icons/bi";
import {useFetch} from "../hooks/useFetch";
import {API_URL} from "../config";
import {HiveForm} from "../components/HiveForm";


export default function EditHive() {

    let params = useParams();

    const {data} = useFetch(
        `${API_URL}/hives/${params.hiveSlug}/`
    );

    useEffect(() => {
        document.title = "Chytrý včelař | Upravit úl"
    }, []);

    return <>
        <Breadcrumb>
            <BreadcrumbItem title="Stanoviště" to="/muj-denik/"><FaHome/></BreadcrumbItem>

            {data ?
                <>
                    <LocationBreadcrumbItem locationSlug={data.location_slug}/>
                    <BreadcrumbItem title={data.name} to={`/ul/${data.slug}`}><BiHive/></BreadcrumbItem>
                </> : ""
            }

            <BreadcrumbItem title="Úprava úlu" to="#"><FaEdit/></BreadcrumbItem>
        </Breadcrumb>
        <div className="container is-fluid mt-4">
            {data ?
                <HiveForm
                    slug={data.slug} initialValues={{
                    location: data.location,
                    name: data.name,
                    type: data.type || "",
                    frame_type: data.frame_type || "",
                    box_count: data.box_count,
                    color: data.color || "",
                    colony_type: data.colony_type || "",
                    established: (data.established && new Date(data.established))
                        || (data.created && new Date(data.created))
                        || new Date(),
                    image: data.image_url
                }}/>
                : ""}
        </div>
    </>
}