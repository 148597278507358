import {useEffect} from "react";
import Breadcrumb, {BreadcrumbItem} from "../components/Breadcrumb";
import {AiOutlinePlusSquare} from "react-icons/ai";
import {FaFlag, FaHome} from "react-icons/fa";
import {useParams} from "react-router-dom";
import {useFetch} from "../hooks/useFetch";
import {API_URL} from "../config";
import {HiveForm} from "../components/HiveForm";

export default function NewHive() {
    useEffect(() => {
        document.title = "Chytrý včelař | Nový úl"
    }, []);

    let params = useParams();

    const {data} = useFetch(
        `${API_URL}/hive-locations/${params.hiveLocationSlug}/`
    );

    return data ? (
        <>
            <Breadcrumb>
                <BreadcrumbItem title="Stanoviště" to="/muj-denik/"><FaHome/></BreadcrumbItem>
                <BreadcrumbItem title={data.name} to={`/stanoviste/${data.slug}`}><FaFlag/></BreadcrumbItem>
                <BreadcrumbItem title="Přidání úlu" to="#"><AiOutlinePlusSquare/></BreadcrumbItem>
            </Breadcrumb>
            <div className="container is-fluid mt-4">
                <HiveForm initialValues={{
                    location: data.id,
                    box_count: 0,
                    name: ""
                }}/>
            </div>
        </>) : "";
}

