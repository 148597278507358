import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {PasswordField} from "./TextField";
import {postFormDataWithoutAuthorization} from "../utils/postFormData";
import {API_URL} from "../config";
import {store} from "react-notifications-component";
import {useHistory} from "react-router-dom";


export const ForgottenPasswordForm = ({initialValues}) => {
    let history = useHistory();

    return <Formik
        initialValues={initialValues}
        validationSchema={
            Yup.object().shape({
                new_password1: Yup.string()
                    .required('Heslo musí být vyplněno')
                    .min(5, 'Heslo je příliš krátké'),
                new_password2: Yup.string()
                    .oneOf([Yup.ref('new_password1'), null], "Hesla se neshodují")
                    .required('Heslo musí být vyplněno')
            })
        }
        onSubmit={(values, {setSubmitting, setFieldError}) => {
            postFormDataWithoutAuthorization(`${API_URL}/auth/password/reset/confirm/`, values).then(data => {
                setSubmitting(false);
                store.addNotification({
                    title: "Heslo změněno",
                    message: <>Nové heslo bylo úspěšně nastaveno.</>,
                    type: "info",
                    insert: "top",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    slidingExit: {
                        duration: 0,
                        timingFunction: 'ease-out',
                        delay: 0
                    },
                    dismiss: {
                        duration: 5000,
                        pauseOnHover: true
                    }
                });
                history.replace({pathname: "/"})
            }).catch(function (err) {
                for (const key of Object.keys(err)) {
                    setFieldError(key, err[key]);
                }
                setSubmitting(false);
            });
        }}
    >
        {props => (
            <Form>
                <Field name="uid" type="hidden"/>
                <Field name="token" type="hidden"/>
                <PasswordField disabled={props.isSubmitting} name="new_password1" label="Nové heslo"/>
                <PasswordField disabled={props.isSubmitting} name="new_password2"
                               label="Nové heslo znovu (pro jistotu)"/>
                <div className="field has-text-centered mt-5">
                    <button type="submit" className="button"
                            disabled={((!(props.isValid && props.dirty)))}>Nastavit heslo
                    </button>
                </div>
            </Form>)}
    </Formik>
}