import {useHistory} from "react-router-dom";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {API_URL} from "../config";
import {DatePickerField} from "./DatePickerField";
import {Select} from "./Select";
import {FloatField} from "./IntegerField";
import {useEffect, useState} from "react";
import {useFetch} from "../hooks/useFetch";
import {HiveFigure} from "./Hive";
import {TextAreaField} from "./TextAreaField";
import {HoneyImage} from "./HoneyList";
import {FaExclamationCircle, FaExclamationTriangle, FaRegCheckCircle} from "react-icons/fa";
import {putFormData} from "../utils/putFormData";
import {store} from "react-notifications-component";
import {postFormData} from "../utils/postFormData";

const HONEY_KINDS = [
    "Květový smíšený",
    "Medovicový",
    "Akátový",
    "Lipový",
    "Řepkový",
    "Malinový",
    "Jetelový",
    "Slunečnicový",
    "Pohankový",
    "Vojtěškový",
    "Vřesový"
]

export const HoneyForm = ({initialValues, id, initialHives=[], showHumidity=false}) => {
    let history = useHistory();

    const [isHumidityVisible, setHumidityVisible] = useState(showHumidity)
    const [hives, setHives] = useState(initialHives)

    return <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
            location: Yup.number().required('Povinný údaj'),
            weight: Yup.number().min(0.1, 'Zadejte hmotnost')
        })}
        onSubmit={(values, {setSubmitting, setFieldError}) => {
            if (!isHumidityVisible) {
                values.humidity = 0
            }
            values.hives = JSON.stringify(hives);
            if (id) {
                putFormData(`${API_URL}/honey-harvesting/${id}/`, values).then(data => {
                    setSubmitting(false);
                    store.addNotification({
                        title: "Medobraní upraveno",
                        message: <>Medobraní bylo úspěšně upraveno.</>,
                        type: "info",
                        insert: "top",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        slidingExit: {
                            duration: 0,
                            timingFunction: 'ease-out',
                            delay: 0
                        },
                        dismiss: {
                            duration: 5000,
                            pauseOnHover: true
                        }
                    });
                    history.replace({pathname: `/medobrani/`})
                }).catch(function (err) {
                    for (const key of Object.keys(err)) {
                        setFieldError(key, err[key]);
                    }
                    setSubmitting(false);
                });
            } else {
                postFormData(`${API_URL}/honey-harvesting/`, values).then(data => {
                    setSubmitting(false);
                    store.addNotification({
                        title: "Medobraní vytvořeno",
                        message: <>Medobraní bylo úspěšně vytvořeno.</>,
                        type: "info",
                        insert: "top",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        slidingExit: {
                            duration: 0,
                            timingFunction: 'ease-out',
                            delay: 0
                        },
                        dismiss: {
                            duration: 5000,
                            pauseOnHover: true
                        }
                    });
                    history.replace({pathname: `/medobrani/`})
                }).catch(function (err) {
                    for (const key of Object.keys(err)) {
                        setFieldError(key, err[key]);
                    }
                    setSubmitting(false);
                });
            }
        }}
    >
        {props => (
            <Form>
                <div className="columns">
                    <div className="column">
                        <h1 className="title is-4">{id ? "Úprava" : "Přidání"} medobraní</h1>
                    </div>
                    <div className="column has-text-right">
                        {props.isSubmitting ?
                            <button type="submit" className="button"
                                    disabled>Ukládám...</button>
                            :
                            <button type="submit" className="button"
                                    disabled={((!(props.isValid)))}>Uložit</button>}
                    </div>
                </div>
                <hr className="mt-0"/>
                <div className="columns">
                    <div className="column is-1">
                        {props && props.values && props.values.kind &&
                        <figure className="image is-64x64">
                            <HoneyImage kind={props.values.kind}/>
                        </figure>
                        }
                    </div>
                    <div className="column">
                        <LocationsSelect props={props} onChange={(e) => {
                            props.handleChange(e);
                            setHives([])
                        }}/>
                        <HivesField location={props.values.location} onHivesSet={setHives} initial={initialHives}/>
                    </div>
                    <div className="column">
                        <DatePickerField disabled={props.isSubmitting} name="created"
                                         label="Datum"></DatePickerField>
                        <Select nullable={false} disabled={props.isSubmitting} name="kind" label="Druh medu">
                            {HONEY_KINDS.map(value => <option key={value} value={value}>{value}</option>)}
                        </Select>
                        <FloatField disabled={props.isSubmitting} name="weight" label="Hmotnost (v kg)"/>
                        <Field type="checkbox" checked={isHumidityVisible} onClick={() => {
                            setHumidityVisible(!isHumidityVisible)
                        }}/> Vlhkost
                        {isHumidityVisible &&
                        <>
                            <FloatField min={14} max={23} disabled={props.isSubmitting} name="humidity"
                                        label="Podíl vody (v %)"/>
                            <HumidityIndicator humidity={props.values.humidity}/>
                        </>
                        }
                    </div>
                    <div className="column">
                        <TextAreaField disabled={props.isSubmitting} name="note"
                                       label="Poznámka"/>
                    </div>
                </div>
            </Form>)}
    </Formik>
}

const HumidityIndicator = ({humidity}) => {
    if (humidity <= 18.0) {
        return <p><FaRegCheckCircle style={{color: "green"}} className="ml-2 vatt"/> do 18% - kvalitní med</p>
    }
    if (humidity <= 20.0) {
        return <p><FaExclamationTriangle style={{color: "orange"}} className="ml-2 vatt"/> 18% až 20% - hraniční hodnoty
        </p>
    }
    return <p><FaExclamationCircle style={{color: "red"}} className="ml-2 vatt"/> více než 20% - med může zkvasit</p>
}

const LocationsSelect = ({props, onChange}) => {
    const {data} = useFetch(
        `${API_URL}/hive-locations/`
    );

    if (data) {
        return <Select nullable={true} onChange={onChange} disabled={props.isSubmitting} name="location"
                       label="Stanoviště">
            {data.map(value => <option key={value.id} value={value.id}>{value.name}</option>)}
        </Select>
    }
    return <></>
}

const HivesField = ({
                        location, initial = [], onHivesSet = (hives) => {
    }
                    }) => {
    const {data} = useFetch(
        `${API_URL}/hives/?location=${location}`
    );

    const [hives, setHives] = useState(initial)

    const onHiveChange = (hive) => {
        return (event) => {
            const value = event.target.value
            if (value != 0) {
                const toInsert = {id: hive.id, name: hive.name, color: hive.color, box_count: value}
                setHives(oldHives => [...oldHives.filter(x => hive.id !== x.id), toInsert]);
            } else {
                setHives(oldHives => [...oldHives.filter(x => hive.id !== x.id)]);
            }
        }
    }

    useEffect(() => {
        onHivesSet(hives)
    }, [hives])

    const initialHiveValue = (hive) => {
        const candidates = hives.filter(x => hive.id == x.id)
        if (candidates && candidates[0]) {
            return candidates[0].box_count
        }
        return 0;
    }

    if (data && data.length > 0) {
        return <>
            <label className="label">Úly</label>
            {data.map(hive => <div key={hive.id}>
                <div className="mb-2"><HiveFigure tiny={true} hive={hive}/> {hive.name}</div>
                <div className="field">
                    <div className="select mb-3">
                        <select onChange={onHiveChange(hive)} value={initialHiveValue(hive)}>
                            <option value={0}>--</option>
                            <option value={1}>1 nástavek</option>
                            <option value={2}>2 nástavky</option>
                            <option value={3}>3 nástavky</option>
                            <option value={4}>4 nástavky</option>
                            <option value={5}>5 nástavků</option>
                        </select>
                    </div>
                </div>
            </div>)}
        </>
    }
    return <></>
}
