import {storage} from "../config";

export function postFormData(uri, values) {

    if (!uri) return;
    const accessToken = storage.getItem("ud") && JSON.parse(storage.getItem("ud")).access_token;
    let formData = new FormData();
    for (let value in values) {
        if (values[value] instanceof Date) {
            formData.append(value, values[value].toISOString());
        } else {
            formData.append(value, values[value]);
        }
    }

    let success = false;
    return fetch(uri, {
        method: "POST",
        headers: {
            'Authorization': `Bearer ${accessToken}`,
        },
        body: formData
    }).then(response => {
        success = (response.status === 201);
        return response.json();
    }).then((data) => {
        if (success) {
            return data;
        } else {
            throw data;
        }
    })

}

export function postFormDataWithoutAuthorization(uri, values) {
    if (!uri) return;

    let formData = new FormData();
    for (let value in values) {
        formData.append(value, values[value]);
    }

    let success = false;
    return fetch(uri, {
        method: "POST",
        body: formData
    }).then(response => {
        success = (response.status === 200 || response.status === 201);
        return response.json();
    }).then((data) => {
        if (success) {
            return data;
        } else {
            throw data;
        }
    })
}