import {useHistory} from "react-router-dom";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {postFormData} from "../utils/postFormData";
import {API_URL} from "../config";
import {store} from "react-notifications-component";
import {putFormData} from "../utils/putFormData";
import {DatePickerField} from "./DatePickerField";
import {Select} from "./Select";
import {IntegerField} from "./IntegerField";
import {ColorField} from "./ColorField";
import {useState} from "react";

const QUEEN_BEE_ORIGINS = [
    "Zakoupená",
    "Rojová",
    "Tichá výměna",
    "Vložený matečník",
    "Inseminovaná",
    "Jiný"
]

const QUEEN_BEE_KINDS = [
    "Sklenar",
    "Troisek",
    "Vigor",
    "Singer",
    "Praděd",
    "Buckfast",
    "Jiná"
]

export const QueenBeeForm = ({initialValues, slug}) => {
    let history = useHistory();

    const [colorHintVisible, setColorHintVisible] = useState(false)

    return <Formik
        initialValues={initialValues}
        // TODO: validace, zatim nesmyslna
        validationSchema={Yup.object({
            name: Yup.number()
                .max(999, 'Název matky nesmí obsahovat více než 3 číslice.')
        })}
        onSubmit={(values, {setSubmitting, setFieldError}) => {
            if (slug) {
                putFormData(`${API_URL}/queen-bees/${slug}/`, values).then(data => {
                    setSubmitting(false);
                    store.addNotification({
                        title: "Matka upravena",
                        message: <>Matka {values['name'] ? <b>{values['name']} </b> : ""}byla úspěšně upravena.</>,
                        type: "info",
                        insert: "top",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        slidingExit: {
                            duration: 0,
                            timingFunction: 'ease-out',
                            delay: 0
                        },
                        dismiss: {
                            duration: 5000,
                            pauseOnHover: true
                        }
                    });
                    // TODO: zatím nevím, kam přesměrovat
                    history.replace({pathname: `/ul/${data.hive_slug}/`})
                }).catch(function (err) {
                    for (const key of Object.keys(err)) {
                        setFieldError(key, err[key]);
                    }
                    setSubmitting(false);
                });
            } else {
                postFormData(`${API_URL}/queen-bees/`, values).then(data => {
                    setSubmitting(false);
                    store.addNotification({
                        title: "Matka vytvořena",
                        message: <>Matka {values['name'] ? <b>{values['name']} </b> : ""}byla úspěšně vytvořena.</>,
                        type: "info",
                        insert: "top",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        slidingExit: {
                            duration: 0,
                            timingFunction: 'ease-out',
                            delay: 0
                        },
                        dismiss: {
                            duration: 5000,
                            pauseOnHover: true
                        }
                    });
                    // TODO: overit
                    history.replace({pathname: `/ul/${data.hive_slug}`})
                }).catch(function (err) {
                    for (const key of Object.keys(err)) {
                        setFieldError(key, err[key]);
                    }
                    setSubmitting(false);
                });
            }
        }}
    >
        {props => (
            <Form>
                <div className="columns">
                    <div className="column">
                        <h1 className="title is-4">{slug ? "Úprava" : "Přidání"} matky</h1>
                    </div>
                    <div className="column has-text-right">
                        {props.isSubmitting ?
                            <button type="submit" className="button"
                                    disabled>Ukládám...</button>
                            :
                            <button type="submit" className="button"
                                    disabled={((!(props.isValid && props.dirty)))}>Uložit</button>}
                    </div>
                </div>
                <hr className="mt-0"/>
                <div className="columns">
                    <div className="column">
                        <div className="columns">
                            <div className="column">
                                <IntegerField disabled={props.isSubmitting} name="name" label="Číslo"/>
                            </div>
                            <div className="column">
                                {/*<ColorField disabled={props.isSubmitting} name="color" label="Barva"/>*/}
                                <div onClick={() => setColorHintVisible(false)}
                                     className={`modal ${colorHintVisible && "is-active"}`}>
                                    <div className="modal-background"></div>
                                    <div className="modal-card card" style={{maxWidth: "400px"}}>
                                        <section className="modal-card-body">
                                            <h3 className="title is-4">Barevné značení matek</h3>
                                            <p>Matky vylíhlé v daném roce se značí stejnou barvou. Je to vždy jedna z
                                                pěti barev, které se po pěti letech opakují.</p>
                                            <ul className="mt-5">
                                                {[["yellow", "Žlutá", "2022"],
                                                    ["white", "Bílá", "2021"],
                                                    ["blue", "Modrá", "2020"],
                                                    ["green", "Zelená", "2019"],
                                                    ["red", "Červená", "2018"]
                                                ].map(([code, color, year]) =>
                                                    <li key={year} className="mt-2">
                                                        <figure
                                                            className="image is-20x20 has-rounded-corners has-shadow vatt mr-3"
                                                            style={{
                                                                backgroundColor: `${code}`,
                                                                display: "inline-block"
                                                            }}/>
                                                        {color} <span className="is-pulled-right">{year}</span></li>
                                                )}
                                            </ul>
                                        </section>
                                    </div>
                                </div>
                                <ColorField disabled={props.isSubmitting} setHintVisible={setColorHintVisible}
                                           name="color" label="Barva"/>
                            </div>
                        </div>
                        <Select nullable={true} disabled={props.isSubmitting} name="origin" label="Původ">
                            {QUEEN_BEE_ORIGINS.map(value => <option key={value} value={value}>{value}</option>)}
                        </Select>
                    </div>
                    <div className="column">
                        <div className="columns">
                            <div className="column">
                                <DatePickerField disabled={props.isSubmitting} name="birth_date"
                                                 label="Datum vylíhnutí"/>
                            </div>
                            <div className="column">
                                <DatePickerField disabled={props.isSubmitting} name="added_to_hive_date"
                                                 label="Datum přidání"/>
                            </div>
                        </div>
                        <Select nullable={true} disabled={props.isSubmitting} name="kind" label="Linie">
                            {QUEEN_BEE_KINDS.map(value => <option key={value} value={value}>{value}</option>)}
                        </Select>
                    </div>
                </div>
            </Form>)}
    </Formik>
}