import {Link} from "react-router-dom";

export default function TermsEn() {
    return <div className="section pt-3">
        <div className="container">
            <div className="box mt-5 content">
                <p className="title is-5">General Terms and Conditions</p>
                <p className="title is-6">1. Definitions</p>
                <p>In these terms and conditions:</p>
                <p>1.1. "Operator" is PunkHive s.r.o., ID: 21152195, with registered office at Kaštanová 1055/14,
                    Olomouc
                    779&nbsp;00.</p>
                <p>1.2. "Application" means the mobile and web application called "Chytrý včelař" (Smart Beekeeper), the
                    main function of which is to create and manage beekeeping records, which is available at the
                    internet address www.chytry-vcelar.cz.</p>
                <p>1.3. "GTC" means these General Terms and Conditions.</p>
                <p>1.4. "Contract" means the contract concluded between the User and the Operator, the subject of which
                    is the provision of a License for the use of the Application to the User under the conditions set
                    out in these GTC.</p>
                <p>1.5. "License" means a non-exclusive license to use the Application under the conditions set out in
                    these GTC.</p>
                <p>1.6. "User" means any legal or natural person other than the Operator who uses the Application.</p>
                <p>1.7. "User Account" means the non-public part of the Application that is accessible to the user after
                    entering the login details.</p>
                <p>1.8. "Login Details" mean the unique combination of the User's login name and password chosen by the
                    User, which the User saves in the Application's database when setting up a User Account in the
                    Application.</p>


                <p className="title is-6">2. Process of concluding the agreement on the use of the Application</p>
                <p>2.1. Displaying a button called "Buy Premium" in the user interface of the Application constitutes an
                    offer to conclude an agreement on the part of the Operator.</p>
                <p>2.2. By clicking the "Buy Premium" button, the User unconditionally accepts the offer to conclude the
                    agreement on the part of the User, and the agreement is thus concluded.</p>
                <p>2.3. By clicking on the checkbox that contains the description "I agree to the terms of service and
                    processing of personal data" and subsequently clicking the button in accordance with 2.2, the User
                    expresses their consent to these Terms and Conditions.</p>
                <p>2.4. The following provisions of these Terms and Conditions become effective upon the conclusion of
                    the agreement.</p>


                <p className="title is-6">3. Use of the Application</p>
                <p>3.1. The Operator grants the user a license within the scope and under the conditions specified in
                    the user
                    interface of the Application, in particular as specified for a given type of User Account.</p>
                <p>3.2. By accepting the license, the user agrees to pay the Operator a fee specified in the user
                    interface of the
                    Application. If no fee is specified for a particular type of use (in particular for a given type of
                    User Account),
                    the license is granted free of charge.</p>
                <p>3.3. In the case where the license is granted for a fee, the Operator has the right to provide the
                    fulfillment of the
                    Agreement (for example, to provide access to a given part or functionality of the Application) only
                    after the
                    payment of the fee for the license has been made. If the user does not pay the full amount of the
                    license fee within
                    the time limit specified by the Operator, or immediately if so specified, the Agreement is canceled
                    without any
                    further action from the beginning.</p>
                <p>3.4. After the expiration of the license period, the Operator is entitled to fully or partially deny
                    the user access to
                    their User Account.</p>
                <p>3.5. The user has the right to create more than one User Account.</p>
                <p>3.6. The user is not entitled to grant a sub-license to a third party for the use of the
                    Application.</p>
                <p>3.7. All financial payments made through the Application or payment gateway linked to the Application
                    shall be
                    deemed paid by crediting the full amount to the relevant Operator's bank account.</p>
                <p>3.8. The Operator has the right to shut down, modify, and/or deny access to the Application or any
                    part thereof from
                    the relevant internet address for any reason.</p>
                <p>3.9. The user has the right to use the Application subject to the Agreement in the current version
                    available from the
                    relevant internet address.</p>
                <p>3.10. The user agrees to use the Application in a way that does not cause any harm to themselves or
                    the Operator.</p>


                <p className="title is-6">4. Database</p>
                <p>4.1. The user is not entitled to extract the database associated with the Application using machine
                    methods (especially using so-called software robots).</p>
                <p>4.2. The user and the Operator hereby agree that any data inserted by the User into the Application's
                    database are parts of the database collected by the Operator and become a part of the Operator's
                    database without the User acquiring any right to the Application's database through their actions
                    described in this paragraph.</p>
                <p>4.3. The contracting parties are aware of, declare unanimously, and make it indisputable that the
                    Application complies with the conditions of § 562 paragraph 2 of the Civil Code, that is, the
                    records of data in the Application and its database, as an electronic system, are reliable,
                    systematically and sequentially performed, and protected against changes.</p>
                <p className="title is-6">5. Personal data and cookies</p>
                <p>5.1. The Operator handles user data in accordance with the principles of <Link
                    to="/en/privacy-policy/" onClick={() => window.scrollTo(0, 0)}>personal data protection</Link>.</p>
                <p className="title is-6">6. Liability for damages</p>
                <p>6.1. The provisions of this article of the TOS do not apply to consumers.</p>
                <p>6.2. The user hereby expressly waives their right to compensation for damage caused by the Operator's
                    unintentional or non-grossly negligent breach of any obligation of the Operator specified in the
                    Contract in connection with the performance of the Contract or in these TOS.</p>


                <p className="title is-6">7. Change of Terms and Conditions</p>
                <p>7.1. The User acknowledges that the Operator concludes the Agreement in normal business relations
                    with a larger number of persons, and the Agreements are, by their nature, obligating for long-term
                    repeated performance of the same kind with reference to these Terms and Conditions. The Parties
                    indisputably agree that the nature of the Operator's obligations under these Terms and Conditions
                    implies and already implied before the conclusion of the Agreement the reasonable need for later
                    changes to these Terms and Conditions.</p>
                <p>7.2. The Parties hereby agree that the Operator may change these Terms and Conditions to a reasonable
                    extent, and at the same time, they agree that the change of the Terms and Conditions will be
                    notified to the User, as the second party, by an email message sent to the email address provided by
                    the User in the Application. In such a case, the User has the right to refuse the change of the
                    Terms
                    and Conditions and terminate the respective obligation between him/her and the Operator within a
                    notice period of one (1) calendar month, which shall start running on the day following the sending
                    of the notification, which the Parties hereby equally declare sufficient to obtain similar
                    performance from another subject.</p>
                <p>7.3. In the event of concluding a new Agreement to an already existing User account (i.e. renewing or
                    extending the User account), such Agreement shall be governed by the Terms and Conditions in force
                    on the day of conclusion of such Agreement.</p>


                <p className="title is-6">8. Mandatory information for consumers</p>
                <p>8.1. This article of the terms and conditions is only effective against Users who are consumers. The
                    provisions
                    stated in this article of the terms and conditions do not apply in particular to entrepreneurs -
                    traders or
                    companies.</p>
                <p>8.2. The Operator hereby informs the User that:</p>
                <p>8.3. The address for delivering documents to the Operator is the same as the above-mentioned
                    registered office
                    address of the Operator;</p>
                {/*<p>8.4. The Operator's telephone contact is +420 737 441 064;</p>*/}
                <p>8.4. The Operator's email address for delivering electronic mail is punkhive@gmail.com;</p>
                <p>8.5. The Operator's fee for the License is determined including all taxes and fees, and its amount
                    and/or method
                    of calculation is set in the Application;</p>
                <p>8.6. The User is obliged to pay the Operator a fee for the License, if the License is not provided
                    free of charge,
                    by one of the methods specified in the relevant option in the user interface of the Application;</p>
                <p>8.7. The User incurs no delivery costs;</p>
                <p>8.8. Access to the Internet and Internet Explorer 9+, Google Chrome, Mozilla Firefox, Safari, or
                    Opera is
                    required to use the Application.</p>
                <p>8.9. The User has the right to withdraw from the Contract without giving a reason and without any
                    penalty within
                    14 days of receipt of the performance. Receipt of performance means the provision of the
                    License.</p>
                <p>8.10. In relation to the Operator, the User incurs no costs for using remote communication tools.</p>
                <p>8.11. Data on the conclusion of the Contract, including these terms and conditions, are stored in the
                    Application
                    database and the User has access to them through the User account.</p>
                <p>8.12. The User has the option of discovering errors by checking the User account;</p>
                <p>8.13. Corrections of errors arising from data entry can also be made through the Application, and
                    where the
                    Application does not allow it, through technical support, the contact of which is specified in the
                    Application;</p>
                <p>8.14. The Operator complies with all applicable laws of the Czech Republic; no other codes are
                    binding on the
                    Operator;</p>
                <p>8.15. The Operator does not use any means of out-of-court dispute resolution;</p>
                <p>8.16. The subject matter of the Contract is not the delivery of goods, so the provisions of the
                    Consumer
                    Protection Act do not apply to the Application; The liability for apparent or hidden defects of the
                    Application,
                    which the Application had at the time of its provision to the User, can be asserted against the
                    Operator under the
                    conditions set by law, the Contract, and these T&C;</p>

                <p>8.17. The user is obliged to comply with these Terms and Conditions, which are also part of the
                    contract and valid and effective under the laws of the Czech Republic.</p>
                <p>8.18. The user may file a complaint with the supervisory or regulatory authority. The Czech Trade
                    Inspection handles consumer extrajudicial complaints in the manner and under the conditions
                    prescribed by the relevant laws.</p>


                <p className="title is-6">9. Applicable Law</p>
                <p>9.1. These Terms and Conditions, as well as the Contract, shall be governed by the laws of the Czech
                    Republic, in particular Act No. 89/2012 Coll., the Civil Code, as amended.</p>
                <p>9.2. Any disputes arising from the Contract and/or these Terms and Conditions shall be resolved by
                    the competent courts of the Czech Republic.</p>


                <p className="title is-6">10. Effectiveness</p>
                <p>10.1. These Terms and Conditions shall become valid and effective on March 1, 2022.</p>

            </div>
        </div>
    </div>
}
