import {ErrorMessage, Field} from "formik";

export const Select = ({name, label, nullable=false, ...props}) => {
    return <div className="field">
        <label className="label" htmlFor={name}>{label}</label>
        <div className="select">
            <Field  {...props} as="select" name={name}>
                {nullable &&
                <option value="">--</option>
                }
                {props.children}
            </Field>
        </div>
        <p className="has-text-danger"><ErrorMessage name={name}/></p>
    </div>
}