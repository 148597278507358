import {useFetch} from "./useFetch";
import {API_URL} from "../config";
import useAuth from "./useAuth";

export const useProfile = () => {

    const {isLoggedIn} = useAuth();

    const {data} = useFetch(
        `${API_URL}/profile/`
    );

    return {
        isProfileDataReady: data && true,
        isPremium: isLoggedIn && data && data.is_premium
    }
};