import {storage} from "../config";

export function deleteData(uri) {

    if (!uri) return;
    const accessToken = storage.getItem("ud") && JSON.parse(storage.getItem("ud")).access_token;
    let success = false;
    return fetch(uri, {
        method: "DELETE",
        headers: {
            'Authorization': `Bearer ${accessToken}`,
        },
    }).then(response => {
        success = (response.status === 204);
        return response;
    }).then((data) => {
        if (success) {
            return data;
        } else {
            throw data;
        }
    })

}