import {useParams} from "react-router-dom";
import {useEffect} from "react";
import Breadcrumb, {BreadcrumbItem, LocationBreadcrumbItem} from "../components/Breadcrumb";
import {FaEdit, FaFlag, FaHome} from "react-icons/fa";
import {BiHive} from "react-icons/bi";
import {useFetch} from "../hooks/useFetch";
import {API_URL} from "../config";
import {QueenBeeForm} from "../components/QueenBeeForm";
import {GiHoneyJar} from "react-icons/gi";
import {HoneyForm} from "../components/HoneyForm";


export default function NewHoney() {

    let params = useParams();

    useEffect(() => {
        document.title = "Chytrý včelař | Nové medobraní"
    }, []);

    return <>
        <Breadcrumb>
            <BreadcrumbItem title="Medobraní" to="/medobrani/"><FaHome/></BreadcrumbItem>
            <BreadcrumbItem title={`Nové medobraní`} to="#"><GiHoneyJar/></BreadcrumbItem>
        </Breadcrumb>
        <div className="container is-fluid mt-4">
            <HoneyForm
                initialValues={{
                    weight: 0,
                    created: new Date(),
                    kind: "Květový smíšený",
                    humidity: 16,
                }}/>
        </div>
    </>
}