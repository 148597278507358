import img404 from "../img/404.png";

export default function Error404() {
    return <div className="section pt-6">
        <div className="container has-text-centered">
            <img src={img404}/>
            <p className="title is-4 mt-5">Ajaj... Toto asi nebude ten pravý úl...</p>
                <p>Stránka nenalezena, pokračujte proklikem na <a href="/">homepage</a></p>
        </div>
    </div>
}