import {ErrorMessage, Field} from "formik";

export const IntegerField = ({name, label, ...props}) => {
    return <div className="field">
        <label className="label" htmlFor={name}>{label}</label>
        <Field {...props} className="input" name={name} type="number"/>
        <p className="has-text-danger"><ErrorMessage name={name}/></p>
    </div>
}

export const FloatField = ({name, label, ...props}) => {
    return <div className="field">
        <label className="label" htmlFor={name}>{label}</label>
        <Field {...props} className="input" name={name} step={0.1} type="number"/>
        <p className="has-text-danger"><ErrorMessage name={name}/></p>
    </div>
}