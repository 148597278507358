import {Link} from "react-router-dom";

export default function LoginButton({outlined = false, children, onClick}) {
    return (
        onClick
            ?
            <button className={`button ${outlined ? "is-outlined" : ""}`} onClick={onClick}>
                {children}
            </button>
            :
            <Link to="/login" className={`button ${outlined ? "is-outlined" : ""}`}>
                {children}
            </Link>
    );
}