import {useEffect} from "react";
import Breadcrumb, {BreadcrumbItem} from "../components/Breadcrumb";
import {FaHome,} from "react-icons/fa";
import {AiOutlinePlusSquare} from "react-icons/ai";
import {LocationForm} from "../components/LocationForm";

export default function NewLocation() {
    useEffect(() => {
        document.title = "Chytrý včelař | Nové stanoviště"
    }, []);

    return (
        <>
            <Breadcrumb>
                <BreadcrumbItem title="Stanoviště" to="/muj-denik/"><FaHome/></BreadcrumbItem>
                <BreadcrumbItem title="Založení stanoviště" to="#"><AiOutlinePlusSquare/></BreadcrumbItem>
            </Breadcrumb>

            <div className="container is-fluid mt-4">
                <LocationForm initialValues={{
                    name: "",
                    address_line_1: "",
                    registration_number: "",
                    established: new Date()
                }}/>
            </div>
        </>);
}

