import React, {useEffect} from "react";
import Loader from "../components/Loader";
import Error from "../components/Error";
import {useFetch} from "../hooks/useFetch";
import {API_URL, IS_GOPAY_ENABLED} from "../config";
import {Link} from "react-router-dom";
import {useProfile} from "../hooks/useProfile";
import {FcOk} from "react-icons/fc";
import crown from "../img/crown.png";
import premium from '../img/premium-beekeeper.svg';
import p1 from "../img/premium-1.svg";
import p2 from "../img/premium-2.svg";
import p3 from "../img/premium-3.svg";
import p4 from "../img/premium-4.svg";
import {FaRegFileAlt} from "react-icons/fa";



const ProfileEntry = ({label, children}) => {
    return <>
        <div className="columns">
            <div className="column">
                {label}
            </div>
            <div className="column has-text-right">
                {children ? <b>{children}</b> : <i>-</i>}
            </div>
        </div>
        <hr style={{height: "1px", margin: "0.1rem 0 1.5rem 0"}}/>
    </>
}

const HoneyMenuItem = () => {

    const {data} = useFetch(
        `${API_URL}/hive-locations/`
    );

    return (data && data.length === 0) ?
        <li><Link style={{color: "#efefef"}}>Medobraní</Link></li>
        :
        <li><Link to="/medobrani/">Medobraní</Link></li>
}

export default function Profile() {

    const {isPremium} = useProfile()

    useEffect(() => {
        document.title = "Chytrý včelař | Profil uživatele"
    }, []);

    const {data, loading, error} = useFetch(
        `${API_URL}/profile/`
    );

    if (error) {
        return <Error/>
    }

    return (
        <>
            <div className="container is-fluid mt-4">
                <div className="columns">
                    <div className="column is-2">
                        <div className="menu">
                            <p className="menu-label">
                                Včely
                            </p>
                            <ul className="menu-list">
                                <li><Link to="/muj-denik/">Stanoviště</Link></li>
                                <HoneyMenuItem/>
                                <li><Link to="/exporty/">Exporty</Link></li>
                            </ul>
                            <p className="menu-label">
                                Nastavení
                            </p>
                            <ul className="menu-list">
                                <li><Link className="is-active" to="/profil/">Profil</Link></li>
                            </ul>
                        </div>
                    </div>

                    <div className="column">
                        {loading ? <Loader/> :
                            data &&
                            <>
                                <div className="columns">
                                    <div className="column">
                                        <h1 className="title is-4">Uživatel</h1>
                                    </div>
                                    <div className="column has-text-right">
                                        <Link to={`/profil/upravit/`}
                                              className="button is-dark">
                                            Upravit
                                        </Link>
                                    </div>
                                </div>
                                <hr className="mt-0 mb-4"/>
                                <div className="columns">
                                    <div className="column">
                                        <ProfileEntry
                                            label="Jméno">{`${data.user.first_name} ${data.user.last_name}`}</ProfileEntry>
                                        <ProfileEntry label="Email">{data.user.email}</ProfileEntry>
                                        <ProfileEntry label=" Číslo chovatele">{data.number}</ProfileEntry>
                                        <ProfileEntry label="Organizace">{data.organization}</ProfileEntry>
                                        <ProfileEntry label="Počet žihadel">{data.stings_count}</ProfileEntry>
                                        {IS_GOPAY_ENABLED &&
                                        <ProfileEntry label="Účet">{isPremium ? <>
                                            <FcOk/> Premium</> : "Zdarma"}</ProfileEntry>}
                                    </div>
                                    {IS_GOPAY_ENABLED ?
                                        <div className="column">
                                            {isPremium ?
                                                <PremiumInfo/> :
                                                <BuyPremium/>
                                            }
                                        </div> : ""}
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>);
}

const PremiumInfo = () => {
    return <div className="card ml-4">
        <div className="container p-4">
            <p className="title is-5 orange"><img src={crown} alt="Premium" className="mr-3" style={{verticalAlign: "middle"}}/>Chytrý
                včelař premium</p>
            <ProfileEntry label="Zaplaceno"></ProfileEntry>
        </div>

    </div>
}

const BuyPremium = () => {
    const {data} = useFetch(
        `${API_URL}/pay/create/`
    );

    return data ?
        <div className="card ml-4">
            <div className="container pl-6 pr-6 pt-5 pb-5 has-text-centered">
                <img style={{width: "96px"}} src={premium} alt="Premium" className="mb-3"/>
                <p className="title is-5 orange">Odemkněte si přístup ke&nbsp;všem
                    funkcím&nbsp;aplikace&nbsp;i&nbsp;na&nbsp;webu</p>
                <ul style={{listStyle: "none"}} className="mb-4">
                    <li className="mb-1"><img src={p1} className="vatt mr-3" alt="Včelstva"/>Zadávání stanovišt, včelstev a jejich
                        editace
                    </li>
                    <li className="mb-1"><img src={p2} className="vatt mr-3" alt="Prohlídky"/>Vkládání záznamů prohlídek, jejich úpravy,
                        mazání
                    </li>
                    <li className="mb-1"><img src={p3} className="vatt mr-3" alt="Produkce"/>Evidence produkce</li>
                    <li className="mb-1"><img src={p4} className="vatt mr-3" alt="Exporty"/>Možnost exportu záznamů do PDF</li>
                </ul>
                <p className="mb-4"><b>399 Kč</b> (jednorázově, vč. DPH)</p>
                <form action={data.gw_url} method="post" id="gopay-payment-button">
                    <button className="button" name="pay" type="submit">Aktivovat Premium</button>
                    <script type="text/javascript" src="https://gate.gopay.cz/gp-gw/js/embed.js"></script>
                </form>
            </div>
        </div> : ""
}
