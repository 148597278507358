import {useFetch} from "../hooks/useFetch";
import {API_URL} from "../config";
import {formatDateAndTime} from "../utils/utils";
import {VscEye, VscEyeClosed} from "react-icons/vsc";


export const QueenBeeHistory = ({hive, ...props}) => {

    const {data, error} = useFetch(`${API_URL}/hives/${hive}/queen-bee-history/`);

    return data ? <div {...props}>
        <hr/>
        {data.length > 0 ? data.map((historyItem) => <div key={historyItem.created} className="box">
            <p className="title is-7 mb-1">{formatDateAndTime(historyItem.created)}</p>
            <p>{historyItem.seen ? <VscEye color="green" className="mr-3"/> : <VscEyeClosed color="red" className="mr-3"/>}Matka {historyItem.seen ? "" : "ne"}viděna</p>
        </div> ) : <i>Bez historie</i>}
    </div> : <></>
}