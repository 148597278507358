import {useParams} from "react-router-dom";
import {useEffect} from "react";
import Breadcrumb, {BreadcrumbItem} from "../components/Breadcrumb";
import {FaEdit, FaFlag, FaHome} from "react-icons/fa";
import {useFetch} from "../hooks/useFetch";
import {API_URL} from "../config";
import {LocationForm} from "../components/LocationForm";


export default function EditLocation() {

    let params = useParams();

    const {data} = useFetch(
        `${API_URL}/hive-locations/${params.hiveLocationSlug}/`
    );

    useEffect(() => {
        document.title = "Chytrý včelař | Upravit stanoviště"
    }, []);

    return <>
        <Breadcrumb>
            <BreadcrumbItem title="Stanoviště" to="/muj-denik/"><FaHome/></BreadcrumbItem>
            {data ?
                <BreadcrumbItem title={data.name} to={`/stanoviste/${data.slug}`}><FaFlag/></BreadcrumbItem> : ""
            }
            <BreadcrumbItem title="Úprava stanoviště" to="#"><FaEdit/></BreadcrumbItem>
        </Breadcrumb>
        <div className="container is-fluid mt-4">
            {data ?
            <LocationForm
                slug={data.slug}  initialValues={{
                name: data.name,
                address_line_1: data.address_line_1,
                registration_number: data.registration_number,
                established: (data.established && new Date(data.established))
                    || (data.created && new Date(data.created))
                    || new Date(),
                image: data.image_url
            }}/>
                : ""}
        </div>
        </>
}