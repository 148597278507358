import ph from '../img/ph-1.svg';
import main from '../img/main.jpg';
import stats from '../img/stats.jpg';
import vcelkulacka from '../img/vcelkulacka.png';
import screen1 from '../img/web-s2.png';
import screen2 from '../img/web-s1.png';
import benefit1 from '../img/benefit1.svg';
import benefit2 from '../img/benefit2.svg';
import benefit3 from '../img/benefit3.svg';
import premium from '../img/premium.svg';
import appStoreBtn from '../img/app-store-btn.svg';
import googlePlayBtn from '../img/google-play-btn.svg';
import {ReactComponent as Landscape0} from '../img/landscape0.svg';
import {ReactComponent as Landscape1} from '../img/landscape1.svg';
import {ReactComponent as Landscape2} from '../img/landscape2.svg';
import {ReactComponent as Landscape3} from '../img/landscape3.svg';

import {ReactComponent as Beekeeper} from '../img/beekeeper.svg';
import * as Yup from "yup";
import {API_URL} from "../config";
import {Field, Form, Formik} from "formik";
import {TextField} from "../components/TextField";
import {TextAreaField} from "../components/TextAreaField";
import {postFormDataWithoutAuthorization} from "../utils/postFormData";
import {FaChevronRight, FaFacebook} from "react-icons/fa";
import {Link} from "react-router-dom";
import {useFetch} from "../hooks/useFetch";
import {Parallax, ParallaxProvider, useParallaxController} from "react-scroll-parallax";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import {useEffect, useState} from "react";

const mainStyle = {
    backgroundSize: "cover",
    backgroundImage: `url(${main})`,
    backgroundRepeat: "no-repeat"
}

const statsStyle = {
    backgroundSize: "cover",
    backgroundImage: `url(${stats})`,
    backgroundRepeat: "no-repeat"
}

const MainHero = () => {
    return <section className="main hero is-primary home" style={mainStyle}>
        <div className="hero-body has-text-centered" style={{margin: "5rem 0"}}>
            <Parallax speed={2}>
                <h1 className="has-text-shadow">
                    Chytrý včelař
                </h1>
            </Parallax>
            <Parallax speed={3}>
                <h2 className="mt-3 has-text-shadow is-white">
                    Úlový zápisník v mobilní aplikaci
                </h2>
            </Parallax>
            <Parallax speed={4}>
                <div className="columns mt-6">
                    <div className="column"/>
                    <div className="column">
                        <a href="https://apps.apple.com/app/id1559735294" target="_blank">
                            <button className="button is-a" style={{width: "18rem", height: "auto"}}><img
                                src={appStoreBtn}/>
                            </button>
                        </a>
                    </div>
                    <div className="column">
                        <a href="https://play.google.com/store/apps/details?id=com.punkhive.beentry" target="_blank">
                            <button className="button is-a" style={{width: "18rem", height: "auto"}}><img
                                src={googlePlayBtn}/>
                            </button>
                        </a>
                    </div>
                    <div className="column"/>
                </div>
            </Parallax>
        </div>
    </section>
}

const BenefitColumn = ({image, title, children}) => {
    return <div className="column ml-5 mr-5">
        <img src={image} alt={title} className="mb-3"/>
        <h3 className="mb-4">{title}</h3>
        <p className="mb-5">{children}</p>
    </div>
}

const Benefits = () => {
    return <section className="hero is-halfheight home">
        <div className="hero-body ml-6 mr-6">
            <div className="columns">
                <BenefitColumn image={benefit1} title="Vše na jednom místě">V prostředí moderní aplikace můžete
                    přehledně spravovat všechny záznamy o svých včelstvech.</BenefitColumn>
                <BenefitColumn image={benefit2} title="Jednoduché zadávání">Díky mobilní aplikaci vytvoříte snadno
                    přímo
                    v terénu záznamy o počtu včelstev jejich stavu, ošetření a léčení.</BenefitColumn>
                <BenefitColumn image={benefit3} title="V mobilu i na PC">Můžete vytvářet záznamy v mobilu přimo u včel a
                    pak je procházet a analyzovat i doma na počítači ve webové verzi.</BenefitColumn>
            </div>
        </div>
    </section>
}

const AppPreview = () => {
    return <section className="hero home" style={{backgroundColor: "#69C6FF"}}>
        <div className="hero-body ml-6 mr-6 has-text-black">
            <div className="columns">
                <div className="column ml-5">
                    <h2>Včelařské zápisy ve vaší kapse a zdarma</h2>
                    <hr style={{backgroundColor: "white"}}/>
                    <h3 className="medium">Konec nepřehledným papírovým záznamům. Praktické funkce a intuitivní ovládání
                        vám usnadní vaši
                        práci nejen v terénu. Zdarma získáte přehled o vašich včelách, ať jste kdekoli.
                    </h3>
                </div>

                <div className="column">
                    <div style={{position: "relative", width: "100%", height: "100%", minHeight: "17rem"}}>
                        <Parallax speed={-3}>
                            <img src={screen1} alt="Ukázka 1"
                                 style={{width: "46%", left: "40%", top: "10%", position: "absolute"}}/>
                        </Parallax>
                        <Parallax speed={-1}>
                            <img src={screen2} alt="Ukázka 2" style={{width: "50%", position: "absolute"}}/>
                        </Parallax>
                    </div>
                </div>
            </div>
        </div>
        <div style={{position: "static"}}>
            <Parallax speed={-1}>
                <Landscape1 style={{width: "100%", height: "auto", position: "absolute"}}/>
            </Parallax>
            <Parallax speed={-3}>
                <Landscape2 style={{width: "100%", height: "auto", position: "absolute"}}/>
            </Parallax>
            <Parallax speed={-5}>
                <Landscape3 style={{width: "100%", height: "auto", position: "absolute"}}/>
            </Parallax>
        </div>
        <Landscape0 style={{width: "100%", height: "auto", opacity: "0"}}/>
    </section>
}

const WhyPremium = () => {
    return <section className="hero home has-text-centered mt-5">
        {/*<Parallax speed={-4}>*/}
        <div className="hero-body ml-6 mr-6">
            <img src={premium} alt="Proč Prémiový účet?" className="is-128x128 mb-3"/>
            <h2 className="has-text-black has-text-centered">
                Chytrý včelař Premium
            </h2>
            <hr className="mt-5 mb-5" style={{margin: "auto"}}/>
            <div className="columns">
                <div className="column"/>
                <div className="column is-7">
                    <h3 className="light">Využijte Chytrého Včelaře na maximum a zároveň podpořte jeho další vývoj a
                        údřžbu. Získejte
                        možnost upravovat a zadávat své záznamy nejen v mobilní aplikaci, ale i ve webovém prohlížeči. A
                        nebude vás to stát víc než dvě sklenice medu... </h3>
                    <Link to="/premium/" onClick={() => window.scrollTo(0, 0)}
                          className="button is-normal mt-5">Více</Link>
                </div>
                <div className="column"/>
            </div>
        </div>
        {/*</Parallax>*/}
    </section>
}

const About = () => {
    return <section className="hero home is-primary pt-6">
        <div className="hero-body ml-6 mr-6">
            <div className="columns">
                <div className="column ml-5">
                    {/*<Parallax speed={-8}>*/}
                    <Beekeeper style={{width: "90%", height: "auto"}}/>
                    {/*</Parallax>*/}
                </div>
                <div className="column mr-5">
                    {/*<Parallax speed={8}>*/}
                    <h2 className="has-text-black">
                        O projektu
                    </h2>
                    <p className="has-text-black mt-4">
                        Chytrého včelaře vyvíjíme v malém týmu a někteří z nás sami dělí svůj čas mezi práci, rodinu
                        a
                        včelaření. I proto se snažíme vytvořit užitečný nástroj, který pomůže a ušetří čas.</p>
                    <p className="has-text-black mt-4"> Rádi bychom náš projekt dále posouvali a nabídli tak
                        uživatelům co nejkomplexnějšího pomocníka pro moderní včelaření. Nejen pouhou evidenci
                        návštěv, ale například plánování práce ve včelách, zapojení zařízení sledujících podmínky v
                        úlu nebo třeba “průvodce”, který by nabídnul pomoc začínajícím včelařům.</p>
                    <p className="has-text-black mt-4"> Sami nejlépe víme, že jsme na začátku dlouhé cesty, ale
                        věříme,
                        že nám pomůžete i vy. Uvítáme každou zpětnou vazbu, protože jen tak se můžeme dál
                        rozvíjet.</p>
                    {/*</Parallax>*/}
                </div>
            </div>
        </div>
    </section>
}

const ContactForm = () => {
    return <section className="hero home">
        <div className="hero-body ml-6 mr-6">
            <div className="columns">
                <div className="column"/>
                <div className="column is-8">
                    <Formik
                        initialValues={{
                            "agree2": true,
                            "agree": false
                        }}
                        validationSchema={Yup.object({
                            message: Yup.string()
                                .max(3000, 'Zpráva nesmí obsahovat více než 3000 znaků')
                                .required('Povinný údaj'),
                            email: Yup.string().email('Neplatná adresa').required('Povinný údaj')
                        })}
                        onSubmit={(values, {setSubmitting, setFieldError, setStatus}) => {
                            setStatus(0);
                            postFormDataWithoutAuthorization(`${API_URL}/send-message/`, values).then(data => {
                                setStatus(100);
                                setSubmitting(false);
                            }).catch(function (err) {
                                for (const key of Object.keys(err)) {
                                    setFieldError(key, err[key]);
                                }
                                setSubmitting(false);
                            });
                        }}
                    >
                        {props => (
                            props.status == 100 ? <><h3 className="has-text-black has-text-centered">
                                    Děkujeme
                                </h3><p className="mt-5 mb-5 has-text-centered">Zpráva byla úspěšně odeslána.</p></> :
                                <Form>
                                    <h3 className="has-text-black has-text-centered">
                                        Máte nějaký návrh, nápad nebo jste narazili
                                        na&nbsp;chybu? Dejte nám vědět!
                                    </h3>
                                    <p className="mt-5 mb-5 has-text-centered">Budeme rádi za jakoukoliv zpětnou
                                        vazbu.</p>
                                    <TextField disabled={props.isSubmitting} name="email" label="Email"/>
                                    <TextAreaField disabled={props.isSubmitting} name="message" label="Text zprávy"/>
                                    <div style={{opacity: "0%"}}>
                                        <Field type="checkbox" name="agree"/>
                                        I agree to the <a href="#">terms and conditions</a>
                                    </div>
                                    <div style={{opacity: "0%"}}>
                                        <Field type="checkbox" name="agree2"/>
                                        I agree to the <a href="#">terms and conditions</a>
                                    </div>
                                    <div className="has-text-centered mt-4">
                                        <button type="submit" className="button"
                                                disabled={((!(props.isValid && props.dirty)))}>Odeslat
                                        </button>
                                    </div>
                                </Form>)}
                    </Formik>
                </div>
                <div className="column"/>
            </div>
        </div>
    </section>
}

const StatsColumn = ({title, value, duration = 2}) => {
    const [viewPortEntered, setViewPortEntered] = useState(false);

    return <div className="column has-text-centered">
        <p className="merri stats has-text-shadow" style={{fontSize: "2.5rem"}}>
            {value ?
                <CountUp end={value} separator=" " suffix=" +" duration={duration}>
                    {({countUpRef, start}) => (
                        <VisibilitySensor onChange={isVisible => {
                            if (isVisible) {
                                if (!viewPortEntered) {
                                    start();
                                }
                                setViewPortEntered(true);
                            }
                        }}
                                          delayedCall>
                            <span ref={countUpRef}/>
                        </VisibilitySensor>
                    )}
                </CountUp> : <>&nbsp;</>}
        </p>
        <p className="has-text-shadow"><b>{title}</b></p>
    </div>
}

const Stats = () => {

    const {data} = useFetch(
        `${API_URL}/stats/`, false
    );

    return <section className="hero home is-primary" style={statsStyle}>
        <Parallax speed={-2}>
            <div className="hero-body ml-6 mr-6">
                <h2 className="has-text-centered has-text-shadow is-white">
                    Chytrý včelař
                </h2>
                <hr className="mt-5 mb-5" style={{backgroundColor: "#FFD180", margin: "auto"}}/>
                <div className="columns">
                    {data ? <>
                        <StatsColumn title="včelařů" value={data.users} duration={1}/>
                        <StatsColumn title="úlů" value={data.hives} duration={2}/>
                        <StatsColumn title="prohlídek" value={data.entries} duration={3}/>
                    </> : <><StatsColumn title="&nbsp;" value=""/>
                        <StatsColumn title="&nbsp;" value=""/>
                        <StatsColumn title="&nbsp;" value=""/></>
                    }
                </div>
            </div>
        </Parallax>
    </section>
}

const Vcelkulacka = () => {
    return <div className="columns home pb-3 pt-3" style={{backgroundColor: "#F6F6F6"}}>
        <div className="column"/>
        <div className="column is-9 ml-2 mr-2 has-text-centered-mobile" style={{backgroundColor: "#F6F6F6"}}>
            <Link to="/vcelkulacka" className="link" onClick={() => window.scrollTo(0, 0)}>
                <img src={vcelkulacka} className="is-hidden-tablet is-48x48 pr-3" alt="Včelkulačka"/>
                <img src={vcelkulacka} className="is-hidden-mobile is-48x48 pr-3" alt="Včelkulačka"
                     style={{float: "left"}}/>
                <h3 className="light">Vyzkoušejte také <b>Včelkulačku</b></h3>
                <p className="small">Včelařská kalkulačka usnadní krmení či léčení organickými kyselinami</p>
            </Link>
        </div>
        <div className="column is-1 has-text-right has-text-centered-mobile pt-6" style={{backgroundColor: "#F6F6F6"}}>
            <Link to="/vcelkulacka" className="button is-a" onClick={() => window.scrollTo(0, 0)}>Více <FaChevronRight/></Link>
        </div>
        <div className="column"/>
    </div>
}

export const BottomMenu = () => {
    return <div style={{backgroundColor: "#DAE8EB"}}>
        <div className="columns pt-2 pb-4">
            <div className="column is-1"></div>
            <div className="column has-text-centered-mobile">
                <a href="https://www.facebook.com/Chytryvcelar"><FaFacebook className="vatt mr-1"/> Chytrý včelař na FB</a>
            </div>
            <div className="column has-text-centered-mobile has-text-right">
                <Link to="/" onClick={() => window.scrollTo(0, 0)}>Úvod</Link>
                {/*&nbsp;|&nbsp;*/}
                {/*Ceník*/}
                &nbsp;|&nbsp;
                <Link to="/terms/" onClick={() => window.scrollTo(0, 0)}>Podmínky služby</Link>
                &nbsp;|&nbsp;
                <Link to="/privacy-policy/" onClick={() => window.scrollTo(0, 0)}>Ochrana soukromí</Link>
            </div>
            <div className="column is-1"></div>
        </div>
    </div>
}

export const Footer = () => {
    return <footer className="footer" style={{backgroundColor: "black"}}>
        <div className="columns pt-4">
            <div className="column is-1"></div>
            <div className="column has-text-centered-mobile">
                <p><span className="cryptedmail"
                         data-name="punkhive"
                         data-domain="gmail"
                         data-tld="com"></span></p>
            </div>
            <div className="column has-text-centered-mobile has-text-right">
                <p>Copyright © 2024 Chytrý&nbsp;včelař od PunkHive</p>
            </div>
            <div className="column is-1"></div>
        </div>
        <div className="has-text-centered"><img src={ph} alt="PunkHive"/></div>
    </footer>
}


export default function Home() {
    const parallaxController = useParallaxController();
    useEffect(() => {
        parallaxController.update();
    }, [])

    return (
        <>
            <MainHero/>
            <Benefits/>
            <AppPreview/>
            <WhyPremium/>
            <About/>
            <ContactForm/>
            <Stats/>
            <Vcelkulacka/>
            <BottomMenu/>
            <Footer/>
        </>
    )
        ;
}
