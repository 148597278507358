import {AiOutlineDelete, AiOutlineEdit,} from "react-icons/ai";
import {FaBook, FaChevronLeft, FaChevronRight,} from "react-icons/fa";
import {HiPlus} from "react-icons/hi";
import {FiCalendar} from "react-icons/fi"
import {useFetch} from "../hooks/useFetch";
import {API_URL} from "../config";
import {formatDate, formatDateAndTime, formatDateForApi, formatTime} from "../utils/utils";
import {useEffect, useState} from "react";
import Gallery from "./Gallery";
import {Link} from "react-router-dom";
import {TagsInCategories, TagsInGroups} from "../utils/WithTags";
import {ConfirmDialog} from "./ConfirmDialog";
import {deleteData} from "../utils/deleteData";
import {store} from "react-notifications-component";
import Loader from "./Loader";
import {useProfile} from "../hooks/useProfile";
import {usePosition} from "../index";
import {Filters} from "./Filters";

function VisitNote({note, onDelete, page = 1, isPremium = false}) {

    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);
    const deleteNote = () => {
        deleteData(`${API_URL}/notes/${note.id}/`).then(data => {
            store.addNotification({
                title: "Poznámka smazána.",
                message: <></>,
                type: "danger",
                insert: "top",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                slidingExit: {
                    duration: 0,
                    timingFunction: 'ease-out',
                    delay: 0
                },
                dismiss: {
                    duration: 5000,
                    pauseOnHover: true
                }
            });
            onDelete();
        }).catch(function (err) {
            // TODO: Error
        });
    }

    const {setPosition, position, pageForPosition} = usePosition()

    const onEditLinkClick = () => {
        setPosition({...position, [pageForPosition]: [page, window.scrollY]});
    }

    return <>
        <div className="panel-block note" style={{borderColor: "whiteSmoke"}}>
            <div className="container">
                <p className="mb-1 mt-2">{formatTime(note.created)}
                    {isPremium &&
                    <span className="note-hidden">
                         <button
                             onClick={() => setShowDeleteConfirmationDialog(true)}
                             className="button is-small-ico is-danger is-pulled-right ml-2"><AiOutlineDelete/>
                            </button>
                        <Link to={`/poznamka/${note.id}/upravit/`}
                              onClick={onEditLinkClick}
                              className="button is-small-ico is-dark is-pulled-right ml-2"><AiOutlineEdit/></Link>
                    </span>
                    }
                </p>
                <ConfirmDialog question={<>Opravdu si přejete vymazat poznámku?</>}
                               isActive={showDeleteConfirmationDialog}
                               onClose={() => setShowDeleteConfirmationDialog(false)}
                               onConfirm={deleteNote}
                />
                <TagsInGroups data={note.tags} showNames={false}></TagsInGroups>
                {note.text ?
                    <article className="message is-warning mb-3 mt-3">
                        <div className="message-body" style={{whiteSpace: "pre-wrap"}}>{note.text}</div>
                    </article> : ""}
                {note.images && note.images.length > 0 ? <Gallery data={note.images}/> : ""}
            </div>
        </div>
    </>
}

export function Entry({
                          entry,
                          onDelete,
                          page = 1,
                          includeDate = false,
                          useDetailedEditLink = false,
                          isPremium = false
                      }) {

    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);
    const deleteEntry = () => {
        deleteData(`${API_URL}/entries/${entry.id}/`).then(data => {
            store.addNotification({
                title: "Prohlídka úlu smazána.",
                message: <></>,
                type: "danger",
                insert: "top",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                slidingExit: {
                    duration: 0,
                    timingFunction: 'ease-out',
                    delay: 0
                },
                dismiss: {
                    duration: 5000,
                    pauseOnHover: true
                }
            });
            onDelete();
        }).catch(function (err) {
            // TODO: Error
        });
    }

    const {setPosition, pageForPosition, position} = usePosition()

    const onEditLinkClick = () => {
        setPosition({...position, [pageForPosition]: [page, window.scrollY]});
    }

    return <>
        <div className="panel-block entry" style={{borderColor: "whiteSmoke"}}>
            <div className="container">
                <p>{includeDate ? formatDateAndTime(entry.created) : formatTime(entry.created)}
                    {isPremium &&
                    <span className="entry-hidden">
                        <button
                            onClick={() => setShowDeleteConfirmationDialog(true)}
                            className="button is-small-ico is-danger is-pulled-right ml-2"><AiOutlineDelete/>
                            </button>
                        {useDetailedEditLink ?
                            <Link to={`/ul/${entry.hive_slug}/prohlidka/${entry.id}/upravit/`}
                                  onClick={onEditLinkClick}
                                  className="button is-small-ico is-dark is-pulled-right ml-2"><AiOutlineEdit/></Link>
                            :
                            <Link to={`/prohlidka/${entry.id}/upravit/`}
                                  onClick={onEditLinkClick}
                                  className="button is-small-ico is-dark is-pulled-right ml-2"><AiOutlineEdit/></Link>
                        }
                    </span>
                    }
                </p>
                <ConfirmDialog question={<>Opravdu si přejete vymazat prohlídku úlu?</>}
                               isActive={showDeleteConfirmationDialog}
                               onClose={() => setShowDeleteConfirmationDialog(false)}
                               onConfirm={deleteEntry}
                />
                <div className="media mt-5 mb-3">
                    <div className="media-left">
                        <figure
                            className={`image is-20x20 has-rounded-corners has-shadow${entry.hive_color ? "" : " has-stripes"}`}
                            style={{backgroundColor: entry.hive_color}}
                        />
                    </div>
                    <div className="media-content">
                        <p className="title is-6">{entry.hive_name}</p>
                    </div>
                </div>
                {entry.text ? <article className="message is-light mb-5 mt-5">
                    <div className="message-body" style={{whiteSpace: "pre-wrap"}}>{entry.text}</div>
                </article> : ""}
                <TagsInCategories data={entry.tags} showNames={true}></TagsInCategories>
                {entry.images && entry.images.length > 0 ? <Gallery data={entry.images}/> : ""}
            </div>
        </div>
    </>
}

function VisitCard({visit, onChange, page, isPremium = false}) {
    return <div className="panel">
        <div className="panel-block">
            <div className="container">
                <p className="title is-6">
                    <FiCalendar className="mr-2"/>{formatDate(visit.created)}</p>
            </div>
        </div>
        {visit.notes.map(note => <VisitNote key={note.id} note={note} page={page} onDelete={onChange}
                                            isPremium={isPremium}/>)}
        {visit.entries.map(entry => <Entry key={entry.id} entry={entry} page={page} onDelete={onChange}
                                           isPremium={isPremium}/>)}
    </div>
}

export default function Visits({locationId, locationSlug}) {
    const {setPosition, position, pageForPosition} = usePosition();
    const [apiUrl, setApiUrl] = useState(`${API_URL}/visits/?location=${locationId}&page=${position[pageForPosition][0]}&page_size=5`)
    const [page, setPage] = useState(position[pageForPosition][0])

    const {data, loading} = useFetch(
        apiUrl
    );

    useEffect(() => {
        if (data) {
            window.scrollTo(0, position[pageForPosition][1]);
        }
    })

    const [change, setChange] = useState(0);
    const onChange = () => {
        setChange(old => old + 1)
        setApiUrl(`${API_URL}/visits/?location=${locationId}&page_size=5&page=${page}&ch=${change}`)
    }
    const {isPremium, isProfileDataReady} = useProfile();

    const onFiltersChanged = (filters) => {
        setPosition({...position, [pageForPosition]: [page, window.scrollY]});
        setChange(old => old + 1)
        let from = filters.from ? `&from=${formatDateForApi(filters.from)}` : ""
        let to = filters.to ? `&to=${formatDateForApi(filters.to)}` : ""
        let hive = filters.hive !== "__all__" ? `&hives=${filters.hive}` : ""
        let tags = filters.tags ? filters.tags.reduce((result, tag) => `${result}&tags=${tag}`, "") : ""
        setApiUrl(`${API_URL}/visits/?location=${locationId}${from}${to}${hive}${tags}&page_size=5&page=${1}&ch=${change}`)
    }

    return <>
        <div className="panel">
            <div className={`panel-heading is-size-6 ${isPremium ? "pb-5" : ""}`}>
                <FaBook className="mr-2"/>
                {data ?
                    <>Záznamy {data ? `(${data.count})` : ""}</>
                    : <>Záznamy se načítají...</>}
                {isPremium &&
                <Link to={`/stanoviste/${locationSlug}/novy-zaznam/`} className="button is-pulled-right"><HiPlus
                    className="mr-3"/>Nový záznam</Link>
                }
            </div>
        </div>

        {isProfileDataReady &&
        <div className="panel">
            <Filters locationId={locationId} onChanged={onFiltersChanged} isPremium={isPremium}/>
        </div>}

        {loading ? <Loader/> :

            (data && data.count > 0) ?
                <>
                    {data.results.map(visit => <VisitCard page={page} key={visit.id} visit={visit} isPremium={isPremium}
                                                          onChange={onChange}/>)}
                    {
                        (data.next || data.previous) ?
                            <div className="panel">
                                <div className="panel-block">
                                    <nav className="pagination is-right" style={{width: "100%"}}>
                                        <a href={data.previous && "#/"}
                                           onClick={(e) => {
                                               e.preventDefault()
                                               setPage(oldPage => oldPage - 1)
                                               setApiUrl(data.previous);
                                               window.scrollTo(0, 0);
                                               setPosition({...position, [pageForPosition]: [page, 0]});
                                           }}
                                           className="pagination-previous" disabled={!data.previous}><FaChevronLeft
                                            className="mr-3"/>Novější
                                        </a>
                                        <span style={{order: 1}}>
                                          {`strana ${page} / ${Math.floor(data.count / 5) + 1}`}
                                        </span>
                                        <a href={data.next && "#/"}
                                           onClick={(e) => {
                                               e.preventDefault();
                                               setPage(oldPage => oldPage + 1)
                                               setApiUrl(data.next)
                                               window.scrollTo(0, 0);
                                               setPosition({...position, [pageForPosition]: [page, 0]});
                                           }}
                                           className="pagination-next" disabled={!data.next}>Starší<FaChevronRight
                                            className="ml-3"/></a>
                                    </nav>
                                </div>
                            </div>
                            : ""
                    }
                </>
                :
                (data && data.count <= 0) ?
                    <div className="panel-block">Nebyly nalezeny žádné záznamy.</div>
                    : ""
        }
    </>
}
