import {Link, useHistory, useParams} from "react-router-dom";
import {useFetch} from "../hooks/useFetch";
import {API_URL} from "../config";
import {useEffect, useState} from "react";
import Breadcrumb, {BreadcrumbItem} from "../components/Breadcrumb";
import {FaFlag, FaHome} from "react-icons/fa";
import {HiveLocationCard} from "../components/HiveLocationCard";
import {HivesList} from "../components/Hive";
import Visits from "../components/Visits";
import {store} from "react-notifications-component";
import {deleteData} from "../utils/deleteData";
import {ConfirmDialog} from "../components/ConfirmDialog";
import Error from "../components/Error";
import Loader from "../components/Loader";
import {useProfile} from "../hooks/useProfile";
import {usePosition} from "../index";

export default function HiveLocation() {
    let params = useParams();

    const {data, loading, error} = useFetch(
        `${API_URL}/hive-locations/${params.hiveLocationSlug}/`
    );

    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);

    const {setPageForPosition, position, setPosition} = usePosition()

    useEffect(() => {
        document.title = "Chytrý včelař | Stanoviště"
        setPageForPosition("HiveLocation");
        setPosition({...position, ["Hive"]: [1, 0]});
    }, []);

    let history = useHistory();
    const {isPremium} = useProfile();

    const deleteLocation = () => {
        const name = data.name;
        deleteData(`${API_URL}/hive-locations/${data.slug}/`).then(data => {
            store.addNotification({
                title: "Stanoviště smazáno",
                message: <>Stanoviště <b>{name}</b> bylo smazáno.</>,
                type: "danger",
                insert: "top",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                slidingExit: {
                    duration: 0,
                    timingFunction: 'ease-out',
                    delay: 0
                },
                dismiss: {
                    duration: 5000,
                    pauseOnHover: true
                }
            });
            history.replace({pathname: "/muj-denik/"})
        }).catch(function (err) {
            // TODO: Error
        });
    }

    if (loading) {
        return <Loader/>
    }

    if (error) {
        return <Error/>
    }

    return (
        <>
            <Breadcrumb>
                <BreadcrumbItem title="Stanoviště" to="/muj-denik/"><FaHome/></BreadcrumbItem>
                {data ?
                    <BreadcrumbItem title={data.name} to="#"><FaFlag/></BreadcrumbItem> : ""
                }
            </Breadcrumb>

            {data ?
                <div className="container is-fluid mt-4">
                    <div className="columns ">
                        <div className="column">
                            <h1 className="title is-4">{data.name}</h1>
                        </div>
                        <div className="column has-text-right">
                            {isPremium &&
                            <Link to={`/stanoviste/${data.slug}/upravit/`}
                                  className="button is-dark mr-2">
                                Upravit
                            </Link>
                            }
                            {isPremium &&
                            <button onClick={() => setShowDeleteConfirmationDialog(true)}
                                    className="button is-danger">Odstranit
                            </button>}
                            <ConfirmDialog question={<>Opravdu si přejete vymazat stanoviště <b>{data.name}</b>?</>}
                                           isActive={showDeleteConfirmationDialog}
                                           onClose={() => setShowDeleteConfirmationDialog(false)}
                                           onConfirm={deleteLocation}
                            />
                        </div>
                    </div>
                    <hr className="mt-0"/>
                    <div className="columns">
                        <div className="column is-4">
                            <HiveLocationCard location={data}/>
                            <HivesList locationSlug={data.slug}/>
                        </div>
                        <div className="column">
                            <Visits locationId={data.id} locationSlug={data.slug}/>
                        </div>
                    </div>
                </div>
                : ""}
        </>);
}