import {useContext} from "react";
import {TagsContext} from "../App";
import {FaCloud, FaCloudSun, FaSun, FaThermometerHalf, FaWind} from "react-icons/fa";

export function addUnit(value, unit, unit_plural_2, unit_plural_5, unit_plural_0) {
    let showValue = true
    value = Math.abs(Math.round(value * 10) / 10)
    if (unit_plural_0 && (unit_plural_0.startsWith("žád") || unit_plural_0.startsWith("nic")) && value == 0) {
        showValue = false
    }
    return `${(value && showValue) ? `${value} ` : ""}${intUnit(value, unit, unit_plural_2, unit_plural_5, unit_plural_0)}`
}

export function intUnit(value, unit, unit_plural_2, unit_plural_5, unit_plural_0) {
    if (value === 0 && unit_plural_0) {
        return unit_plural_0
    }
    if (value >= 2 && value <= 4 && unit_plural_2) {
        return unit_plural_2
    }
    if (value >= 5 && unit_plural_5) {
        return unit_plural_5
    }
    return unit
}


export function WithTags({children}) {
    // const {data} = useFetch(
    //      `${API_URL}/available-entry-tags/`
    // );

    const data = [
        {
            "id": 7,
            "group": {
                "id": 2,
                "title": "Vnější prohlídka",
                "order": 0,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/c92f408f9ea610f9318064b375fa62e1247579cb/51597f377617a37ece0eebd5ac3f035e.png"
            },
            "category": {
                "id": 1,
                "title": "Česno",
                "order": 0
            },
            "has_value": false,
            "title": "Nosí pyl"
        },
        {
            "id": 8,
            "group": {
                "id": 2,
                "title": "Vnější prohlídka",
                "order": 0,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/c92f408f9ea610f9318064b375fa62e1247579cb/51597f377617a37ece0eebd5ac3f035e.png"
            },
            "category": {
                "id": 1,
                "title": "Česno",
                "order": 0
            },
            "has_value": false,
            "title": "Nosí nektar"
        },
        {
            "id": 9,
            "group": {
                "id": 2,
                "title": "Vnější prohlídka",
                "order": 0,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/c92f408f9ea610f9318064b375fa62e1247579cb/51597f377617a37ece0eebd5ac3f035e.png"
            },
            "category": {
                "id": 1,
                "title": "Česno",
                "order": 0
            },
            "has_value": false,
            "title": "Větrání"
        },
        {
            "id": 10,
            "group": {
                "id": 2,
                "title": "Vnější prohlídka",
                "order": 0,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/c92f408f9ea610f9318064b375fa62e1247579cb/51597f377617a37ece0eebd5ac3f035e.png"
            },
            "category": {
                "id": 1,
                "title": "Česno",
                "order": 0
            },
            "has_value": false,
            "title": "Šíření feromonu"
        },
        {
            "id": 11,
            "group": {
                "id": 2,
                "title": "Vnější prohlídka",
                "order": 0,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/c92f408f9ea610f9318064b375fa62e1247579cb/51597f377617a37ece0eebd5ac3f035e.png"
            },
            "category": {
                "id": 1,
                "title": "Česno",
                "order": 0
            },
            "has_value": false,
            "title": "Loupež"
        },
        {
            "id": 12,
            "group": {
                "id": 2,
                "title": "Vnější prohlídka",
                "order": 0,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/c92f408f9ea610f9318064b375fa62e1247579cb/51597f377617a37ece0eebd5ac3f035e.png"
            },
            "category": {
                "id": 1,
                "title": "Česno",
                "order": 0
            },
            "has_value": false,
            "title": "Rojení"
        },
        {
            "id": 13,
            "group": {
                "id": 2,
                "title": "Vnější prohlídka",
                "order": 0,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/c92f408f9ea610f9318064b375fa62e1247579cb/51597f377617a37ece0eebd5ac3f035e.png"
            },
            "category": {
                "id": 1,
                "title": "Česno",
                "order": 0
            },
            "has_value": false,
            "title": "Prolety mladušek"
        },
        {
            "id": 14,
            "group": {
                "id": 2,
                "title": "Vnější prohlídka",
                "order": 0,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/c92f408f9ea610f9318064b375fa62e1247579cb/51597f377617a37ece0eebd5ac3f035e.png"
            },
            "category": {
                "id": 1,
                "title": "Česno",
                "order": 0
            },
            "has_value": false,
            "title": "Zimní prolety"
        },
        {
            "id": 15,
            "group": {
                "id": 2,
                "title": "Vnější prohlídka",
                "order": 0,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/c92f408f9ea610f9318064b375fa62e1247579cb/51597f377617a37ece0eebd5ac3f035e.png"
            },
            "category": {
                "id": 1,
                "title": "Česno",
                "order": 0
            },
            "has_value": false,
            "title": "Bez aktivity"
        },
        {
            "id": 16,
            "group": {
                "id": 2,
                "title": "Vnější prohlídka",
                "order": 0,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/c92f408f9ea610f9318064b375fa62e1247579cb/51597f377617a37ece0eebd5ac3f035e.png"
            },
            "category": {
                "id": 2,
                "title": "Podmet",
                "order": 1
            },
            "has_value": false,
            "title": "Vyvěšují se"
        },
        {
            "id": 17,
            "group": {
                "id": 2,
                "title": "Vnější prohlídka",
                "order": 0,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/c92f408f9ea610f9318064b375fa62e1247579cb/51597f377617a37ece0eebd5ac3f035e.png"
            },
            "category": {
                "id": 2,
                "title": "Podmet",
                "order": 1
            },
            "has_value": false,
            "title": "Staví v podmetu"
        },
        {
            "id": 18,
            "group": {
                "id": 2,
                "title": "Vnější prohlídka",
                "order": 0,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/c92f408f9ea610f9318064b375fa62e1247579cb/51597f377617a37ece0eebd5ac3f035e.png"
            },
            "category": {
                "id": 2,
                "title": "Podmet",
                "order": 1
            },
            "has_value": true,
            "unit": "ks",
            "unit_plural_2": "ks",
            "unit_plural_5": "ks",
            "unit_plural_0": "žádný",
            "title": "Spad mrtvolek"
        },
        {
            "id": 19,
            "group": {
                "id": 2,
                "title": "Vnější prohlídka",
                "order": 0,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/c92f408f9ea610f9318064b375fa62e1247579cb/51597f377617a37ece0eebd5ac3f035e.png"
            },
            "category": {
                "id": 2,
                "title": "Podmet",
                "order": 1
            },
            "has_value": false,
            "title": "Bez aktivity"
        },
        {
            "id": 20,
            "group": {
                "id": 2,
                "title": "Vnější prohlídka",
                "order": 0,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/c92f408f9ea610f9318064b375fa62e1247579cb/51597f377617a37ece0eebd5ac3f035e.png"
            },
            "category": {
                "id": 3,
                "title": "Podložka",
                "order": 2
            },
            "has_value": true,
            "unit": "ks",
            "unit_plural_2": "ks",
            "unit_plural_5": "ks",
            "unit_plural_0": "žádný",
            "title": "Spad roztoče"
        },
        {
            "id": 21,
            "group": {
                "id": 2,
                "title": "Vnější prohlídka",
                "order": 0,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/c92f408f9ea610f9318064b375fa62e1247579cb/51597f377617a37ece0eebd5ac3f035e.png"
            },
            "category": {
                "id": 3,
                "title": "Podložka",
                "order": 2
            },
            "has_value": false,
            "title": "Pylové rousky"
        },
        {
            "id": 22,
            "group": {
                "id": 2,
                "title": "Vnější prohlídka",
                "order": 0,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/c92f408f9ea610f9318064b375fa62e1247579cb/51597f377617a37ece0eebd5ac3f035e.png"
            },
            "category": {
                "id": 3,
                "title": "Podložka",
                "order": 2
            },
            "has_value": true,
            "unit": "ks",
            "unit_plural_2": "ks",
            "unit_plural_5": "ks",
            "unit_plural_0": "nic",
            "title": "Rozkousané tělíčka, křídla, nožky"
        },
        {
            "id": 23,
            "group": {
                "id": 2,
                "title": "Vnější prohlídka",
                "order": 0,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/c92f408f9ea610f9318064b375fa62e1247579cb/51597f377617a37ece0eebd5ac3f035e.png"
            },
            "category": {
                "id": 3,
                "title": "Podložka",
                "order": 2
            },
            "has_value": true,
            "unit": "ulička",
            "unit_plural_2": "uličky",
            "unit_plural_5": "uliček",
            "unit_plural_0": "žádná",
            "title": "Počet obsazených uliček"
        },
        {
            "id": 104,
            "group": {
                "id": 2,
                "title": "Vnější prohlídka",
                "order": 0,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/c92f408f9ea610f9318064b375fa62e1247579cb/51597f377617a37ece0eebd5ac3f035e.png"
            },
            "category": {
                "id": 3,
                "title": "Podložka",
                "order": 2
            },
            "has_value": false,
            "title": "Očištění podložky"
        },
        {
            "id": 43,
            "group": {
                "id": 4,
                "title": "Stav včelstva",
                "order": 1,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/72d4cd59a6dda38573760a90b5417439356c99be/da4d456b80f9239b6aa15fac89915825.png"
            },
            "category": {
                "id": 4,
                "title": "Stav plodu",
                "order": 0
            },
            "has_value": true,
            "unit": "plást",
            "unit_plural_2": "plásty",
            "unit_plural_5": "plástů",
            "unit_plural_0": "žádný",
            "title": "Zavíčkovaný"
        },
        {
            "id": 44,
            "group": {
                "id": 4,
                "title": "Stav včelstva",
                "order": 1,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/72d4cd59a6dda38573760a90b5417439356c99be/da4d456b80f9239b6aa15fac89915825.png"
            },
            "category": {
                "id": 4,
                "title": "Stav plodu",
                "order": 0
            },
            "has_value": true,
            "unit": "plást",
            "unit_plural_2": "plásty",
            "unit_plural_5": "plástů",
            "unit_plural_0": "žádný",
            "title": "Otevřený"
        },
        {
            "id": 45,
            "group": {
                "id": 4,
                "title": "Stav včelstva",
                "order": 1,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/72d4cd59a6dda38573760a90b5417439356c99be/da4d456b80f9239b6aa15fac89915825.png"
            },
            "category": {
                "id": 4,
                "title": "Stav plodu",
                "order": 0
            },
            "has_value": false,
            "title": "Mezerovitost"
        },
        {
            "id": 47,
            "group": {
                "id": 4,
                "title": "Stav včelstva",
                "order": 1,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/72d4cd59a6dda38573760a90b5417439356c99be/da4d456b80f9239b6aa15fac89915825.png"
            },
            "category": {
                "id": 4,
                "title": "Stav plodu",
                "order": 0
            },
            "has_value": false,
            "title": "Hrboplod"
        },
        {
            "id": 67,
            "group": {
                "id": 4,
                "title": "Stav včelstva",
                "order": 1,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/72d4cd59a6dda38573760a90b5417439356c99be/da4d456b80f9239b6aa15fac89915825.png"
            },
            "category": {
                "id": 4,
                "title": "Stav plodu",
                "order": 0
            },
            "has_value": true,
            "unit": "matečník",
            "unit_plural_2": "matečníky",
            "unit_plural_5": "matečníků",
            "unit_plural_0": "žádný",
            "title": "Nouzové matečníky"
        },
        {
            "id": 68,
            "group": {
                "id": 4,
                "title": "Stav včelstva",
                "order": 1,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/72d4cd59a6dda38573760a90b5417439356c99be/da4d456b80f9239b6aa15fac89915825.png"
            },
            "category": {
                "id": 4,
                "title": "Stav plodu",
                "order": 0
            },
            "has_value": true,
            "unit": "matečník",
            "unit_plural_2": "matečníky",
            "unit_plural_5": "matečníků",
            "unit_plural_0": "žádný",
            "title": "Rojové matečníky"
        },
        {
            "id": 69,
            "group": {
                "id": 4,
                "title": "Stav včelstva",
                "order": 1,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/72d4cd59a6dda38573760a90b5417439356c99be/da4d456b80f9239b6aa15fac89915825.png"
            },
            "category": {
                "id": 4,
                "title": "Stav plodu",
                "order": 0
            },
            "has_value": true,
            "unit": "matečník",
            "unit_plural_2": "matečníky",
            "unit_plural_5": "matečníků",
            "unit_plural_0": "žádný",
            "title": "Matečníky tiché výměny"
        },
        {
            "id": 87,
            "group": {
                "id": 4,
                "title": "Stav včelstva",
                "order": 1,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/72d4cd59a6dda38573760a90b5417439356c99be/da4d456b80f9239b6aa15fac89915825.png"
            },
            "category": {
                "id": 5,
                "title": "Síla včelstva",
                "order": 1
            },
            "has_value": false,
            "title": "Velmi silné"
        },
        {
            "id": 48,
            "group": {
                "id": 4,
                "title": "Stav včelstva",
                "order": 1,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/72d4cd59a6dda38573760a90b5417439356c99be/da4d456b80f9239b6aa15fac89915825.png"
            },
            "category": {
                "id": 5,
                "title": "Síla včelstva",
                "order": 1
            },
            "has_value": false,
            "title": "Silné"
        },
        {
            "id": 88,
            "group": {
                "id": 4,
                "title": "Stav včelstva",
                "order": 1,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/72d4cd59a6dda38573760a90b5417439356c99be/da4d456b80f9239b6aa15fac89915825.png"
            },
            "category": {
                "id": 5,
                "title": "Síla včelstva",
                "order": 1
            },
            "has_value": false,
            "title": "Průměrné"
        },
        {
            "id": 49,
            "group": {
                "id": 4,
                "title": "Stav včelstva",
                "order": 1,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/72d4cd59a6dda38573760a90b5417439356c99be/da4d456b80f9239b6aa15fac89915825.png"
            },
            "category": {
                "id": 5,
                "title": "Síla včelstva",
                "order": 1
            },
            "has_value": false,
            "title": "Slabé"
        },
        {
            "id": 89,
            "group": {
                "id": 4,
                "title": "Stav včelstva",
                "order": 1,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/72d4cd59a6dda38573760a90b5417439356c99be/da4d456b80f9239b6aa15fac89915825.png"
            },
            "category": {
                "id": 5,
                "title": "Síla včelstva",
                "order": 1
            },
            "has_value": false,
            "title": "Velmi slabé"
        },
        {
            "id": 50,
            "group": {
                "id": 4,
                "title": "Stav včelstva",
                "order": 1,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/72d4cd59a6dda38573760a90b5417439356c99be/da4d456b80f9239b6aa15fac89915825.png"
            },
            "category": {
                "id": 6,
                "title": "Nálada",
                "order": 2
            },
            "has_value": false,
            "title": "Klidné"
        },
        {
            "id": 51,
            "group": {
                "id": 4,
                "title": "Stav včelstva",
                "order": 1,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/72d4cd59a6dda38573760a90b5417439356c99be/da4d456b80f9239b6aa15fac89915825.png"
            },
            "category": {
                "id": 6,
                "title": "Nálada",
                "order": 2
            },
            "has_value": false,
            "title": "Nervózní"
        },
        {
            "id": 52,
            "group": {
                "id": 4,
                "title": "Stav včelstva",
                "order": 1,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/72d4cd59a6dda38573760a90b5417439356c99be/da4d456b80f9239b6aa15fac89915825.png"
            },
            "category": {
                "id": 6,
                "title": "Nálada",
                "order": 2
            },
            "has_value": false,
            "title": "Agresivní"
        },
        {
            "id": 53,
            "group": {
                "id": 4,
                "title": "Stav včelstva",
                "order": 1,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/72d4cd59a6dda38573760a90b5417439356c99be/da4d456b80f9239b6aa15fac89915825.png"
            },
            "category": {
                "id": 7,
                "title": "Nemoc",
                "order": 3
            },
            "has_value": false,
            "title": "Mor včelího plodu"
        },
        {
            "id": 90,
            "group": {
                "id": 4,
                "title": "Stav včelstva",
                "order": 1,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/72d4cd59a6dda38573760a90b5417439356c99be/da4d456b80f9239b6aa15fac89915825.png"
            },
            "category": {
                "id": 11,
                "title": "Stav zásob",
                "order": 3
            },
            "has_value": true,
            "unit": "plást",
            "unit_plural_2": "plásty",
            "unit_plural_5": "plástů",
            "unit_plural_0": "žádný",
            "title": "Zásobních plástů"
        },
        {
            "id": 54,
            "group": {
                "id": 4,
                "title": "Stav včelstva",
                "order": 1,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/72d4cd59a6dda38573760a90b5417439356c99be/da4d456b80f9239b6aa15fac89915825.png"
            },
            "category": {
                "id": 7,
                "title": "Nemoc",
                "order": 3
            },
            "has_value": false,
            "title": "Paralýza včel"
        },
        {
            "id": 55,
            "group": {
                "id": 4,
                "title": "Stav včelstva",
                "order": 1,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/72d4cd59a6dda38573760a90b5417439356c99be/da4d456b80f9239b6aa15fac89915825.png"
            },
            "category": {
                "id": 7,
                "title": "Nemoc",
                "order": 3
            },
            "has_value": false,
            "title": "Nosematóza"
        },
        {
            "id": 56,
            "group": {
                "id": 4,
                "title": "Stav včelstva",
                "order": 1,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/72d4cd59a6dda38573760a90b5417439356c99be/da4d456b80f9239b6aa15fac89915825.png"
            },
            "category": {
                "id": 7,
                "title": "Nemoc",
                "order": 3
            },
            "has_value": false,
            "title": "Zvápenatění včelího plodu"
        },
        {
            "id": 57,
            "group": {
                "id": 4,
                "title": "Stav včelstva",
                "order": 1,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/72d4cd59a6dda38573760a90b5417439356c99be/da4d456b80f9239b6aa15fac89915825.png"
            },
            "category": {
                "id": 7,
                "title": "Nemoc",
                "order": 3
            },
            "has_value": false,
            "title": "Virus deformovaných křídel"
        },
        {
            "id": 58,
            "group": {
                "id": 4,
                "title": "Stav včelstva",
                "order": 1,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/72d4cd59a6dda38573760a90b5417439356c99be/da4d456b80f9239b6aa15fac89915825.png"
            },
            "category": {
                "id": 7,
                "title": "Nemoc",
                "order": 3
            },
            "has_value": false,
            "title": "Virus pytlíčkovitého plodu"
        },
        {
            "id": 59,
            "group": {
                "id": 4,
                "title": "Stav včelstva",
                "order": 1,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/72d4cd59a6dda38573760a90b5417439356c99be/da4d456b80f9239b6aa15fac89915825.png"
            },
            "category": {
                "id": 7,
                "title": "Nemoc",
                "order": 3
            },
            "has_value": false,
            "title": "Hniloba včelího plodu"
        },
        {
            "id": 40,
            "group": {
                "id": 4,
                "title": "Stav včelstva",
                "order": 1,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/72d4cd59a6dda38573760a90b5417439356c99be/da4d456b80f9239b6aa15fac89915825.png"
            },
            "has_value": false,
            "title": "Matka viděna"
        },
        {
            "id": 41,
            "group": {
                "id": 4,
                "title": "Stav včelstva",
                "order": 1,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/72d4cd59a6dda38573760a90b5417439356c99be/da4d456b80f9239b6aa15fac89915825.png"
            },
            "has_value": false,
            "title": "Matka neviděna"
        },
        {
            "id": 42,
            "group": {
                "id": 4,
                "title": "Stav včelstva",
                "order": 1,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/72d4cd59a6dda38573760a90b5417439356c99be/da4d456b80f9239b6aa15fac89915825.png"
            },
            "has_value": true,
            "unit": "plást",
            "unit_plural_2": "plásty",
            "unit_plural_5": "plástů",
            "unit_plural_0": "žádný",
            "title": "Nakladená vajíčka"
        },
        {
            "id": 91,
            "group": {
                "id": 4,
                "title": "Stav včelstva",
                "order": 1,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/72d4cd59a6dda38573760a90b5417439356c99be/da4d456b80f9239b6aa15fac89915825.png"
            },
            "has_value": false,
            "title": "Úhyn včelstva"
        },
        {
            "id": 92,
            "group": {
                "id": 4,
                "title": "Stav včelstva",
                "order": 1,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/72d4cd59a6dda38573760a90b5417439356c99be/da4d456b80f9239b6aa15fac89915825.png"
            },
            "has_value": false,
            "title": "Obnova uhynulého včelstva"
        },
        {
            "id": 78,
            "group": {
                "id": 7,
                "title": "Zásahy",
                "order": 2,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/923f33e4b241947cb389aa18334da2d6cb79b1a6/e5081751365511dafd96bbb3eb1762f5.png"
            },
            "category": {
                "id": 8,
                "title": "Nástavky a rámky",
                "order": 0
            },
            "has_value": true,
            "unit": "nástavek",
            "unit_plural_2": "nástavky",
            "unit_plural_5": "nástavků",
            "unit_plural_0": "žádný",
            "title": "Přidání nástavků"
        },
        {
            "id": 79,
            "group": {
                "id": 7,
                "title": "Zásahy",
                "order": 2,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/923f33e4b241947cb389aa18334da2d6cb79b1a6/e5081751365511dafd96bbb3eb1762f5.png"
            },
            "category": {
                "id": 8,
                "title": "Nástavky a rámky",
                "order": 0
            },
            "has_value": true,
            "unit": "nástavek",
            "unit_plural_2": "nástavky",
            "unit_plural_5": "nástavků",
            "unit_plural_0": "žádný",
            "title": "Odebrání nástavků"
        },
        {
            "id": 80,
            "group": {
                "id": 7,
                "title": "Zásahy",
                "order": 2,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/923f33e4b241947cb389aa18334da2d6cb79b1a6/e5081751365511dafd96bbb3eb1762f5.png"
            },
            "category": {
                "id": 8,
                "title": "Nástavky a rámky",
                "order": 0
            },
            "has_value": true,
            "unit": "mezistěna",
            "unit_plural_2": "mezistěny",
            "unit_plural_5": "mezistěn",
            "unit_plural_0": "žádná",
            "title": "Vložení mezistěn"
        },
        {
            "id": 81,
            "group": {
                "id": 7,
                "title": "Zásahy",
                "order": 2,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/923f33e4b241947cb389aa18334da2d6cb79b1a6/e5081751365511dafd96bbb3eb1762f5.png"
            },
            "category": {
                "id": 8,
                "title": "Nástavky a rámky",
                "order": 0
            },
            "has_value": true,
            "unit": "rámek",
            "unit_plural_2": "rámky",
            "unit_plural_5": "rámků",
            "unit_plural_0": "žádný",
            "title": "Vložení stavebních rámků"
        },
        {
            "id": 82,
            "group": {
                "id": 7,
                "title": "Zásahy",
                "order": 2,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/923f33e4b241947cb389aa18334da2d6cb79b1a6/e5081751365511dafd96bbb3eb1762f5.png"
            },
            "category": {
                "id": 8,
                "title": "Nástavky a rámky",
                "order": 0
            },
            "has_value": true,
            "unit": "souš",
            "unit_plural_2": "souše",
            "unit_plural_5": "souší",
            "unit_plural_0": "žádná",
            "title": "Vložení souší"
        },
        {
            "id": 83,
            "group": {
                "id": 7,
                "title": "Zásahy",
                "order": 2,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/923f33e4b241947cb389aa18334da2d6cb79b1a6/e5081751365511dafd96bbb3eb1762f5.png"
            },
            "category": {
                "id": 8,
                "title": "Nástavky a rámky",
                "order": 0
            },
            "has_value": true,
            "unit": "rámek",
            "unit_plural_2": "rámky",
            "unit_plural_5": "rámků",
            "unit_plural_0": "žádný",
            "title": "Vložení plodových rámků"
        },
        {
            "id": 84,
            "group": {
                "id": 7,
                "title": "Zásahy",
                "order": 2,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/923f33e4b241947cb389aa18334da2d6cb79b1a6/e5081751365511dafd96bbb3eb1762f5.png"
            },
            "category": {
                "id": 8,
                "title": "Nástavky a rámky",
                "order": 0
            },
            "has_value": true,
            "unit": "rámek",
            "unit_plural_2": "rámky",
            "unit_plural_5": "rámků",
            "unit_plural_0": "žádný",
            "title": "Vložení zásobních rámků"
        },
        {
            "id": 85,
            "group": {
                "id": 7,
                "title": "Zásahy",
                "order": 2,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/923f33e4b241947cb389aa18334da2d6cb79b1a6/e5081751365511dafd96bbb3eb1762f5.png"
            },
            "category": {
                "id": 8,
                "title": "Nástavky a rámky",
                "order": 0
            },
            "has_value": true,
            "unit": "rámek",
            "unit_plural_2": "rámky",
            "unit_plural_5": "rámků",
            "unit_plural_0": "žádný",
            "title": "Vložení rámků s pylem"
        },
        {
            "id": 94,
            "group": {
                "id": 7,
                "title": "Zásahy",
                "order": 2,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/923f33e4b241947cb389aa18334da2d6cb79b1a6/e5081751365511dafd96bbb3eb1762f5.png"
            },
            "category": {
                "id": 8,
                "title": "Nástavky a rámky",
                "order": 0
            },
            "has_value": false,
            "title": "Rotace nástavků"
        },
        {
            "id": 95,
            "group": {
                "id": 7,
                "title": "Zásahy",
                "order": 2,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/923f33e4b241947cb389aa18334da2d6cb79b1a6/e5081751365511dafd96bbb3eb1762f5.png"
            },
            "category": {
                "id": 8,
                "title": "Nástavky a rámky",
                "order": 0
            },
            "has_value": true,
            "unit": "rámek",
            "unit_plural_2": "rámky",
            "unit_plural_5": "rámků",
            "unit_plural_0": "žádný",
            "title": "Odstranění trubčiny"
        },
        {
            "id": 72,
            "group": {
                "id": 7,
                "title": "Zásahy",
                "order": 2,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/923f33e4b241947cb389aa18334da2d6cb79b1a6/e5081751365511dafd96bbb3eb1762f5.png"
            },
            "has_value": false,
            "title": "Vložení mateří mřížky"
        },
        {
            "id": 73,
            "group": {
                "id": 7,
                "title": "Zásahy",
                "order": 2,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/923f33e4b241947cb389aa18334da2d6cb79b1a6/e5081751365511dafd96bbb3eb1762f5.png"
            },
            "has_value": false,
            "title": "Odstranění mateří mřížky"
        },
        {
            "id": 74,
            "group": {
                "id": 7,
                "title": "Zásahy",
                "order": 2,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/923f33e4b241947cb389aa18334da2d6cb79b1a6/e5081751365511dafd96bbb3eb1762f5.png"
            },
            "has_value": false,
            "title": "Vložení výkluzu"
        },
        {
            "id": 75,
            "group": {
                "id": 7,
                "title": "Zásahy",
                "order": 2,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/923f33e4b241947cb389aa18334da2d6cb79b1a6/e5081751365511dafd96bbb3eb1762f5.png"
            },
            "has_value": false,
            "title": "Odstranění výkluzu"
        },
        {
            "id": 76,
            "group": {
                "id": 7,
                "title": "Zásahy",
                "order": 2,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/923f33e4b241947cb389aa18334da2d6cb79b1a6/e5081751365511dafd96bbb3eb1762f5.png"
            },
            "has_value": false,
            "title": "Vložení česnové zábrany"
        },
        {
            "id": 77,
            "group": {
                "id": 7,
                "title": "Zásahy",
                "order": 2,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/923f33e4b241947cb389aa18334da2d6cb79b1a6/e5081751365511dafd96bbb3eb1762f5.png"
            },
            "has_value": false,
            "title": "Odstranění česnové zábrany"
        },
        {
            "id": 96,
            "group": {
                "id": 7,
                "title": "Zásahy",
                "order": 2,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/923f33e4b241947cb389aa18334da2d6cb79b1a6/e5081751365511dafd96bbb3eb1762f5.png"
            },
            "has_value": false,
            "title": "Zaklíckování matky"
        },
        {
            "id": 97,
            "group": {
                "id": 7,
                "title": "Zásahy",
                "order": 2,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/923f33e4b241947cb389aa18334da2d6cb79b1a6/e5081751365511dafd96bbb3eb1762f5.png"
            },
            "has_value": false,
            "title": "Odklíckování matky"
        },
        {
            "id": 24,
            "group": {
                "id": 3,
                "title": "Léčení",
                "order": 3,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/897e939cfc4d6a0de1558bfb141031d6fe7472f8/1e1a49eae2c6d835b00f7a07c2692ec6.png"
            },
            "category": {
                "id": 9,
                "title": "Organické",
                "order": 0
            },
            "has_value": false,
            "title": "Apiguard (thymol)"
        },
        {
            "id": 27,
            "group": {
                "id": 3,
                "title": "Léčení",
                "order": 3,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/897e939cfc4d6a0de1558bfb141031d6fe7472f8/1e1a49eae2c6d835b00f7a07c2692ec6.png"
            },
            "category": {
                "id": 9,
                "title": "Organické",
                "order": 0
            },
            "has_value": false,
            "title": "Formidol 41g (1. část s obalem)"
        },
        {
            "id": 28,
            "group": {
                "id": 3,
                "title": "Léčení",
                "order": 3,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/897e939cfc4d6a0de1558bfb141031d6fe7472f8/1e1a49eae2c6d835b00f7a07c2692ec6.png"
            },
            "category": {
                "id": 9,
                "title": "Organické",
                "order": 0
            },
            "has_value": false,
            "title": "Formidol 41g (2. část bez obalu)"
        },
        {
            "id": 29,
            "group": {
                "id": 3,
                "title": "Léčení",
                "order": 3,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/897e939cfc4d6a0de1558bfb141031d6fe7472f8/1e1a49eae2c6d835b00f7a07c2692ec6.png"
            },
            "category": {
                "id": 9,
                "title": "Organické",
                "order": 0
            },
            "has_value": false,
            "title": "Formidol 81g"
        },
        {
            "id": 60,
            "group": {
                "id": 3,
                "title": "Léčení",
                "order": 3,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/897e939cfc4d6a0de1558bfb141031d6fe7472f8/1e1a49eae2c6d835b00f7a07c2692ec6.png"
            },
            "category": {
                "id": 9,
                "title": "Organické",
                "order": 0
            },
            "has_value": true,
            "unit": "ml",
            "unit_plural_2": "ml",
            "unit_plural_5": "ml",
            "unit_plural_0": "ml",
            "title": "Kys. mravenčí 85%"
        },
        {
            "id": 61,
            "group": {
                "id": 3,
                "title": "Léčení",
                "order": 3,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/897e939cfc4d6a0de1558bfb141031d6fe7472f8/1e1a49eae2c6d835b00f7a07c2692ec6.png"
            },
            "category": {
                "id": 9,
                "title": "Organické",
                "order": 0
            },
            "has_value": true,
            "unit": "ml",
            "unit_plural_2": "ml",
            "unit_plural_5": "ml",
            "unit_plural_0": "ml",
            "title": "Kys. mravenčí 65%"
        },
        {
            "id": 99,
            "group": {
                "id": 3,
                "title": "Léčení",
                "order": 3,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/897e939cfc4d6a0de1558bfb141031d6fe7472f8/1e1a49eae2c6d835b00f7a07c2692ec6.png"
            },
            "category": {
                "id": 9,
                "title": "Organické",
                "order": 0
            },
            "has_value": false,
            "title": "Odstranění odpařovače"
        },
        {
            "id": 34,
            "group": {
                "id": 3,
                "title": "Léčení",
                "order": 3,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/897e939cfc4d6a0de1558bfb141031d6fe7472f8/1e1a49eae2c6d835b00f7a07c2692ec6.png"
            },
            "category": {
                "id": 9,
                "title": "Organické",
                "order": 0
            },
            "has_value": false,
            "title": "Oxuvar"
        },
        {
            "id": 35,
            "group": {
                "id": 3,
                "title": "Léčení",
                "order": 3,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/897e939cfc4d6a0de1558bfb141031d6fe7472f8/1e1a49eae2c6d835b00f7a07c2692ec6.png"
            },
            "category": {
                "id": 9,
                "title": "Organické",
                "order": 0
            },
            "has_value": false,
            "title": "OxyBee"
        },
        {
            "id": 37,
            "group": {
                "id": 3,
                "title": "Léčení",
                "order": 3,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/897e939cfc4d6a0de1558bfb141031d6fe7472f8/1e1a49eae2c6d835b00f7a07c2692ec6.png"
            },
            "category": {
                "id": 9,
                "title": "Organické",
                "order": 0
            },
            "has_value": false,
            "title": "Thymovar, 15g"
        },
        {
            "id": 39,
            "group": {
                "id": 3,
                "title": "Léčení",
                "order": 3,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/897e939cfc4d6a0de1558bfb141031d6fe7472f8/1e1a49eae2c6d835b00f7a07c2692ec6.png"
            },
            "category": {
                "id": 9,
                "title": "Organické",
                "order": 0
            },
            "has_value": true,
            "unit": "ml",
            "unit_plural_2": "ml",
            "unit_plural_5": "ml",
            "unit_plural_0": "ml",
            "title": "VarroMed"
        },
        {
            "id": 62,
            "group": {
                "id": 3,
                "title": "Léčení",
                "order": 3,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/897e939cfc4d6a0de1558bfb141031d6fe7472f8/1e1a49eae2c6d835b00f7a07c2692ec6.png"
            },
            "category": {
                "id": 9,
                "title": "Organické",
                "order": 0
            },
            "has_value": true,
            "unit": "ml",
            "unit_plural_2": "ml",
            "unit_plural_5": "ml",
            "unit_plural_0": "ml",
            "title": "Kys. šťavelová pokap"
        },
        {
            "id": 105,
            "group": {
                "id": 3,
                "title": "Léčení",
                "order": 3,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/897e939cfc4d6a0de1558bfb141031d6fe7472f8/1e1a49eae2c6d835b00f7a07c2692ec6.png"
            },
            "category": {
                "id": 9,
                "title": "Organické",
                "order": 0
            },
            "has_value": true,
            "unit": "pásek",
            "unit_plural_2": "pásky",
            "unit_plural_5": "pásků",
            "unit_plural_0": "žádný",
            "title": "Kys. šťavelová pásky"
        },
        {
            "id": 86,
            "group": {
                "id": 3,
                "title": "Léčení",
                "order": 3,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/897e939cfc4d6a0de1558bfb141031d6fe7472f8/1e1a49eae2c6d835b00f7a07c2692ec6.png"
            },
            "category": {
                "id": 9,
                "title": "Organické",
                "order": 0
            },
            "has_value": true,
            "unit": "ml",
            "unit_plural_2": "ml",
            "unit_plural_5": "ml",
            "unit_plural_0": "ml",
            "title": "Kys. mléčná 15%"
        },
        {
            "id": 93,
            "group": {
                "id": 3,
                "title": "Léčení",
                "order": 3,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/897e939cfc4d6a0de1558bfb141031d6fe7472f8/1e1a49eae2c6d835b00f7a07c2692ec6.png"
            },
            "category": {
                "id": 9,
                "title": "Organické",
                "order": 0
            },
            "has_value": true,
            "unit": "pásek",
            "unit_plural_2": "pásky",
            "unit_plural_5": "pásků",
            "unit_plural_0": "žádný",
            "title": "Ekopol"
        },
        {
            "id": 25,
            "group": {
                "id": 3,
                "title": "Léčení",
                "order": 3,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/897e939cfc4d6a0de1558bfb141031d6fe7472f8/1e1a49eae2c6d835b00f7a07c2692ec6.png"
            },
            "category": {
                "id": 10,
                "title": "Syntetické",
                "order": 1
            },
            "has_value": false,
            "title": "Apitraz (amitraz)"
        },
        {
            "id": 26,
            "group": {
                "id": 3,
                "title": "Léčení",
                "order": 3,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/897e939cfc4d6a0de1558bfb141031d6fe7472f8/1e1a49eae2c6d835b00f7a07c2692ec6.png"
            },
            "category": {
                "id": 10,
                "title": "Syntetické",
                "order": 1
            },
            "has_value": false,
            "title": "Apivar (amitraz)"
        },
        {
            "id": 30,
            "group": {
                "id": 3,
                "title": "Léčení",
                "order": 3,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/897e939cfc4d6a0de1558bfb141031d6fe7472f8/1e1a49eae2c6d835b00f7a07c2692ec6.png"
            },
            "category": {
                "id": 10,
                "title": "Syntetické",
                "order": 1
            },
            "has_value": false,
            "title": "Gabon Flum"
        },
        {
            "id": 31,
            "group": {
                "id": 3,
                "title": "Léčení",
                "order": 3,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/897e939cfc4d6a0de1558bfb141031d6fe7472f8/1e1a49eae2c6d835b00f7a07c2692ec6.png"
            },
            "category": {
                "id": 10,
                "title": "Syntetické",
                "order": 1
            },
            "has_value": false,
            "title": "Gabon PF"
        },
        {
            "id": 98,
            "group": {
                "id": 3,
                "title": "Léčení",
                "order": 3,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/897e939cfc4d6a0de1558bfb141031d6fe7472f8/1e1a49eae2c6d835b00f7a07c2692ec6.png"
            },
            "category": {
                "id": 10,
                "title": "Syntetické",
                "order": 1
            },
            "has_value": false,
            "title": "Odstranění Gabonu"
        },
        {
            "id": 32,
            "group": {
                "id": 3,
                "title": "Léčení",
                "order": 3,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/897e939cfc4d6a0de1558bfb141031d6fe7472f8/1e1a49eae2c6d835b00f7a07c2692ec6.png"
            },
            "category": {
                "id": 10,
                "title": "Syntetické",
                "order": 1
            },
            "has_value": false,
            "title": "M-1 AER"
        },
        {
            "id": 33,
            "group": {
                "id": 3,
                "title": "Léčení",
                "order": 3,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/897e939cfc4d6a0de1558bfb141031d6fe7472f8/1e1a49eae2c6d835b00f7a07c2692ec6.png"
            },
            "category": {
                "id": 10,
                "title": "Syntetické",
                "order": 1
            },
            "has_value": false,
            "title": "MP 10 FUM"
        },
        {
            "id": 36,
            "group": {
                "id": 3,
                "title": "Léčení",
                "order": 3,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/897e939cfc4d6a0de1558bfb141031d6fe7472f8/1e1a49eae2c6d835b00f7a07c2692ec6.png"
            },
            "category": {
                "id": 10,
                "title": "Syntetické",
                "order": 1
            },
            "has_value": false,
            "title": "PolyVar Yellow"
        },
        {
            "id": 38,
            "group": {
                "id": 3,
                "title": "Léčení",
                "order": 3,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/897e939cfc4d6a0de1558bfb141031d6fe7472f8/1e1a49eae2c6d835b00f7a07c2692ec6.png"
            },
            "category": {
                "id": 10,
                "title": "Syntetické",
                "order": 1
            },
            "has_value": true,
            "unit": "kapka",
            "unit_plural_2": "kapky",
            "unit_plural_5": "kapek",
            "unit_plural_0": "žádná",
            "title": "Varidol 125"
        },
        {
            "id": 70,
            "group": {
                "id": 5,
                "title": "Krmení",
                "order": 4,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/e4d3a21d88a25c98dc50d8c41e160693b6cf39d6/e647571de7bc4ed35fcb3ab6d98ea3ff.png"
            },
            "has_value": false,
            "title": "Vložení krmítka"
        },
        {
            "id": 71,
            "group": {
                "id": 5,
                "title": "Krmení",
                "order": 4,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/e4d3a21d88a25c98dc50d8c41e160693b6cf39d6/e647571de7bc4ed35fcb3ab6d98ea3ff.png"
            },
            "has_value": false,
            "title": "Odstranění krmítka"
        },
        {
            "id": 63,
            "group": {
                "id": 5,
                "title": "Krmení",
                "order": 4,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/e4d3a21d88a25c98dc50d8c41e160693b6cf39d6/e647571de7bc4ed35fcb3ab6d98ea3ff.png"
            },
            "has_value": true,
            "unit": "l",
            "unit_plural_2": "l",
            "unit_plural_5": "l",
            "unit_plural_0": "l",
            "title": "Cukerný roztok 3:2"
        },
        {
            "id": 64,
            "group": {
                "id": 5,
                "title": "Krmení",
                "order": 4,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/e4d3a21d88a25c98dc50d8c41e160693b6cf39d6/e647571de7bc4ed35fcb3ab6d98ea3ff.png"
            },
            "has_value": true,
            "unit": "l",
            "unit_plural_2": "l",
            "unit_plural_5": "l",
            "unit_plural_0": "l",
            "title": "Cukerný roztok 1:1"
        },
        {
            "id": 65,
            "group": {
                "id": 5,
                "title": "Krmení",
                "order": 4,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/e4d3a21d88a25c98dc50d8c41e160693b6cf39d6/e647571de7bc4ed35fcb3ab6d98ea3ff.png"
            },
            "has_value": true,
            "unit": "kg",
            "unit_plural_2": "kg",
            "unit_plural_5": "kg",
            "unit_plural_0": "kg",
            "title": "Medocukrové těsto"
        },
        {
            "id": 100,
            "group": {
                "id": 5,
                "title": "Krmení",
                "order": 4,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/e4d3a21d88a25c98dc50d8c41e160693b6cf39d6/e647571de7bc4ed35fcb3ab6d98ea3ff.png"
            },
            "has_value": true,
            "unit": "kg",
            "unit_plural_2": "kg",
            "unit_plural_5": "kg",
            "unit_plural_0": "kg",
            "title": "Apifood sirup"
        },
        {
            "id": 101,
            "group": {
                "id": 5,
                "title": "Krmení",
                "order": 4,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/e4d3a21d88a25c98dc50d8c41e160693b6cf39d6/e647571de7bc4ed35fcb3ab6d98ea3ff.png"
            },
            "has_value": true,
            "unit": "kg",
            "unit_plural_2": "kg",
            "unit_plural_5": "kg",
            "unit_plural_0": "kg",
            "title": "Apifood těsto"
        },
        {
            "id": 102,
            "group": {
                "id": 5,
                "title": "Krmení",
                "order": 4,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/e4d3a21d88a25c98dc50d8c41e160693b6cf39d6/e647571de7bc4ed35fcb3ab6d98ea3ff.png"
            },
            "has_value": true,
            "unit": "kg",
            "unit_plural_2": "kg",
            "unit_plural_5": "kg",
            "unit_plural_0": "kg",
            "title": "Apivital sirup"
        },
        {
            "id": 103,
            "group": {
                "id": 5,
                "title": "Krmení",
                "order": 4,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/e4d3a21d88a25c98dc50d8c41e160693b6cf39d6/e647571de7bc4ed35fcb3ab6d98ea3ff.png"
            },
            "has_value": true,
            "unit": "kg",
            "unit_plural_2": "kg",
            "unit_plural_5": "kg",
            "unit_plural_0": "kg",
            "title": "Apivital těsto"
        },
        {
            "id": 6,
            "group": {
                "id": 1,
                "title": "Počasí",
                "order": 5,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/eab9295cfb558106c8ade16602c7688705eb950c/00281ff924acdb88c882c3351f2f5598.png"
            },
            "has_value": true,
            "unit": "°C",
            "unit_plural_2": "°C",
            "unit_plural_5": "°C",
            "unit_plural_0": "°C",
            "title": "Teplota",
            "ico_name": "thermometer-half"
        },
        {
            "id": 2,
            "group": {
                "id": 1,
                "title": "Počasí",
                "order": 5,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/eab9295cfb558106c8ade16602c7688705eb950c/00281ff924acdb88c882c3351f2f5598.png"
            },
            "has_value": false,
            "title": "Polojasno",
            "ico_name": "cloud-sun"
        },
        {
            "id": 3,
            "group": {
                "id": 1,
                "title": "Počasí",
                "order": 5,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/eab9295cfb558106c8ade16602c7688705eb950c/00281ff924acdb88c882c3351f2f5598.png"
            },
            "has_value": false,
            "title": "Zataženo",
            "ico_name": "cloud"
        },
        {
            "id": 4,
            "group": {
                "id": 1,
                "title": "Počasí",
                "order": 5,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/eab9295cfb558106c8ade16602c7688705eb950c/00281ff924acdb88c882c3351f2f5598.png"
            },
            "has_value": false,
            "title": "Bezvětří"
        },
        {
            "id": 5,
            "group": {
                "id": 1,
                "title": "Počasí",
                "order": 5,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/eab9295cfb558106c8ade16602c7688705eb950c/00281ff924acdb88c882c3351f2f5598.png"
            },
            "has_value": false,
            "title": "Vítr",
            "ico_name": "wind"
        },
        {
            "id": 1,
            "group": {
                "id": 1,
                "title": "Počasí",
                "order": 5,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/eab9295cfb558106c8ade16602c7688705eb950c/00281ff924acdb88c882c3351f2f5598.png"
            },
            "has_value": false,
            "title": "Slunečno",
            "ico_name": "sun"
        },
        {
            "id": 66,
            "group": {
                "id": 6,
                "title": "Žihadla",
                "order": 6,
                "image_url": "https://d3ndeyjlcr3ixh.cloudfront.net/media/CACHE/images/hive/4810d76e9ce3cf2e2e257a28f6c90ca94ff9e4d5/b5ae79adf18ac5b39ac34512aa722f42.png"
            },
            "has_value": true,
            "unit": "žihadlo",
            "unit_plural_2": "žihladla",
            "unit_plural_5": "žihadel",
            "unit_plural_0": "žádné",
            "title": "Počet žihadel"
        }
    ]

    if (data) {
        const tagsMap = data.reduce((result, tagInfo) => {
            result.tags[tagInfo.id] = tagInfo;
            result.groups[tagInfo.group.id] = tagInfo.group;
            if (tagInfo.category) {
                result.categories[tagInfo.category.id] = tagInfo.category;
            }
            return result;
        }, {tags: {}, groups: {}, categories: {}});

        return <TagsContext.Provider value={tagsMap}>
            {children}
        </TagsContext.Provider>
    }

    return <>
        {children}
    </>
}

const TagIco = ({id}) => {
    let height = "0.65em"
    const icos = {
        1: <FaSun className="mr-1" style={{height: height}}/>,
        2: <FaCloudSun className="mr-1" style={{height: height}}/>,
        3: <FaCloud className="mr-1" style={{height: height}}/>,
        5: <FaWind className="mr-1" style={{height: height}}/>,
        6: <FaThermometerHalf className="mr-1" style={{height: height}}/>
    }

    if (id in icos) {
        return icos[id]
    }
    return <></>
}

export function Tag({data, showName = false}) {
    const tagsMap = useContext(TagsContext);
    const tagInfo = tagsMap.tags[data[0]];
    if (showName) {
        return <span className="tag mr-2">
            {tagInfo.title}{(tagInfo.has_value) ? `: ${addUnit(data[1], tagInfo.unit, tagInfo.unit_plural_2,
            tagInfo.unit_plural_5, tagInfo.unit_plural_0)}` : ""}
    </span>
    } else
        return <span className="tag mr-2"><TagIco id={tagInfo.id}/>
            {(tagInfo.has_value) ? addUnit(data[1], tagInfo.unit, tagInfo.unit_plural_2,
                tagInfo.unit_plural_5, tagInfo.unit_plural_0) : tagInfo.title}
    </span>
}

export function TagsInCategories({data, showNames = false}) {
    const tagsMap = useContext(TagsContext);

    const categorizedTags = data.reduce((result, tag) => {
        const tagInfo = tagsMap.tags[tag[0]];
        let tagCategory = "";
        let tagGroup = ""
        if (tagInfo.category) {
            tagCategory = tagInfo.category;
        }
        if (tagInfo.group) {
            tagGroup = tagInfo.group;
        }
        if (result.length > 0) {
            const last = result[result.length - 1];
            if (last.category === tagCategory?.id && last.group === tagGroup?.id) {
                last.tags.push(tag);
            } else {
                result.push({category: tagCategory?.id, group: tagGroup?.id, tags: [tag]});
            }
        } else {
            result.push({category: tagCategory?.id, group: tagGroup?.id, tags: [tag]});
        }
        return result;
    }, []);

    const categoryOrGroup = (categoryTags) => {
        if (categoryTags.category in tagsMap.categories) {
            return tagsMap.categories[categoryTags.category].title
        }
        if (categoryTags.group in tagsMap.groups) {
            return tagsMap.groups[categoryTags.group].title
        }
    }

    return <>
        {categorizedTags.map((categoryTags, index) =>
            <div key={index} className="mb-1"><b className="mr-2">{categoryOrGroup(categoryTags)}:</b>
                {categoryTags.tags.map(tag =>
                    <Tag key={tag} data={tag} showName={showNames}/>)}
            </div>)}
    </>
}

export function TagsInGroups({data, showNames = false}) {
    const tagsMap = useContext(TagsContext);

    const groupedTags = data.reduce((result, tag) => {
        const tagInfo = tagsMap.tags[tag[0]];
        const tagGroup = tagInfo.group;
        if (result.length > 0) {
            const last = result[result.length - 1];
            if (last.group === tagGroup?.id) {
                last.tags.push(tag);
            } else {
                result.push({group: tagGroup?.id, tags: [tag]});
            }
        } else {
            result.push({group: tagGroup?.id, tags: [tag]});
        }
        return result;
    }, []);

    return <>
        {groupedTags.map(groupTags =>
            <div key={groupTags.group} className="mb-1"><b className="mr-2">{tagsMap.groups[groupTags.group].title}:</b>
                {groupTags.tags.map(tag =>
                    <Tag key={tag} data={tag} showName={showNames}/>)}
            </div>)}
    </>
}
