import {GithubPicker} from 'react-color';
import {ErrorMessage, useField, useFormikContext} from "formik";
import {AiOutlineDelete, AiOutlineQuestionCircle} from "react-icons/ai";
import {useState} from "react";

export const ColorField = ({label, setHintVisible, ...props}) => {
    const [isPickerVisible, setPickerVisible] = useState(false)
    const {setFieldValue} = useFormikContext();
    const [field] = useField(props);

    const reset = (e) => {
        setFieldValue(field.name, "");
        setPickerVisible(false);
        e.stopPropagation();
        e.preventDefault();
    }

    const togglePickerVisible = () => {
        setPickerVisible(!isPickerVisible);
    }

    return <div className="field">
        <label className="label">{label} {setHintVisible ?
            <AiOutlineQuestionCircle onClick={(e) => {
                setHintVisible(true)
            }} color="gray" className="ml-1 vatt"/> : ""}</label>
        <div className={`input ${field.value ? "" : "no-color"}`} style={{backgroundColor: field.value, justifyContent: "end"}}
             onClick={togglePickerVisible}>
            <button className="button is-small-ico is-dark" onClick={reset}><AiOutlineDelete/></button>
        </div>
        {isPickerVisible &&
        <div style={{position: "absolute", zIndex: "2"}}>
            <GithubPicker
                {...field}
                {...props}
                width="137px"
                colors={[
                    "yellow",
                    "white",
                    "blue",
                    "green",
                    "red",
                    "#8B4513",
                    "#808000",
                    "#00FF00",
                    "#00FFFF",
                    "#008080",
                    "#000080",
                    "#C0C0C0",
                    "#FF00FF",
                    "#FFA500",
                    "#000000",
                ]}
                color={field.value}
                onChange={color => {
                    setFieldValue(field.name, (color ? color.hex : ""));
                    setPickerVisible(false);
                }
                }
            /></div>
        }
        <p className="has-text-danger"><ErrorMessage name={props.name}/></p>
    </div>
}

// export const ColorField = ({label, setHintVisible, ...props}) => {
//     const {setFieldValue} = useFormikContext();
//     const [field] = useField(props);
//
//     return <div className="field">
//         <label className="label">{label} {setHintVisible ?
//             <AiOutlineQuestionCircle onClick={(e) => { setHintVisible(true) }} color="gray" className="ml-1 vatt"/> : ""}</label>
//         <BlockPicker
//             {...field}
//             {...props}
//             triangle="hide"
//             colors={[
//                 "#C0C0C0",
//                 "#FFFFFF",
//                 "#800000",
//                 "#8B4513",
//                 "#FFFF00",
//                 "#808000",
//                 "#00FF00",
//                 "#008000",
//                 "#00FFFF",
//                 "#008080",
//                 "#0000FF",
//                 "#000080",
//                 "#FF00FF",
//                 "#800080",
//                 "#FFA500",
//             ]}
//             color={field.value || "#000000"}
//             onChange={color => setFieldValue(field.name, (color === "#000000" ? null : color.hex))}
//         />
//         <p className="has-text-danger"><ErrorMessage name={props.name}/></p>
//     </div>
// }
