import {useContext, useEffect, useState} from "react";
import {TagsContext} from "../App";
import {intUnit} from "../utils/WithTags";

export const TagsRow = ({children}) => {
    return <div className="card-content pt-3 pb-1" style={{borderBottom: "1px solid whitesmoke"}}>
        {children}
    </div>
}

const TagOption = ({tag, active, intActive = false, ...props}) => {
    return <span style={
        {
            cursor: "pointer",
            fontSize: "0.9rem",
            height: "2rem",
            userSelect: "none",
            borderBottomRightRadius: intActive ? 0 : "4px",
            borderTopRightRadius: intActive ? 0 : "4px"
        }
    } {...props}
                 className={`tag mb-2 is-normal ${intActive ? "" : "mr-2"} ${active && "is-info"}`}>
    {tag.title}
        </span>
}

export const TagFieldOptions = ({
                                    multiple = true, currentValue = [], tags, onExclude = () => {
    }, onInclude = () => {
    }
                                }) => {
    const [value, setValue] = useState((currentValue.map((e) => e[0])).filter(e => tags.includes(e)));
    const [values, setValues] = useState(currentValue.map((e) => e[0]));
    const tagsMap = useContext(TagsContext);

    useEffect(() => {
        let toRemove = tags.filter(tag => tag != value);
        let toAdd = tags.filter(tag => tag == value);
        onExclude(toRemove);
        onInclude(toAdd);
    }, [value]);

    useEffect(() => {
        let toRemove = tags.filter(tag => !values.includes(tag));
        let toAdd = tags.filter(tag => values.includes(tag));
        onExclude(toAdd);
        onExclude(toRemove);
        onInclude(toAdd);
    }, [values]);

    const handleTagClick = (tagId) => {
        if (value === tagId) {
            setValue(null);
        } else {
            setValue(tagId);
        }
    }

    const handleMultipleTagClick = (tagId) => {
        if (values.includes(tagId)) {
            setValues(oldValues => oldValues.filter(x => x != tagId));
        } else {
            setValues(oldValues => [...oldValues, tagId]);
        }
    }

    return <>
        {multiple ?
            tagsMap && tags.map(tagId => <TagOption onClick={() => handleMultipleTagClick(tagId)}
                                                    active={values.includes(tagId)}
                                                    key={tagId}
                                                    tag={tagsMap.tags[tagId]}></TagOption>)
            : tagsMap && tags.map(tagId => <TagOption onClick={() => handleTagClick(tagId)}
                                                      active={value == tagId}
                                                      key={tagId}
                                                      tag={tagsMap.tags[tagId]}></TagOption>)}
    </>
}

export const TagFieldNumber = ({tag, minValue, maxValue, currentValue = [], defaultValue, step = 1, onSet}) => {
    const getCurrentValue = (val) => {
        let candidates = val.filter(e => tag == e[0]);
        if (candidates.length > 0) {
            return candidates[0][1]
        }
        return null
    }

    const [value, setValue] = useState(getCurrentValue(currentValue));
    const tagInfo = useContext(TagsContext).tags[tag];

    const toggle = () => {
        if (value != null) {
            setValue(null);
            onSet(tag, null);
        } else {
            setValue(defaultValue || 0);
            onSet(tag, defaultValue || 0);
        }
    }

    const handleValueChange = (e) => {
        const targetValue = e.target.value;
        if (targetValue >= minValue && targetValue <= maxValue) {
            setValue(targetValue);
            onSet(tag, targetValue);
        }
    }

    return tagInfo && <span className="is-inline-block">
        <TagOption onClick={toggle}
                   active={value != null}
                   intActive={value != null}
                   tag={tagInfo}/>
        {value != null && <>
            <input style={{
                width: "5em",
                height: "2rem",
                borderLeft: "none",
                borderBottomLeftRadius: 0,
                borderTopLeftRadius: 0
            }}
                   step={step}
                   className="input mr-2"
                   min={minValue}
                   max={maxValue}
                   type="number"
                   value={`${Math.round(value * 1 / step) * step}`}
                   onChange={handleValueChange}/>
            <span className="mr-4">{intUnit(value, tagInfo.unit, tagInfo.unit_plural_2,
                tagInfo.unit_plural_5, tagInfo.unit_plural_0)}</span>
        </>}
    </span>

}
