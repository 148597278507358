import React, {createContext, useContext, useState} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './css/custom.scss';
import 'animate.css';
import {AuthProvider} from "./hooks/useAuth";
import {WithTags} from "./utils/WithTags";
import {ParallaxProvider} from "react-scroll-parallax";


const PositionContext = createContext();
export default function PositionProvider({children}) {
    const [pageForPosition, setPageForPosition] = useState()
    // position is array [page, offset]
    // - pageNumber: 1 .. n
    // - offset: top offset in the viewport
    const [position, setPosition] = useState({
        HiveLocation: [1, 0],
        Hive: [1, 0]
    });

    return (
        <PositionContext.Provider value={{position, setPosition, pageForPosition, setPageForPosition}}>
            {children}
        </PositionContext.Provider>
    );
};
export const usePosition = () => useContext(PositionContext);

ReactDOM.render(
    // <React.StrictMode>
    <ParallaxProvider>
        <AuthProvider>
            <WithTags>
                <PositionProvider>
                    <App/>
                </PositionProvider>
            </WithTags>
        </AuthProvider>
    </ParallaxProvider>
    // </React.StrictMode>
    ,
    document.getElementById('root')
);
