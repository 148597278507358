import {ErrorMessage, Field} from "formik";
import {AiOutlineQuestionCircle} from "react-icons/ai";

export const TextField = ({name, label, disabled = false, setHintVisible}) => {
    return <div className="field">
        <label className="label" htmlFor={name}>{label} {setHintVisible ?
            <AiOutlineQuestionCircle onClick={(e) => { setHintVisible(true) }} color="gray" className="ml-1 vatt"/> : ""}</label>
        <Field disabled={disabled} className="input" name={name} type="text"/>
        <p className="has-text-danger"><ErrorMessage name={name}/></p>
    </div>
}

export const PasswordField = ({name, label, disabled = false, setHintVisible}) => {
    return <div className="field">
        <label className="label" htmlFor={name}>{label} {setHintVisible ?
            <AiOutlineQuestionCircle onClick={(e) => { setHintVisible(true) }} color="gray" className="ml-1 vatt"/> : ""}</label>
        <Field disabled={disabled} className="input" name={name} type="password"/>
        <p className="has-text-danger"><ErrorMessage name={name}/></p>
    </div>
}