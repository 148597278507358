import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {ForgottenPasswordForm} from "../components/ForgottenPasswordForm";

export default function ForgottenPassword() {
    let params = useParams();

    useEffect(() => {
        document.title = "Chytrý včelař | Zapomenuté heslo"
    }, []);

    return <div className="container is-fluid mt-4">
        <div className="section">
            <div className="columns is-centered">
                <div className="column is-6-tablet is-5-desktop is-4-widescreen">
                    <div className="box">
                        <ForgottenPasswordForm
                            initialValues={{
                                uid: params.uid,
                                token: params.token
                            }}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
