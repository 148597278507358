import {Parallax, ParallaxProvider} from "react-scroll-parallax";
import {BottomMenu, Footer} from "./Home";
import {useEffect} from "react";
import vcelkulackaPreview from "../img/vcelkulacka-preview.png"
import ico from "../img/ico.png"
import vcelkulackaBig from "../img/vcelkulacka-big.png"
import appStoreBtn from "../img/app-store-btn.svg";
import googlePlayBtn from '../img/google-play-btn.svg';
import {Link} from "react-router-dom";
import {FaChevronRight} from "react-icons/fa";

const TitleSection = () => {
    return <section className="hero home" style={{backgroundColor: "#F6F6F6"}}>
        <div className="columns">
            <div className="column is-1"></div>
            <div className="column">
                <div className="hero-body mb-6">
                    <Parallax speed={10}>
                        <h1 className="bold mb-4">Včelkulačka</h1>
                        <h2 className="bold orange">Snadné výpočty
                            pro&nbsp;včelaře</h2>
                        <hr className="mt-6 mb-6"/>
                        <h3 className="light">Spočítejte si správné poměry a&nbsp;hodnoty při přípravě cukerných roztoků
                            a ředění organických kyselin během pár vteřin. </h3>
                    </Parallax>
                </div>
            </div>
            <div className="column">
                <div className="hero-body has-text-centered mb-6">
                    <Parallax speed={-3}>
                        <img src={vcelkulackaPreview} alt="Včelkulačka" />
                    </Parallax>
                </div>
            </div>
            <div className="column is-1"></div>
        </div>
    </section>;
}

const About = () => {
    return <section className="hero home">
        <div className="pt-6 pb-6 has-text-centered">
            <Parallax speed={3}>
                <img className="mt-6" src={vcelkulackaBig} alt="Včelkulačka"/>
            </Parallax>
            <Parallax speed={4}>
                <h2 className="mt-2">Včelkulačka</h2>
                <p className="mb-6">Včelařská kalkulačka</p>
            </Parallax>
            <Parallax speed={5}>
                <div className="mb-6">
                    <a href="https://apps.apple.com/app/id1564065762" target="_blank">
                        <button className="button is-a" style={{width: "14rem", height: "auto"}}><img
                            src={appStoreBtn}/>
                        </button>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.punkhive.beecalc" target="_blank">
                        <button className="button is-a" style={{width: "14rem", height: "auto"}}><img
                            src={googlePlayBtn}/>
                        </button>
                    </a>
                </div>
            </Parallax>
        </div>
    </section>;
}

const ChytryVcelar = () => {
    return <div className="columns home pb-3 pt-3" style={{backgroundColor: "#F6F6F6"}}>
        <div className="column"/>
        <div className="column is-9 ml-2 mr-2 has-text-centered-mobile">
            <Link to="/" className="link" onClick={() => window.scrollTo(0, 0)}>
                <img src={ico} className="is-hidden-tablet is-48x48 pr-3" alt="Chytrý včelař"/>
                <img src={ico} className="is-hidden-mobile is-48x48 pr-3" alt="Chytrý včelař"
                     style={{float: "left"}}/>
                <h3 className="light">Vyzkoušejte také <b>Chytrého včelaře</b></h3>
                <p className="small">Jednoduchý a přehledný úlový zápisník ve vašem počítačí i mobilu</p>
            </Link>
        </div>
        <div className="column is-1 has-text-right has-text-centered-mobile pt-6">
            <Link to="/" className="button is-a" onClick={() => window.scrollTo(0, 0)}>Více <FaChevronRight/></Link>
        </div>
        <div className="column"/>
    </div>
}

export default function Vcelkulacka() {

    useEffect(() => {
        document.title = "Chytrý včelař | Včelkulačka"
    }, []);

    return (
        <>
            <TitleSection/>
            <About/>
            <ChytryVcelar/>
            <BottomMenu/>
            <Footer/>
        </>
    );
}