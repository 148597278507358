import {Parallax, ParallaxProvider} from "react-scroll-parallax";
import premium from '../img/premium-beekeeper.svg';
import p1 from '../img/premium-1.svg';
import p2 from '../img/premium-2.svg';
import p3 from '../img/premium-3.svg';
import p4 from '../img/premium-4.svg';
import pay1 from '../img/pay1.svg';
import pay2 from '../img/pay2.svg';


import {BottomMenu, Footer} from "./Home";
import {Link} from "react-router-dom";

const TitleSection = () => {
    return <section className="hero is-white home">
        <div className="columns">
            <div className="column is-2"></div>
            <div className="column">
                <div className="hero-body has-text-centered mb-6">
                    <Parallax speed={2}>
                        <h1 className="bold">
                            Premiový včelař</h1>
                    </Parallax>
                    <Parallax speed={3}>
                        <h2 className="mt-3 regular">S premiovým účtem můžete se svými
                            včelstvy a záznamy pracovat nejen
                            v&nbsp;mobilní&nbsp;aplikaci, ale i na webu</h2>
                    </Parallax>
                </div>
            </div>
            <div className="column is-2"></div>
        </div>
    </section>;
}

const PremiumBox = () => {
    return <div className="columns">
        <div className="column is-3"></div>
        <div className="column">
            <div className="card home">
                <div className="card-content">
                    <div className="content has-text-centered">
                        <img src={premium} alt="Premium" className="mb-3"/>
                        <h2 className="bold orange">Chytrý včelař Premium</h2>
                        <h3>Neomezený přístup k funkcím na webu
                            www.chytry-vcelar.cz:</h3>
                        <ul style={{listStyle: "none"}} className="mb-5">
                            <li><img src={p1} className="vatt mr-3" alt="Včelstva"/>Zadávání stanovišt, včelstev a jejich editace</li>
                            <li><img src={p2} className="vatt mr-3" alt="Prohlídky"/>Vkládání záznamů prohlídek, jejich úpravy, mazání
                            </li>
                            <li><img src={p3} className="vatt mr-3" alt="Produkce"/>Evidence produkce</li>
                            <li><img src={p4} className="vatt mr-3" alt="Exporty"/>Možnost exportu záznamů do PDF</li>
                        </ul>
                        <hr className="mb-4"
                            style={{width: "90%", margin: "auto", height: "1px", backgroundColor: "#A4B8BC"}}/>
                        <p className="mb-1 pb-0">za jednorázový poplatek</p>
                        <p className="mt-0 pt-0"><b>399 Kč</b> (vč. DPH)</p>
                        <Link to="/profil/"><button className="button is-normal is-large">Koupit Premium</button></Link>
                    </div>
                </div>
            </div>
        </div>
        <div className="column is-3"></div>

    </div>
}

const SupportHero = () => {
    return <section className=" hero is-primary home">
        <div className="columns">
            <div className="column is-2"></div>
            <div className="column">
                <div className="hero-body has-text-centered mb-6">
                    <h2 className="is-white">Využijte Chytrého včelaře na maximum a podpořte jeho další vývoj a údržbu!
                        Nestojí víc, než
                        dvě sklenice
                        medu...</h2>
                </div>
            </div>
            <div className="column is-2"></div>
        </div>
    </section>
}

const About = () => {
    return <div className="columns">
        <div className="column is-3"></div>
        <div className="column">
            <div className="content home has-text-centered">
                <h2 className="mt-6 mb-3">Mohlo by vás zajímat</h2>
                <hr className="mt-6 mb-6" style={{margin: "auto"}}/>

                <h3>Je Chytrý včelař stále zdarma?</h3>
                <p>Ano, mobilní aplikace se všemi jejími funkcemi je zcela zdarma. Bezplatné je také prohlížení
                    vašich
                    dat
                    na webu chytryvcelar.cz</p>

                <h3>A jak se tedy liší bezplatné premiové užívání?</h3>
                <p>Premiový účet odemyká všechny funkce mobilní aplikace i na webu a umožňuje tedy včelstva a
                    záznamy
                    nejen
                    prohlížet, ale i vytvářet, upravovat, mazat...</p>

                <h3>Proč si Premium pořídit?</h3>
                <p>Můžete se svými včelstvy a záznamy o nich pracovat i v pohodlí domova, na velké obrazovce
                    počítače,
                    ale
                    hlavně budete mít dobrý pocit, že podporujete další vývoj a udržbu Chytrého včelaře.</p>
            </div>
        </div>
        <div className="column is-3"></div>
    </div>;
}

const PaymentLogos = () => {
    return <div className="has-text-centered">
        <div>
            <img src={pay1} className="mt-6 mb-3" />
        </div>
        <div>
            <img src={pay2} className="mt-3 mb-6" />
        </div>
    </div>
}

export default function Premium() {
    return (
        <>
            <TitleSection/>
            <PremiumBox/>
            <PaymentLogos/>
            <About/>
            <SupportHero/>

            <BottomMenu/>
            <Footer/>
        </>
    );
}
