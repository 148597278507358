import {useProfile} from "../hooks/useProfile";
import {Link} from "react-router-dom";
import {HiPlus} from "react-icons/hi";
import {useFetch} from "../hooks/useFetch";
import {API_URL} from "../config";
import Loader from "./Loader";
import {chunk, formatDate} from "../utils/utils";
import {FaChevronDown, FaChevronUp, FaExclamationCircle, FaExclamationTriangle, FaRegCheckCircle} from "react-icons/fa";
import lucni from "../img/honey/lucni.svg";
import medovice from "../img/honey/medovice.svg";
import akat from "../img/honey/akat.svg";
import lipa from "../img/honey/lipa.svg";
import repka from "../img/honey/repka.svg";
import malina from "../img/honey/malina.svg";
import jetel from "../img/honey/jetel.svg";
import slunecnice from "../img/honey/slunecnice.svg";
import pohanka from "../img/honey/pohanka.svg";
import vojteska from "../img/honey/vojteska.svg";
import vres from "../img/honey/vres.svg";
import {HiveFigure} from "./Hive";
import {useEffect, useState} from "react";
import {AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";
import {ConfirmDialog} from "./ConfirmDialog"
import {deleteData} from "../utils/deleteData"
import {store} from "react-notifications-component"


export const HoneyList = ({}) => {

    const {isPremium} = useProfile()

    const {loading, data, error} = useFetch(
        `${API_URL}/honey-harvesting/`
    );

    const [dataInSections, setDataInSections] = useState([])
    const [filterLocation, setFilterLocation] = useState("__all__")


    useEffect(() => {
        let result = []
        let section = ""
        if (data && data.length > 0) {
            data.forEach(honey => {
                if (honey.location_name == section) {
                    result[result.length - 1][1].push(honey)
                } else {
                    section = honey.location_name
                    result.push([section, [honey]])
                }
            })
            setDataInSections(result)
        }
    }, [data])

    const onDelete = (id) => {
        return () => {
            setDataInSections(dataInSections.map(section => {
                section[1] = section[1].filter(honey => honey.id != id)
                return section
            }).filter(section => section[1].length > 0))
        }
    }


    return <>
        <div className="columns">
            <div className="column">
                <h2 className="title is-4">Medobraní</h2>
            </div>
            <div className="column has-text-right">
                {isPremium &&
                    <Link to="/nove-medobrani/" className="button"><HiPlus className="mr-3"/>Nové
                        medobraní</Link>}
            </div>
        </div>
        <div>
            {loading ? <Loader/> : (data && data.length) ?
                <>
                    <div className="field">
                        <div className="select mb-3">
                            <select onChange={(e) => {
                                setFilterLocation(e.target.value)
                            }} value={filterLocation}>
                                <option value={"__all__"}>Všechna stanoviště</option>
                                {dataInSections.map(section =>
                                    <option key={section[0]} value={section[0]}>{section[0]}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    {dataInSections.filter(item => filterLocation == '__all__' || item[0] == filterLocation).map(section =>
                        <div className="mb-6" key={section}>
                            <p className="title is-6">Stanoviště {section[0]} ({section[1].length})</p>
                            {
                                chunk(section[1], 4).map(honeys =>
                                    <div className="columns" key={honeys[0].id}>
                                        {honeys.map(honey =>
                                            <div className="column is-3" key={honey.id}>
                                                <HoneyCard honey={honey} onDelete={onDelete(honey.id)} isPremium={isPremium}/>
                                            </div>
                                        )}
                                    </div>
                                )
                            }
                        </div>
                    )}</>
                : <i>Bez medobraní</i>}
        </div>
    </>
}

export const HoneyImage = ({kind}) => {
    const images = {
        "Květový smíšený": lucni,
        "Medovicový": medovice,
        "Akátový": akat,
        "Lipový": lipa,
        "Řepkový": repka,
        "Malinový": malina,
        "Jetelový": jetel,
        "Slunečnicový": slunecnice,
        "Pohankový": pohanka,
        "Vojtěškový": vojteska,
        "Vřesový": vres
    }
    return <img src={images[kind] || lucni} alt={kind}/>
}

const HumidityIndicator = ({humidity}) => {
    if (humidity <= 18.0) {
        return <FaRegCheckCircle style={{color: "green"}} className="ml-2 vatt"/>
    }
    if (humidity <= 20.0) {
        return <FaExclamationTriangle style={{color: "orange"}} className="ml-2 vatt"/>
    }
    return <FaExclamationCircle style={{color: "red"}} className="ml-2 vatt"/>
}

const HoneyCard = ({honey, onDelete, isPremium = false}) => {

    const [showMore, setShowMore] = useState(false)
    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);

    const deleteHoney = () => {
        deleteData(`${API_URL}/honey-harvesting/${honey.id}/`).then(data => {
            store.addNotification({
                title: "Medobraní smazáno.",
                message: <></>,
                type: "danger",
                insert: "top",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                slidingExit: {
                    duration: 0,
                    timingFunction: 'ease-out',
                    delay: 0
                },
                dismiss: {
                    duration: 5000,
                    pauseOnHover: true
                }
            });
            onDelete()
        }).catch(function (err) {
            // TODO: Error
        });
    }

    return (
        <div className="card is-primary" style={{cursor: "pointer"}} onClick={() => {
            setShowMore(!showMore)
        }}>
            <div className="card-content p-4">
                <div className="media mb-0">
                    <div className="media-left">
                        <figure className="image is-64x64 is-square">
                            <HoneyImage kind={honey.kind}/>
                        </figure>
                    </div>
                    <div className="media-content">
                        <p className="title is-6">{formatDate(honey.created)}</p>
                        <p className="subtitle is-7 mb-0">{honey.kind}</p>
                        <span className="subtitle is-7 mb-4">{honey.weight.toLocaleString()} kg</span>
                        <p className="subtitle is-7 mb-2">{honey.humidity ? <>{honey.humidity.toLocaleString()}%
                            vody<HumidityIndicator
                                humidity={honey.humidity}/></> : <>&nbsp;</>}</p>
                    </div>
                    {isPremium &&
                        <>
                            <Link to={`/med/${honey.id}/`} className="button is-small-ico is-dark is-pulled-right">
                                <div style={{marginTop: "auto"}}>
                                    <AiOutlineEdit/>
                                </div>
                            </Link>
                            <button
                                onClick={() => setShowDeleteConfirmationDialog(true)}
                                className="button is-small-ico is-danger is-pulled-right ml-2"><AiOutlineDelete/>
                            </button>
                        </>}
                </div>
                <ConfirmDialog question={<>Opravdu si přejete vymazat medobraní?</>}
                               isActive={showDeleteConfirmationDialog}
                               onClose={() => setShowDeleteConfirmationDialog(false)}
                               onConfirm={deleteHoney}
                />
                {showMore &&
                    <>
                        {honey.hives && honey.hives.length > 0 &&
                            <div>
                                <hr className="mt-0 mb-2"/>
                                {honey.hives.map(hive =>
                                    <div className="subtitle is-7 mb-2" key={hive.id}><HiveFigure tiny={true}
                                                                                                  hive={hive}/> {hive.name}
                                        {hive.box_count && hive.box_count > 0 &&
                                            <>
                                                , {hive.box_count} {hive.box_count == 1 ? "nástavek" : hive.box_count >= 2 && hive.box_count < 5 ? "nástavky" : "nástavků"}
                                            </>
                                        }
                                    </div>
                                )}
                            </div>
                        }
                        {honey.note &&
                            <div>
                                <hr className="mt-0 mb-2"/>
                                <p className="subtitle is-7">{honey.note}</p>
                            </div>
                        }
                    </>
                }
                <div className="has-text-right mb-0">
                    {((honey.hives && honey.hives.length > 0) || honey.note) ? (showMore ? <FaChevronUp></FaChevronUp> :
                        <FaChevronDown></FaChevronDown>) : ""}
                </div>
            </div>
        </div>
    );
}
