import {useHistory} from "react-router-dom";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {postFormData} from "../utils/postFormData";
import {API_URL} from "../config";
import {store} from "react-notifications-component";
import {putFormData} from "../utils/putFormData";
import {DatePickerField} from "./DatePickerField";
import {TextField} from "./TextField";
import {ImageField} from "./ImageField";


export const LocationForm = ({initialValues, slug}) => {
    let history = useHistory();

    return <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
            name: Yup.string()
                .max(50, 'Název stanoviště nesmí obsahovat více než 50 znaků')
                .required('Povinný údaj'),
            established: Yup.string()
                .nullable()
                .required('Povinný údaj')
        })}
        onSubmit={(values, {setSubmitting, setFieldError}) => {
            if (slug) {
                putFormData(`${API_URL}/hive-locations/${slug}/`, values).then(data => {
                    setSubmitting(false);
                    store.addNotification({
                        title: "Stanoviště upraveno",
                        message: <>Stanoviště <b>{values['name']}</b> bylo úspěšně upraveno.</>,
                        type: "info",
                        insert: "top",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        slidingExit: {
                            duration: 0,
                            timingFunction: 'ease-out',
                            delay: 0
                        },
                        dismiss: {
                            duration: 5000,
                            pauseOnHover: true
                        }
                    });
                    history.replace({pathname: `/stanoviste/${slug}/`})
                }).catch(function (err) {
                    for (const key of Object.keys(err)) {
                        setFieldError(key, err[key]);
                    }
                    setSubmitting(false);
                });
            } else {
                postFormData(`${API_URL}/hive-locations/`, values).then(data => {
                    setSubmitting(false);
                    store.addNotification({
                        title: "Stanoviště vytvořeno",
                        message: <>Stanoviště <b>{values['name']}</b> bylo úspěšně vytvořeno.</>,
                        type: "info",
                        insert: "top",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        slidingExit: {
                            duration: 0,
                            timingFunction: 'ease-out',
                            delay: 0
                        },
                        dismiss: {
                            duration: 5000,
                            pauseOnHover: true
                        }
                    });
                    history.replace({pathname: "/muj-denik/"})
                }).catch(function (err) {
                    for (const key of Object.keys(err)) {
                        setFieldError(key, err[key]);
                    }
                    setSubmitting(false);
                });
            }
        }}
    >
        {props => (
            <Form>
                <div className="columns">
                    <div className="column">
                        <h1 className="title is-4">{slug ? "Úprava" : "Založení"} stanoviště</h1>
                    </div>
                    <div className="column has-text-right">
                        {props.isSubmitting ?
                            <button type="submit" className="button"
                                    disabled>Ukládám...</button>
                            :
                            <button type="submit" className="button"
                                    disabled={((!(props.isValid && props.dirty)))}>Uložit</button>}
                    </div>
                </div>
                <hr className="mt-0"/>
                <div className="columns">
                    <div className="column">
                        <TextField disabled={props.isSubmitting} name="name" label="Název stanoviště"/>
                        <TextField disabled={props.isSubmitting} name="address_line_1" label="Adresa"/>
                        <TextField disabled={props.isSubmitting} name="registration_number" label="Registrační číslo"/>
                        <DatePickerField disabled={props.isSubmitting} name="established" label="Datum založení"/>

                    </div>
                    <div className="column">
                        <ImageField initial={props.initialValues.image} name="image"
                                    setFieldValue={props.setFieldValue}/>
                    </div>
                </div>
            </Form>)}
    </Formik>
}